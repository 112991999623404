import { Button, Card, Icon, Modal, ModalActions, ModalTitle, Spinner, Table, TextField, Typography } from '@platform-ui/design-system';
import React, { Dispatch, FC, useEffect, useState } from 'react';
import { IADState } from '../../../../IntegrationApps/IntegrationAppDetails/state';
import { Action, useStoreContext } from '../../../../Store';
import { NS_ADD_SOLUTION_INSTANCE, NS_DELETE_SOLUTION_INSTANCE, NS_LOAD_SOLUTION_INSTANCES, NS_PROVISION_SOLUTION_INSTANCE_ERROR, NS_UPDATE_SOLUTION_INSTANCE } from '../action_types';
import Connect from '../../../../Connect/Connect';

interface SolutionInstancesTableProps {
  inbound: boolean;
  rows: any[];
  setInstanceDetails: (id: number, name: string, withMappings: boolean, show: boolean) => void;
}

export const SolutionInstancesTable: FC<SolutionInstancesTableProps> = ({
  inbound,
  rows,
  setInstanceDetails
}: SolutionInstancesTableProps) => {
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [instanceFields, setInstanceFields] = useState({
    name: '',
    error: false,
    helperText: '',
    showSpinner: false
  });
  const [deleteFields, setDeleteFields] = useState({
    msg: 'Are you sure you want to delete this solution instance?',
    id: '',
    showSpinner: false
  });
  const [loading, setLoading] = useState(false);
  const connect: Connect = (window as any).connect;
  const workflowProxyPath = `/platform/gateway-proxy-v2`;

  const getTemplate = async (instanceName: string) => {
    const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
    const options = {
      method: 'GET',
      headers: new Headers({
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'X-CSRF-Token': csrf
      })
    };

    return await window.fetch(`${state.settingsUrl.replace('.json', `/netsuite/instance_template?name=${encodeURIComponent(instanceName)}&inbound=${inbound}`)}`, options);
  }

  const createSolutionInstance = async (instanceName: string) => {
    let response = await getTemplate(instanceName);

    if (!response.ok) throw Error(response.statusText);

    const template = await response.json();

    if (state.production) {
      const headers = state.enablePrivateWorkflow
        ? {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Content-Type': 'application/json',
          'Scope': 'Internal'
        }
        : {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Content-Type': 'application/json'
        };

      response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/import?activate=true`,
        'POST',
        template,
        headers
      );
    } else {
      const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
      const options = {
        method: 'POST',
        headers: new Headers({
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'X-CSRF-Token': csrf
        }),
        body: JSON.stringify({
          name: instanceName,
          inbound
        })
      };
      response = await window.fetch(`${state.settingsUrl.replace('.json', '/netsuite/instances')}`, options);
    }

    return response;
  };

  const getSolutionInstances = async () => {
    setLoading(true);
    try {
      let response;

      if (state.production) {
        const path = state.enablePrivateWorkflow
          ? `${workflowProxyPath}/workflows?tags[]=IntegrationHub_NetSuite_${inbound ? 'Inbound' : 'Outbound'}&page=1&page_length=50`
          : `${workflowProxyPath}/workflows?category=IntegrationHub_NetSuite_${inbound ? 'Inbound' : 'Outbound'}&page=1&page_length=50`;
        
        const headers = state.enablePrivateWorkflow
          ? { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
          : { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id };
        
        response = await Connect.proxyCall(
          path,
          'GET',
          undefined,
          headers
        );
      } else {
        const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
        const options = {
          method: 'GET',
          headers: new Headers({
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'X-CSRF-Token': csrf
          })
        };
        response = await window.fetch(`${state.settingsUrl.replace('.json', `/netsuite/instances?inbound=${inbound}`)}`, options);
      }

      if (!response.ok) throw Error(response.statusText);

      const { data: instances } = await response.json();

      // Note (Duc): we store the actual solution instance name users type in into description to avoid custom logic to parse private workflow prefix i.e z-
      const rows = instances.map(instance => ({
        id: instance.id,
        solution_instance_name: instance.name.split(`IntegrationHub_NetSuite_${inbound ? 'Inbound' : 'Outbound'}_`)[1],
        created_at: instance.createdAt,
        active_version_id: instance.active_version?.id || instance.id,
        scheduled_trigger: instance.scheduledTrigger,
        interval: instance.interval,
        timezone: instance.timezone
      }));

      dispatch({
        type: NS_LOAD_SOLUTION_INSTANCES,
        payload: rows
      });
    } catch (err) {
      Connect.log(err);
    } finally {
      setLoading(false);
    }
  };

  const retrieveDefaultOutboundConnectors = async () => {
    let response;

    if (state.production) {
      const path = `${workflowProxyPath}/workflows?name=${state.enablePrivateWorkflow ? 'z-' : ''}IntegrationHub_NetSuite_Outbound_IH_Internal_NetSuite_GL`;
      
      const headers = state.enablePrivateWorkflow
        ? { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
        : { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id };
      
      response = await Connect.proxyCall(
        path,
        'GET',
        undefined,
        headers
      );
    } else {
      const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
      const options = {
        method: 'GET',
        headers: new Headers({
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'X-CSRF-Token': csrf
        })
      };
      response = await window.fetch(`${state.settingsUrl.replace('.json', `/netsuite/instances?inbound=${inbound}&name=IH_Internal_NetSuite_GL`)}`, options);
    }

    return response;
  };

  const provisionOutbound = async () => {
    try {
      let response = await retrieveDefaultOutboundConnectors();

      if (!response.ok) throw Error(response.statusText);

      const { data: instances } = await response.json();

      if (Array.isArray(instances) && instances.length === 0) {
        response = await createSolutionInstance('IH_Internal_NetSuite_GL');

        if (!response.ok) throw Error(response.statusText);

        const instance = await response.json();

        if (instance.errors) throw Error(instance.errors?.[0]);

        dispatch({
          type: NS_ADD_SOLUTION_INSTANCE,
          payload: {
            id: instance.id,
            solution_instance_name: instance.name.split(`IntegrationHub_NetSuite_${inbound ? 'Inbound' : 'Outbound'}_`)[1],
            created_at: instance.createdAt,
            active_version_id: instance.active_version?.id || instance.id,
            scheduled_trigger: instance.scheduledTrigger,
            interval: instance.interval,
            timezone: instance.timezone
          }
        });
      }
    } catch (err) {
      Connect.log(err);
      dispatch({ type: NS_PROVISION_SOLUTION_INSTANCE_ERROR });
    }
  };

  const increaseVersion = (version: string) => {
    const [major, minor, patch] = version.split('.');

    const currentPatch = Number(patch);
    const currentMinor = Number(minor);
    const currentMajor = Number(major);

    const nextPatch = currentPatch < 9 ? currentPatch + 1 : 0;
    const nextMinor = currentPatch <= nextPatch ? currentMinor : (currentMinor < 9 ? currentMinor + 1 : 0);
    const nextMajor = currentMinor <= nextMinor ? currentMajor : currentMajor + 1; 

    return `${nextMajor}.${nextMinor}.${nextPatch}`;
  };

  const updateInstanceToLatest = async () => {
    try {
      let response = await retrieveDefaultOutboundConnectors();

      if (!response.ok) throw Error(response.statusText);

      const { data: instances } = await response.json();

      if (Array.isArray(instances) && instances.length > 0) {
        const instance = instances[0];
        const currentVersion = instance?.active_version?.version || '0.0.1';
        const nextVersion = increaseVersion(currentVersion);

        response = await getTemplate('IH_Internal_NetSuite_GL');

        if (!response.ok) throw Error(response.statusText);

        const template = await response.json();

        if (template?.workflow) {
          template.workflow.version = nextVersion;
        }

        if (state.production) {
          const headers = state.enablePrivateWorkflow
            ? {
              'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
              'Content-Type': 'application/json',
              'Scope': 'Internal'
            }
            : {
              'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
              'Content-Type': 'application/json'
            };

          response = await Connect.proxyCall(
            `${workflowProxyPath}/workflows/${instance.id}/versions/import?version=${nextVersion}&activate=true`,
            'POST',
            template,
            headers
          );
        } else {
          const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
          const options = {
            method: 'POST',
            headers: new Headers({
              'Accept': 'application/json',
              'Content-type': 'application/json',
              'X-CSRF-Token': csrf
            }),
            body: JSON.stringify({
              name: 'IH_Internal_NetSuite_GL',
              version: nextVersion,
              workflowId: instance.id,
              inbound
            })
          };
          response = await window.fetch(`${state.settingsUrl.replace('.json', '/netsuite/instances')}`, options);
        }

        if (!response.ok) throw Error(response.statusText);

        dispatch({ type: NS_UPDATE_SOLUTION_INSTANCE });
      }
    } catch (err) {
      Connect.log(err);
    }
  };

  useEffect(() => {
    // Add a little bit of delay when loading solution instances to avoid race condition in the backend
    // when trying to load NetSuite connector
    const timer = setTimeout(() => getSolutionInstances(), 500);

    // As for outbound, we need to provision only one workflow
    if (!inbound) {
      provisionOutbound();
    }

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!inbound && state.saved) {
      updateInstanceToLatest();
    }
  }, [state.saved]);

  return (
    <>
      <Modal
        id='create-solution-instance-modal'
        open={openCreateModal}
        disableBackdropClick={true}
        header={
          <ModalTitle
            dsOnClose={() => {
              setOpenCreateModal(false);
              setInstanceFields({...instanceFields, name: '', error: false, helperText: ''});
            }}
          >
            Create a Solution Instance
          </ModalTitle>
        }
        dsOnClose={() => {
          setOpenCreateModal(false);
          setInstanceFields({...instanceFields, name: '', error: false, helperText: ''});
        }}
        body={
          <TextField
            fullWidth
            value={instanceFields.name}
            label='Solution Instance Name'
            helperText={instanceFields.helperText}
            error={instanceFields.error}
            dsOnChange={(e) => {
              if (e.target.value === '') {
                setInstanceFields({...instanceFields, error: true, helperText: 'Instance name cannot be empty', name: ''});
              } else {
                setInstanceFields({...instanceFields, error: false, helperText: '', name: e.target.value});
              }
            }}
          />
        }
        footer={(
          <>
            <ModalActions>
              <Button
                variant='outlined'
                dsOnClick={() => {
                  setOpenCreateModal(false);
                  setInstanceFields({...instanceFields, name: '', error: false, helperText: ''});
                }}
              >
                Cancel
              </Button>
              <Button
                dsOnClick={async () => {
                  if (instanceFields.name) {
                    setInstanceFields({...instanceFields, error: false, helperText: '', showSpinner: true});
                    try {
                      const response = await createSolutionInstance(instanceFields.name);

                      if (!response.ok) throw Error(response.statusText);

                      const instance = await response.json();

                      if (instance.errors) throw Error(instance.errors?.[0]);

                      setInstanceFields({...instanceFields, name: '', error: false, helperText: '', showSpinner: false});
                      setOpenCreateModal(false);
                      dispatch({
                        type: NS_ADD_SOLUTION_INSTANCE,
                        payload: {
                          id: instance.id,
                          solution_instance_name: instance.name.split(`IntegrationHub_NetSuite_${inbound ? 'Inbound' : 'Outbound'}_`)[1],
                          created_at: instance.createdAt,
                          active_version_id: instance.active_version?.id || instance.id,
                          scheduled_trigger: instance.scheduledTrigger,
                          interval: instance.interval,
                          timezone: instance.timezone
                        }
                      });
                    } catch (err) {
                      Connect.log(err);
                      setInstanceFields({...instanceFields, error: true, helperText: 'Unable to create a solution instance. Please contact admin!', showSpinner: false});
                    }
                  } else {
                    setInstanceFields({...instanceFields, error: true, helperText: 'Instance name cannot be empty', showSpinner: false});
                  }
                }}
              >
                Submit
              </Button>
            </ModalActions>
            {instanceFields.showSpinner && <Spinner variant='linear' />}
          </>
        )}
      />
      <Modal
        id='delete-solution-instance-modal'
        open={openDeleteModal}
        header={
          <ModalTitle
            icon={<Icon color='warning' body='warning' />}
            dsOnClose={() => {
              setOpenDeleteModal(false);
              setDeleteFields({...deleteFields, msg: 'Are you sure you want to delete this solution instance?', showSpinner: false});
            }}
          >
            Delete a Solution Instance
          </ModalTitle>
        }
        dsOnClose={() => {
          setOpenDeleteModal(false);
          setDeleteFields({...deleteFields, msg: 'Are you sure you want to delete this solution instance?', showSpinner: false});
        }}
        body={
          <Typography>{deleteFields.msg}</Typography>
        }
        footer={
          <>
            <ModalActions>
            <Button variant='outlined' dsOnClick={() => setOpenDeleteModal(false)}>No</Button>
            <Button
              dsOnClick={async () => {
                setDeleteFields({...deleteFields, showSpinner: true});

                try {
                  if (state.production) {
                    const headers = state.enablePrivateWorkflow 
                      ? {
                        'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
                        'Scope': 'Internal'
                      }
                      : {
                        'Zuora-Tenant-Id': (connect.tenant as any).tenant_id
                      };

                    await Connect.proxyCall(
                      `${workflowProxyPath}/workflows/${deleteFields.id}`,
                      'DELETE',
                      undefined,
                      headers
                    );
                  } else {
                    const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
                    const options = {
                      method: 'DELETE',
                      headers: new Headers({
                        'Accept': 'application/json',
                        'Content-type': 'application/json',
                        'X-CSRF-Token': csrf
                      })
                    };
                    await window.fetch(`${state.settingsUrl.replace('.json', `/netsuite/instances/${deleteFields.id}`)}`, options);
                  }

                  setDeleteFields({...deleteFields, showSpinner: false});
                  setOpenDeleteModal(false);
                  dispatch({
                    type: NS_DELETE_SOLUTION_INSTANCE,
                    payload: {
                      id: deleteFields.id
                    }
                  });
                } catch (err) {
                  Connect.log(err);
                  setDeleteFields({...deleteFields, msg: 'Failed to delete this solution instance. Please try again later.', showSpinner: false});
                }
              }}
            >
              Yes
            </Button>
            </ModalActions>
            {deleteFields.showSpinner && <Spinner variant='linear' />}
          </>
        }
      />
      <Card
        id='netsuite-execution-table'
        body={
          <Table
            uniqueKey='netsuite-execution-table-key'
            loading={loading}
            columns={[
              {
                field: 'id',
                label: 'ID',
                type: 'number'
              },
              inbound ?
              {
                field: 'solution_instance_name',
                label: 'Solution Instance Name',
                dsRenderCell: ( { row } ) => {
                  const solutionInstanceName: string = row.solution_instance_name as string;
                  const solutionInstanceId: number = row.id as number;
                  return (
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setInstanceDetails(solutionInstanceId, solutionInstanceName, true, true);
                      }}
                    >
                      {solutionInstanceName}
                    </a>
                  );
                }
              } :
              {
                field: 'solution_instance_name',
                label: 'Solution Instance Name'
              },
              {
                field: 'created_at',
                label: 'Creation Date'
              },
              // {
              //   field: 'updated_at',
              //   label: 'Update Date'
              // },
              {
                field: 'actions',
                label: 'Actions',
                alignment: 'right',
                sortable: false,
                dsRenderCell: ({ row }) => {
                  return (
                    <>
                      <Button
                        icon='delete'
                        tooltip='Delete a Solution Instance'
                        dsOnClick={() => {
                          setOpenDeleteModal(true);
                          setDeleteFields({...deleteFields, id: (row.id) as string});
                        }}
                      />
                      {
                        !inbound &&
                        <Button
                          icon='update'
                          tooltip='Update to The Latest Version'
                          dsOnClick={() => alert('This feature is not implemented yet!')}
                        />
                      }
                    </>
                  );
                }
              }
            ]}
            rows={rows}
            rowsPerPage={10}
            rowsPerPageOptions={[5, 10, 15, 20, 30, 45]}
            hideTotalRows
            tableActions={[
              {
                icon: 'add',
                tooltip: 'Add a Solution Instance',
                disabled: rows.length >= 50,
                onClick: () => setOpenCreateModal(true)
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh',
                onClick: () => getSolutionInstances()
              }
            ]}
          />
        }
      />
    </>
  );
};