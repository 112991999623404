import { createStore } from '../../Store';
import { reducer } from './reducer';
import * as IADActionTypes from './action_types';

const IADContextProvider = createStore(reducer);

export { ButtonGroup as IADButtonGroup } from './components/ButtonGroup';
export { TabPanel as IADTabPanel } from './components/TabPanel';
export { Title as IADTitle } from './components/Title';
export { initialState } from './state';
export { IADContextProvider };
export { IADActionTypes };
export { actionMap } from './action_map';