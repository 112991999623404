import { Alert, Spinner } from '@platform-ui/design-system';
import React from 'react';
import Connect from '../../../Connect/Connect';
import { DestinationTable } from './components/Destination/DestinationTable';
import { IADState } from '../../../IntegrationApps/IntegrationAppDetails/state';
import { useStoreState } from '../../../Store';
import { useFlipperHook } from '../../../Tables/helpers';

export const Destination = (props: { system: string }) => {
  const state = useStoreState() as IADState;
  const [sapFeatureEnabled, sapFeatureLoading] = useFlipperHook('enable_sap_connector');

  try {
    if (sapFeatureLoading) {
      return <Spinner />;
    }

    if (!state.active) {
      return (
        <Alert center variant='outlined' severity='warning' body='You must enable integration in Authentication tab first' open={true} />
      );
    }

    if (!state.saved && !state.settingsHash['authentication']) {
      return (
        <Alert center variant='outlined' severity='warning' body='You must configure Authentication and save first' open={true} />
      );
    }
  } catch (err) {
    Connect.log(`Destination loading for SAP ${props.system} error: ${err}`);
    return (
      <Alert center variant='outlined' severity='error' body='An unexpected error has occurred. Please contact admin for technical support.' open={true} />
    );
  }

  return (
    <DestinationTable
      permissionCheckDisabled={sapFeatureEnabled}
      permissionName='permission.ZuoraWorkdayConnector'
      system={props.system}
    />
  );
};