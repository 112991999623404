import {Action, useStoreContext} from "../../Store";
import {IADState} from "./state";
import {Dispatch, useEffect} from "react";
import Connect from '../../Connect/Connect';

/**
 * The custom hook to provision or update a workflow instance if needed
 *
 * @param {boolean} enabled - Whether the workflow should be provisioned
 * @param defaultWorkflowName - The default name of the workflow
 * @param addSolutionInstanceActionType - The action type for adding a solution instance
 * @param loadSolutionInstancesActionType - The action type for loading solution instances
 * @param provisionSolutionInstanceErrorActionType - The action type for the error upon provisioning a solution instance
 */
const useProvisionWorkflow = (
  enabled: boolean,
  defaultWorkflowName: string,
  addSolutionInstanceActionType: string,
  loadSolutionInstancesActionType: string,
  provisionSolutionInstanceErrorActionType: string
) => {
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const connect: Connect = (window as any).connect;
  const isLocal = window.location.host.includes('localhost');
  const workflowProxyPath = `${isLocal ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2`;
  const workflowName = state.workflow_template?.workflow_definition?.name || defaultWorkflowName;
  const workflowTemplateVersion = state.workflow_template?.workflow?.version || '0.0.1';

  // Retrieve a workflow instance by its name
  const retrieveWorkflow = async (workflowProxyPath: string, workflowName: string) => {
    const response = await Connect.proxyCall(
      `${workflowProxyPath}/workflows?name=${workflowName}`,
      'GET',
      undefined,
      { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
    );

    return response;
  }

  // Create a new workflow instance
  const createWorkflow = async (workflowProxyPath: string, workflow_template: any) => {
    const response = await Connect.proxyCall(
      `${workflowProxyPath}/workflows/import?activate=true`,
      'POST',
      workflow_template,
      {
        'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
        'Content-Type': 'application/json',
        'Scope': 'Internal'
      }
    );
    return response;
  }

  // Update an existing workflow instance
  const updateWorkflow = async (workflowProxyPath: string, id: number, version: string, workflow_template: any) => {
    const response = await Connect.proxyCall(
      `${workflowProxyPath}/workflows/${id}/versions/import?version=${version}&activate=true`,
      'POST',
      workflow_template,
      {
        'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
        'Content-Type': 'application/json',
        'Scope': 'Internal'
      }
    );
    return response;
  }

  // Provision the workflow instance if it doesn't exist or update it if it's outdated
  const provisionWorkflow = async () => {
    try {
      let instance;
      let response = await retrieveWorkflow(workflowProxyPath, workflowName);
      if (!response.ok) throw Error(response.statusText);
      const { data: instances } = await response.json();
      if (Array.isArray(instances) && instances.length === 0) {
        response = await createWorkflow(workflowProxyPath, state.workflow_template);
        if (!response.ok) throw Error(response.statusText);
        const instance = await response.json();
        if (instance.errors) throw Error(instance.errors?.[0]);
        dispatch({
          type: addSolutionInstanceActionType,
          payload: {
            id: instance.id,
            solution_instance_name: instance.name,
            created_at: instance.createdAt,
            active_version_id: instance.active_version?.id || instance.id,
            scheduled_trigger: instance.scheduledTrigger,
            interval: instance.interval,
            timezone: instance.timezone
          }
        });
      } else if (Array.isArray(instances) && (instance = instances.find(instance => instance.name === workflowName))
        && (instance.active_version?.version !== workflowTemplateVersion)) {
        response = await updateWorkflow(workflowProxyPath, instance.id, workflowTemplateVersion, state.workflow_template);
        if (!response.ok) throw Error(response.statusText);
        instance = await response.json();
        if (instance.errors) throw Error(instance.errors?.[0]);
        dispatch({
          type: addSolutionInstanceActionType,
          payload: {
            id: instance.id,
            solution_instance_name: instance.name,
            created_at: instance.createdAt,
            active_version_id: instance.active_version?.id || instance.id,
            scheduled_trigger: instance.scheduledTrigger,
            interval: instance.interval,
            timezone: instance.timezone
          }
        });
      } else {
        const rows = instances.map(instance => ({
          id: instance.id,
          solution_instance_name: instance.name,
          created_at: instance.createdAt,
          active_version_id: instance.active_version?.id || instance.id,
          scheduled_trigger: instance.scheduledTrigger,
          interval: instance.interval,
          timezone: instance.timezone
        }));

        dispatch({
          type: loadSolutionInstancesActionType,
          payload: rows
        });
      }
    } catch (error) {
      Connect.log(error);
      dispatch({ type: provisionSolutionInstanceErrorActionType });
    }
  };

  useEffect(() => {
    if (enabled) {
      provisionWorkflow();
    }
  }, [enabled]);
};

export default useProvisionWorkflow;