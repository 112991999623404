import { AlertColor, Box, CircularProgress, FormControlLabel, Switch } from '@mui/material';
import { Button, Card, Grid, PageHeader, Select, SelectItem, TextField, ToastController, Typography, designTokens } from '@platform-ui/design-system';
import React, { Dispatch, FC, useState } from 'react';
import { Action, useStoreContext } from '../../../../Store';
import { IADState } from '../../../../IntegrationApps/IntegrationAppDetails/state';
import { NS_SAVE_SOLUTION_INSTANCE_OBJECTS } from '../action_types';
import Connect from '../../../../Connect/Connect';

interface SolutionInstancesDetailsProps {
  id: number;
  name: string;
  withMappings?: boolean;
  setShowInstanceDetails: (show: boolean) => void;
}

export const SolutionInstancesDetails: FC<SolutionInstancesDetailsProps> = ({
  id,
  name,
  withMappings = false,
  setShowInstanceDetails
}: SolutionInstancesDetailsProps) => {
  const [saving, setSaving] = useState(false);
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const solutionInstance = (state.settingsHash['solutionInstances'] || []).find(si => si.id === id);
  const [selectObject, setSelectObject] = useState('Credit Memos');
  const [includes, setIncludes] = useState(solutionInstance?.includes || {
    'Credit Memos': false
  });
  const [mappings, setMappings] = useState(solutionInstance?.mappings || {
    'Credit Memos': {
      netsuite: '',
      revenue: ''
    }
  });
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success' as AlertColor
  });

  return (
    <>
      { toast.show && <ToastController severity={toast.severity as any} message={toast.msg} /> }
      <PageHeader
        surtitle='SOLUTION INSTANCE NAME'
        title={<Typography variant='h6'>{name}</Typography>}
        dsOnBack={() => setShowInstanceDetails(false)}
        actions={withMappings ? [
          {
            id: 'save',
            variant: 'contained',
            body: (
              <Box sx={{m: 1, position: 'relative'}}>
                <Button
                  disabled={saving}
                  dsOnClick={async () => {
                    dispatch({
                      type: NS_SAVE_SOLUTION_INSTANCE_OBJECTS,
                      payload: {
                        ...solutionInstance,
                        includes,
                        mappings,
                        updatedAt: new Date().toUTCString(),
                        updatedBy: state.currentUser
                      }
                    });

                    const newState = {
                      ...state,
                      settings_hash: {
                        ...state.settingsHash,
                        fieldMapping: {
                          ...state.settingsHash['fieldMapping'],
                          mappingOptions: {}
                        },
                        emailNotifications: {
                          ...state.settingsHash['emailNotifications']
                        },
                        solutionInstances: [...state.settingsHash['solutionInstances']]
                      }
                    };

                    delete newState.settingsHash;
                    delete newState.settings_hash['filteredFieldMapping'];
                    
                    const idx = newState.settings_hash['solutionInstances'].findIndex(si => si.id === id);
                    newState.settings_hash['solutionInstances'][idx] = {
                      ...solutionInstance,
                      includes,
                      mappings,
                      updatedAt: new Date().toUTCString(),
                      updatedBy: state.currentUser
                    };

                    ['execution', 'executions'].forEach(tab => delete newState.settings_hash[tab]);

                    if (!saving) {
                      setSaving(true);
                      setToast({...toast, show: false});

                      try {
                        const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
                        const options = {
                          method: state.createOrUpdate === 'update' ? 'PUT' : 'POST',
                          headers: new Headers({
                            'Accept': 'application/json',
                            'Content-type': 'application/json',
                            'X-CSRF-Token': csrf
                          }),
                          body: JSON.stringify(newState)
                        };
                        const response = await window.fetch(state.settingsUrl, options);
                        const data = await response.json();
                        if (!response.ok) throw Error(data['message'] || 'Failed to save settings!');

                        setSaving(false);
                        setToast({
                          show: true,
                          msg: 'Settings were saved successfully!!!',
                          severity: 'success'
                        });
                      } catch (error) {
                        Connect.log(error);
                        setSaving(false);
                        setToast({
                          show: true,
                          msg: error.message,
                          severity: 'error'
                        });
                      }
                    }
                  }}
                >
                  Save
                </Button>
                {
                  saving &&
                  <CircularProgress
                    size={24}
                    sx={{
                      color: designTokens.colors.blue500,
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px'
                    }}
                  />
                }
              </Box>
            )
          },
          {
            id: 'cancel',
            label: 'Cancel',
            onClick: () => setShowInstanceDetails(false),
            variant: 'outlined'
          }
        ] : []}
      />
      {
        withMappings ?
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Card
              id='netsuite-solution-instances-details-top-ui'
              body={
                <Grid container spacing={20}>
                  <Grid item xs='auto'>
                    <Select
                      label='Select Object'
                      required
                      value={selectObject}
                      dsOnChange={(e) => setSelectObject(e.target.value as any)}
                    >
                      {
                        // TODO (Duc): replace with real data
                        ['Credit Memos', 'Invoices', 'Sales Orders'].map((option: string, idx: number) => (
                          <SelectItem value={option}>{option}</SelectItem>
                        ))
                      }
                    </Select>
                  </Grid>
                  <Grid item><TextField readonly dsOnChange={() => {}} label={<Typography variant='subtitle2'>Updated By</Typography>} value={solutionInstance?.updatedBy || 'N/A'} /></Grid>
                  <Grid item><TextField readonly dsOnChange={() => {}} label={<Typography variant='subtitle2'>Updated Date</Typography>} value={solutionInstance?.updatedAt || 'N/A'} /></Grid>
                </Grid>
              }
            />
          </Grid>
          <Grid item>
            <Card
              id='netsuite-solution-instances-details-bottom-ui'
              body={
                <Grid container direction='column'>
                  <Grid item container>
                    <Grid item><Typography>{`Include ${selectObject} in the Solution Instance`}</Typography></Grid>
                    <Grid item>
                      <FormControlLabel
                        style={{pointerEvents: 'none'}}
                        label={includes[selectObject] ? 'Yes' : 'No'}
                        control={
                          <Switch
                            size='small'
                            checked={!!includes[selectObject]}
                            style={{pointerEvents: 'auto'}}
                            onChange={(_, checked) => setIncludes({...includes, [selectObject]: checked})}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item container>
                    <Grid item xs={6}>
                      <TextField
                        label={`NetSuite Dataset for ${selectObject}`}
                        value={mappings[selectObject]?.netsuite || ''}
                        dsOnChange={(e) => setMappings({...mappings, [selectObject]: { ...mappings[selectObject], netsuite: e.target.value }})}
                        disabled={!includes[selectObject]}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label={`Zuora Revenue Object for ${selectObject}`}
                        value={mappings[selectObject]?.revenue || ''}
                        dsOnChange={(e) => setMappings({...mappings, [selectObject]: { ...mappings[selectObject], revenue: e.target.value }})}
                        disabled={!includes[selectObject]}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        </Grid> :
        <Card
          id='netsuite-solution-instances-details-top-ui'
          body={
            <Grid container direction='column'>
              <Grid item><Typography variant='subtitle2'>Default Object</Typography></Grid>
              <Grid item>
                <Grid container>
                  <Grid item xs={3}><TextField readonly dsOnChange={() => {}} label={<Typography variant='subtitle2'>NetSuite Object</Typography>} value='NetSuite Journal' /></Grid>
                  <Grid item xs={3}><TextField readonly dsOnChange={() => {}} label={<Typography variant='subtitle2'>System</Typography>} value='Zuora Revenue' /></Grid>
                  <Grid item xs={3}><TextField readonly dsOnChange={() => {}} label={<Typography variant='subtitle2'>Updated By</Typography>} value='admin@zuora.com' /></Grid>
                  <Grid item xs={3}><TextField readonly dsOnChange={() => {}} label={<Typography variant='subtitle2'>Updated Date</Typography>} value='10 September, 2023 09:28:43 GMT' /></Grid>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      }
    </>
  );
};