import {Backdrop, CircularProgress, FormControlLabel, Switch} from '@mui/material';
import {
  Button,
  Checkbox,
  FormGroup,
  FormLabel,
  Grid, Modal, ModalActions,
  ModalTitle, TextField, ToastController,
  Typography
} from '@platform-ui/design-system';
import React, { Dispatch, useState } from 'react';
import { IADState } from '../../../../IntegrationApps/IntegrationAppDetails/state';
import { Action, useStoreContext } from '../../../../Store';
import { IADActionTypes } from '../../../../IntegrationApps/IntegrationAppDetails';
import {
  WD_CHANGE_EMAIL_NOTIFICATIONS_ENABLED,
  WD_CHANGE_EMAIL_NOTIFICATIONS_SUCCESS,
  WD_CHANGE_EMAIL_NOTIFICATIONS_WARNINGS,
  WD_EMAIL_NOTIFICATIONS_ADD_ADDRESS,
  WD_EMAIL_NOTIFICATIONS_CANCEL_BUTTON_CLICK,
  WD_EMAIL_NOTIFICATIONS_DELETE_ADDRESS,
  WD_EMAIL_NOTIFICATIONS_SAVE_BUTTON_CLICK,
  WD_EMAIL_NOTIFICATIONS_UPDATE_ADDRESS
} from "../action_types";
import Connect from "../../../../Connect/Connect";
import {IAD_SAVE_BTN_CLICK} from "../../../../IntegrationApps/IntegrationAppDetails/action_types";
import { buildWorkdayGlobalConstants } from '../utils';

const NON_PERSISTENT_ITEMS = ['execution', 'executions', 'solutionInstances'];
const SUCCESSFUL_SAVE_MESSAGE = 'Configurations were saved successfully.';

export const HeaderAction = () => {
  const {state, dispatch} = useStoreContext() as {state: IADState, dispatch: Dispatch<Action>};
  const [checked, setChecked] = useState(!!state.active);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [numberOfInvalidEmails, setNumberOfInvalidEmails] = useState(0);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success'
  });
  const connect: Connect = (window as any).connect;
  const isLocal = window.location.host.includes('localhost');
  const workflowProxyPath = `${isLocal ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2`;
  const handleEnableIntegration = (checked: boolean) => {
    setChecked(checked);
    dispatch({
      type: IADActionTypes.ENABLE_INTEGRATION,
      payload: checked
    });
  };
  const handleEmailNotificationsEnabled = (checked: boolean) => {
    dispatch({
      type: WD_CHANGE_EMAIL_NOTIFICATIONS_ENABLED,
      payload: checked
    });
  }
  const handleWarningsSwitch = (checked: boolean) => {
    dispatch({
      type: WD_CHANGE_EMAIL_NOTIFICATIONS_WARNINGS,
      payload: checked
    });
  };
  const handleSuccessSwitch = (checked: boolean) => {
    dispatch({
      type: WD_CHANGE_EMAIL_NOTIFICATIONS_SUCCESS,
      payload: checked
    });
  };
  const handleAddEmailAddress = () => {
    setNumberOfInvalidEmails(numberOfInvalidEmails + 1);
    dispatch({
      type: WD_EMAIL_NOTIFICATIONS_ADD_ADDRESS,
    });
  };
  const handleUpdateEmailAddress = (index: number, email: string) => {
    let oldEmailAddress = state.settingsHash['emailNotifications']['emails'][index];
    let oldEmailWasInvalid = emailIsInvalid(oldEmailAddress);
    let newEmailIsInvalid = emailIsInvalid(email);
    if (oldEmailWasInvalid && !newEmailIsInvalid) {
      setNumberOfInvalidEmails(numberOfInvalidEmails - 1);
    } else if (!oldEmailWasInvalid && newEmailIsInvalid) {
      setNumberOfInvalidEmails(numberOfInvalidEmails + 1);
    }
    dispatch({
      type: WD_EMAIL_NOTIFICATIONS_UPDATE_ADDRESS,
      payload: {
        index: index,
        email: email
      }
    });
  }
  const handleDeleteEmailAddress = (index: number) => {
    if (emailIsInvalid(state.settingsHash['emailNotifications']['emails'][index])) {
      setNumberOfInvalidEmails(numberOfInvalidEmails - 1);
    }
    dispatch({
      type: WD_EMAIL_NOTIFICATIONS_DELETE_ADDRESS,
      payload: index
    });
  }
  const handleCancel = () => {
    setNumberOfInvalidEmails(0);
    setOpenEmailModal(false);
    dispatch({
      type: WD_EMAIL_NOTIFICATIONS_CANCEL_BUTTON_CLICK
    });
  }
  const emailIsInvalid = (emailAddress: string) => {
    let regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return !regex.test(emailAddress);
  }

  return (
    <>
      <Modal
        id='email-notifications-modal'
        open={openEmailModal}
        disableBackdropClick={true}
        header={
          <ModalTitle
            dsOnClose={() => handleCancel()}
          >
            Configurations
          </ModalTitle>
        }
        dsOnClose={() => {
          setOpenEmailModal(false);
        }}
        body={
          <>
            <Grid>
              <Grid item><Typography variant='subtitle1'>Email settings:</Typography></Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      size='small'
                      checked={!!state.settingsHash['emailNotifications']?.['enabled']}
                      onChange={(_, emailNotificationsEnabled) => {
                        handleEmailNotificationsEnabled(emailNotificationsEnabled)
                      }}
                    />
                  }
                  label='Enable email notifications'
                />
              </Grid>
              {
                state.settingsHash['emailNotifications']?.['enabled'] &&
                  <Grid container direction='column'>
                      <Grid item>
                          <FormGroup>
                              <FormLabel>Emails to be sent for:</FormLabel>
                              <Checkbox dsOnChange={() => {
                              }} checked={true} label='Failures' disabled={true}/>
                              <Checkbox
                                  dsOnChange={(_, emailWarningsChecked) => handleWarningsSwitch(emailWarningsChecked)}
                                  checked={!!state.settingsHash['emailNotifications']?.['warnings']} label='Warnings'/>
                              <Checkbox
                                  dsOnChange={(_, emailSuccessChecked) => handleSuccessSwitch(emailSuccessChecked)}
                                  checked={!!state.settingsHash['emailNotifications']?.['success']} label='Success'/>
                          </FormGroup>
                      </Grid>
                      <Grid item>
                          <Grid container direction='column'>
                            {
                              (state.settingsHash['emailNotifications']?.['emails'] || []).map((item, idx) => (
                                <Grid item>
                                  <TextField error={emailIsInvalid(item)} value={item}
                                             dsOnChange={(event) => handleUpdateEmailAddress(idx, event.target.value)}/>
                                  <Button icon='delete' dsOnClick={() => handleDeleteEmailAddress(idx)}/>
                                </Grid>
                              ))
                            }
                          </Grid>
                      </Grid>
                      <Grid item>
                          <Button variant='text' startIcon='add' children='Add Email'
                                  dsOnClick={() => handleAddEmailAddress()}/>
                      </Grid>
                  </Grid>
              }
            </Grid>
            <Backdrop open={loading} invisible={true}>
              <CircularProgress />
            </Backdrop>
          </>
        }
        footer={(
          <>
            <ModalActions>
              <Button variant='outlined' dsOnClick={() => handleCancel()}>Cancel</Button>
              <Button disabled={!state.emailNotificationsModified || numberOfInvalidEmails > 0 || loading}
                      dsOnClick={async () => {
                        const newState = {
                          ...state,
                          settings_hash: {
                            ...state.settingsHash,
                            fieldMapping: {
                              ...state.settingsHash['fieldMapping'],
                              mappingOptions: {}
                            },
                            emailNotifications: {
                              ...state.settingsHash['emailNotifications']
                            }
                          }
                        }

                        delete newState.settingsHash;

                        NON_PERSISTENT_ITEMS.forEach(tab => delete newState.settings_hash[tab]);

                        if (!loading) {
                          setLoading(true);
                          setToast({...toast, show: false});

                          try {
                            const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
                            const options = {
                              method: state.createOrUpdate === 'update' ? 'PUT' : 'POST',
                              headers: new Headers({
                                'Accept': 'application/json',
                                'Content-type': 'application/json',
                                'X-CSRF-Token': csrf
                              }),
                              body: JSON.stringify(newState)
                            };
                            const response = await window.fetch(state.settingsUrl, options);
                            const data = await response.json();
                            if (!response.ok) throw Error(data['message'] || 'Failed to save settings!');
                            if ("proxy_call" in data) {
                              for (let call of data["proxy_call"]) {
                                let proxied_response = await Connect.proxyCall(call["path"], call["method"], JSON.parse(call["body"]), JSON.parse(call["headers"]));
                                let proxied_data = await proxied_response.json();
                                if (!proxied_response.ok) throw Error(proxied_data['message'] || 'Failed to save settings!');
                              }
                            }
                            if (state.workflow_template?.['workflow_definition']?.['name'] === 'z-IntegrationHub_Workday_IH_Internal_Workday_Outbound') {
                              const response = await Connect.proxyCall(
                                `${workflowProxyPath}/workflows/global_constants`,
                                'PUT',
                                buildWorkdayGlobalConstants(state.settingsHash['authentication'] || {}, state.settingsHash['emailNotifications'] || {}, state.settingsHash['fieldMapping'] || {}),
                                { 'Content-Type': 'application/json', 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
                              );
                              if (!response.ok) throw Error(response.statusText);
                            }
                            setLoading(false);
                            setToast({
                              show: true,
                              msg: SUCCESSFUL_SAVE_MESSAGE,
                              severity: 'success'
                            });
                            dispatch({
                              type: WD_EMAIL_NOTIFICATIONS_SAVE_BUTTON_CLICK
                            });
                            dispatch({
                              type: IAD_SAVE_BTN_CLICK
                            });
                            setOpenEmailModal(false);
                          } catch (error) {
                            Connect.log(error);
                            setLoading(false);
                            setToast({
                              show: true,
                              msg: error.message,
                              severity: 'error'
                            });
                          }
                        }
                      }}>
                Save
              </Button>
            </ModalActions>
          </>
        )}
      />
      { toast.show && <ToastController severity={toast.severity as any} message={toast.msg} /> }
      <Grid container item xs='auto' alignContent='flex-start' alignItems='center' justify='flex-end'>
        <Grid item>
          <FormControlLabel
            style={{pointerEvents: 'none'}}
            label='Enable Integration'
            control={
              <Switch
                size='small'
                checked={checked}
                onChange={(_, checked) => handleEnableIntegration(checked)}
                style={{pointerEvents: 'auto'}}
              />
            }
          />
        </Grid>
        <Grid item>
          <Button dsOnClick={() => {
            setOpenEmailModal(true)
          }} icon='settings' disabled={!state.active} />
        </Grid>
      </Grid>
    </>
  );
};