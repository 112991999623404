export const WDB_ADD_FIELD_MAPPING_ROW = 'WorkdayBilling/addFieldMappingRow';
export const WDB_DELETE_FIELD_MAPPING_ROW = 'WorkdayBilling/deleteFieldMappingRow';
export const WDB_ADD_SOURCE_TARGET = 'WorkdayBilling/addSourceTarget';
export const WDB_LOAD_SOLUTION_INSTANCES = 'WorkdayBilling/loadSolutionInstances';
export const WDB_DELETE_SOLUTION_INSTANCE = 'WorkdayBilling/deleteSolutionInstance';
export const WDB_ADD_SOLUTION_INSTANCE = 'WorkdayBilling/addSolutionInstance';
export const WDB_LOAD_EXECUTIONS = 'WorkdayBilling/loadExecutions';
export const WDB_LOAD_FIELD_MAPPING_OPTIONS = 'WorkdayBilling/loadFieldMappingOptions';
export const WDB_EXECUTE_SOLUTION_INSTANCE = 'WorkdayBilling/executeSolutionInstance';
export const WDB_CHANGE_EMAIL_NOTIFICATIONS_ENABLED = 'WorkdayBilling/changeEmailNotificationsEnabled';
export const WDB_CHANGE_EMAIL_NOTIFICATIONS_FAILURES = 'WorkdayBilling/changeEmailSettingFailures';
export const WDB_CHANGE_EMAIL_NOTIFICATIONS_SUCCESS = 'WorkdayBilling/changeEmailSettingSuccess';
export const WDB_EMAIL_NOTIFICATIONS_ADD_ADDRESS = 'WorkdayBilling/emailNotificationsAddAddress';
export const WDB_EMAIL_NOTIFICATIONS_UPDATE_ADDRESS = 'WorkdayBilling/emailNotificationsUpdateAddress';
export const WDB_EMAIL_NOTIFICATIONS_DELETE_ADDRESS = 'WorkdayBilling/emailNotificationsDeleteAddress';
export const WDB_EMAIL_NOTIFICATIONS_CANCEL_BUTTON_CLICK = 'WorkdayBilling/emailNotificationsCancelButtonClick';
export const WDB_EMAIL_NOTIFICATIONS_SAVE_BUTTON_CLICK = 'WorkdayBilling/emailNotificationsSaveButtonClick';
export const WDB_SAVE_SOLUTION_INSTANCE_OBJECTS = 'WorkdayBilling/saveSolutionInstanceObjects';
export const WDB_UPDATE_SOLUTION_INSTANCE = 'WorkdayBilling/updateSolutionInstance';
export const WDB_PROVISION_SOLUTION_INSTANCE_ERROR = 'WorkdayBilling/provisionSolutionInstanceError';
export const WDB_UPDATE_SOLUTION_INSTANCE_SCHEDULE = 'WorkdayBilling/updateSolutionInstanceSchedule';
export const WDB_FILTER_FIELD_MAPPING_ROWS = 'WorkdayBilling/filterFieldMappingRows';
export const WDB_TOGGLE_EDIT_FIELD_MAPPINGS = 'WorkdayBilling/toggleEditFieldMappings';
export const WDB_TOGGLE_REPORT_TYPE = 'WorkdayBilling/toggleReportType';