import { ChipProps } from '@mui/material';
import { Chip, Modal, ModalTitle, Table, Typography } from '@platform-ui/design-system';
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import Connect from '../../../../Connect/Connect';
import { useToast } from '../../../../ToastProvider';

interface TaskModalProps {
  tableName: string;
  workflowRunId: number;
  workflowRunNumber: string;
  open: boolean;
  setOpenTasksModal: Dispatch<SetStateAction<boolean>>;
  taskRerunEnabled: boolean;
}

const EXECUTION_TASKS_SUMMARY_COLUMNS = [
  {
    field: 'id',
    label: 'Task Id',
    sortable: false
  },
  {
    field: 'run_number',
    label: 'Run Number',
    sortable: false
  },
  {
    field: 'name',
    label: 'Task Name',
    sortable: false
  },
  {
    field: 'type',
    label: 'Type',
    sortable: false
  },
  {
    field: 'status',
    label: 'Status',
    sortable: false,
    dsRenderCell: ({ value }) => {
      let state: ChipProps['color'] = 'success';

      switch (value) {
        case 'Success':
          state = 'success';
          break;
        case 'Error':
          state = 'error';
          break;
        case 'Processing':
          state = 'primary';
          break;
        case 'Queued':
        case 'Pending':
        case 'Stopped':
          state = 'default';
          break;
        default:
          state = 'default';
      }

      return <Chip label={<Typography variant='overline'>{value}</Typography>} size='medium' state={state} />
    }
  },
  {
    field: 'error',
    label: 'Error Code',
    sortable: false
  },
  {
    field: 'start_time',
    label: 'Started At',
    sortable: false
  },
  {
    field: 'end_time',
    label: 'Finished At',
    sortable: false
  }
];

export const TaskModal: FC<TaskModalProps> = ({
  tableName,
  workflowRunId,
  workflowRunNumber,
  open,
  setOpenTasksModal,
  taskRerunEnabled
}: TaskModalProps) => {
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [columns, setColumns] = useState(() => {
    const savedColumns = JSON.parse(localStorage.getItem(`${tableName}_tasks_summary_columns`));
    if (savedColumns) {
      const merged = savedColumns.map(savedColumn => {
        const matchedColumn = EXECUTION_TASKS_SUMMARY_COLUMNS.find(col => col.field === savedColumn.field);
        if (matchedColumn?.dsRenderCell) {
          savedColumn.dsRenderCell = matchedColumn.dsRenderCell;
        }
        savedColumn.sortable = !!matchedColumn?.sortable;
        return savedColumn;
      });
      return merged;
    } else {
      return EXECUTION_TASKS_SUMMARY_COLUMNS;
    }
  });
  const [pageDetails, setPageDetails] = useState({
    pageSize: 10,
    currentPage: 0
  });
  const [singlePageLoadingOptions, setSinglePageLoadingOptions] = useState({
    isPreviousPageDisabled: true,
    isNextPageDisabled: true,
    currentPage: 0,
  });
  const { setToast } = useToast();
  const connect: Connect = (window as any).connect;
  const isLocal = window.location.host.includes('localhost');
  const workflowProxyPath = `${isLocal ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2`;

  const getTasks = async ({ page, rowsPerPage }) => {
    setLoading(true);
    setPageDetails({ currentPage: page, pageSize: rowsPerPage });

    try {
      const extraQueryParams = `page=${page + 1}&page_length=${rowsPerPage}`;

      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/tasks?workflow_id=${workflowRunId}&${extraQueryParams}`,
        'GET',
        undefined,
        { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
      );

      if (!response.ok) throw Error(response.statusText);

      const { data, pagination } = await response.json();

      const tasks = data.map(t => ({
        id: t.id,
        run_number: workflowRunNumber,
        name: t.name,
        type: t.action_type,
        status: t.status || 'N/A',
        error: t.error || 'N/A',
        start_time: t.start_time || 'N/A',
        end_time: t.end_time || 'N/A'
      }));

      setLoading(false);
      setSinglePageLoadingOptions({
        currentPage: page,
        isPreviousPageDisabled: page === 0,
        isNextPageDisabled: !pagination.next_page
      });
      setTasks(tasks);
    } catch (err) {
      Connect.log(err);
      setLoading(false);
    }
  }

  const onRerunClickHandler = async (args: { row: any }) => {
    const { row } = args;

    if (!confirm(`Are you sure you want to rerun ${row.name}`)) {
      return;
    }

    setLoading(true);
    try {
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/tasks/${row.id}/rerun`,
        'POST',
        undefined,
        {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Scope': 'Internal',
          'Accept': 'application/json'
        }
      );

      if (!response.ok) throw Error(response.statusText);

      await getTasks({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize });

      setLoading(false);
      setToast({
        message: `Successfully reran ${row.name}`,
        severity: 'success',
        keyRender: Date.now()
      });
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        message: `Failed to rerun ${row.name}`,
        severity: 'error',
        keyRender: Date.now()
      });
    }
  };

  useEffect(() => {
    if (workflowRunId && open) {
      getTasks({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize });
    } else {
      setTasks([]);
    }
  }, [workflowRunId, open]);

  return (
    <Modal
      id={`execution-tasks-modal-${workflowRunId}`}
      fullWidth
      disableBackdropClick
      open={open}
      dsOnClose={() => {
        setOpenTasksModal(false);
        setPageDetails({
          pageSize: 10,
          currentPage: 0
        });
      }}
      header={
        <ModalTitle
          dsOnClose={() => {
            setOpenTasksModal(false);
            setPageDetails({
              pageSize: 10,
              currentPage: 0
            });
          }}
        >
          Task Summary
        </ModalTitle>
      }
      body={
        <Table
          uniqueKey={`${tableName}-tasks-table-${workflowRunId}`}
          loading={loading}
          columns={columns}
          rows={tasks}
          tableActions={[
            {
              icon: 'refresh',
              tooltip: 'Reload Table',
              onClick: () => getTasks({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize })
            }
          ]}
          rowActions={
            taskRerunEnabled ? [
              {
                dsGetActionData: (row) => ({
                  icon: 'play_circle_outline',
                  tooltip: 'Rerun',
                  disabled: row.status !== 'Error',
                  onClick: onRerunClickHandler
                })
              }
            ] : []
          }
          rowDisplayOptions={{
            hoverEffect: false
          }}
          rowsPerPage={pageDetails.pageSize}
          rowsPerPageOptions={[5, 10, 15, 20, 30, 45]}
          dsOnPage={getTasks}
          hideTotalRows
          singlePageLoadingOptions={singlePageLoadingOptions}
          orderable
          dsOnColumnsChange={(columns) => {
            localStorage.setItem(`${tableName}_tasks_summary_columns`, JSON.stringify(columns));
          }}
        />
      }
    />
  );
};
