import {
  AutoThemeProvider,
  ToastController,
  ToastControllerProps,
} from '@platform-ui/design-system';
import React, { ReactNode, useMemo, useState } from 'react';
import ToastContext from './ToastContext';

const DEFAULT_TOAST_STATE = {
  message: '',
  severity: 'error',
} as Pick<ToastControllerProps, 'message' | 'severity'>;

const ToastProvider = ({
  children,
  overrides,
}: {
  children: ReactNode;
  overrides?: Pick<ToastControllerProps, 'duration'>;
}) => {
  const [toastState, setToastState] =
    useState<ToastControllerProps>(DEFAULT_TOAST_STATE);

  const value = useMemo(
    () => ({
      setToast: (props) =>
        setToastState({ ...props, ...overrides, keyRender: Date.now() }),
      closeToast: () =>
        setToastState({
          ...DEFAULT_TOAST_STATE,
          duration: 0,
          keyRender: Date.now(),
        }),
    }),
    [overrides],
  );

  return (
    <AutoThemeProvider>
      <ToastContext.Provider value={value}>
        <ToastController {...toastState} />
        {children}
      </ToastContext.Provider>
    </AutoThemeProvider>
  );
};

export default ToastProvider;