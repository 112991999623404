import React, { FC, ReactNode } from 'react';

interface TabPanelProps {
  children: ReactNode;
  tabs: number;
  index: number;
  id?: string;
}

export const TabPanel: FC<TabPanelProps> = ({
  children,
  tabs,
  index,
  id,
}: TabPanelProps) => {
  return (
    <div hidden={tabs !== index} id={id}>{children}</div>
  );
};