module Flipper {
  
  export async function enabled(key: String, object_class: String, flipper_id: String): Promise<boolean> {
    let path = "/flippers/feature.json"
    switch(object_class.toLowerCase()){
      case 'organization':
        path = path + "?organization_id="+flipper_id+"&feature_key="+key;
        break;
      case 'tenant':
        path = path +"?tenant_id="+flipper_id+"&feature_key="+key;
        break;
      case 'user':
        path = path +"?user_id="+flipper_id+"&feature_key="+key;
        break;
      case 'integrationapp':
        path = path +"?integration_app_id="+flipper_id+"&feature_key="+key;
        break;
      default:
        throw new Error("not a supported object");
    }
    
    try{
      if (!window.location.host.includes('connect') && !window.location.host.includes('localhost')) {
        path = '/services/connect' + path;
      }

      const response = await window.fetch(path, {headers: {
        'Accept': 'application/json'
      }});
      if (!response.ok) throw Error(response.statusText);
      const actions = await response.json();
      return actions["result"]
    }
    catch(error){
      console.error(error)
    }
    return Promise.resolve(false);
  }
}

export default Flipper