import { Button, Chip, Grid, ToastController, Typography, designTokens } from '@platform-ui/design-system';
import React, { FC, useState } from 'react';
import { useStoreState } from '../Store';
import { IADState } from '../IntegrationApps/IntegrationAppDetails/state';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Connect from '../Connect/Connect';

interface BasicTemplateHeaderTitleProps {
  header?: string;
  subheader?: string;
  showTestConnection?: boolean;
  tabName?: string;
}

const BasicTemplateHeaderTitle: FC<BasicTemplateHeaderTitleProps> = ({
  header,
  subheader,
  showTestConnection,
  tabName
}: BasicTemplateHeaderTitleProps) => {
  const state: IADState = useStoreState();
  const activeChip = state.active ? <Chip size='medium' label={<Typography variant='overline'>Active</Typography>} state='success' /> : <Chip size='medium' label={<Typography variant='overline'>Inactive</Typography>} state='indeterminate' />;
  const testConnectionClick = async () => {
    if (!loading) {
      setLoading(true);
      setToast({...toast, show: false});

      try {
        const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
        const options = {
          method: 'POST',
          headers: new Headers({
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'X-CSRF-Token': csrf
          }),
          body: JSON.stringify({ auth: state.settingsHash?.[tabName] || null })
        };

        const response = await window.fetch(`${state.settingsUrl.replace('.json', '/netsuite/validate')}`, options);
        if (!response.ok) throw Error(response.statusText);

        const jsonResponse = await response.json();

        setLoading(false);
        setToast({
          show: true,
          msg: jsonResponse.error ? jsonResponse.error : 'Successfully Connected!!!',
          severity: jsonResponse.error ? 'error' : 'success'
        });
      } catch (error) {
        Connect.log(error);
        setLoading(false);
        setToast({
          show: true,
          msg: error.message || 'Internal Server Error',
          severity: 'error'
        });
      }
    }
  };
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success'
  });

  return (
    <>
      <Grid container item xs direction='column' spacing={0.5}>
        <Grid container item alignItems='center' spacing={1}>
          <Grid item><Typography variant='h5'>{header}</Typography></Grid>
          <Grid item>{activeChip}</Grid>
        </Grid>
        <Grid item><Typography variant='body2'>{subheader}</Typography></Grid>
      </Grid>
      { toast.show && <ToastController severity={toast.severity as any} message={toast.msg} /> }
      {
        showTestConnection &&
        <div style={{paddingTop: '10px', paddingRight: '5px'}}>
          <Box sx={{m: 1, position: 'relative'}}>
            <Button disabled={loading} variant='text' children='Test Connection' dsOnClick={testConnectionClick} />
            {
              loading &&
              <CircularProgress
                size={24}
                sx={{
                  color: designTokens.colors.blue500,
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px'
                }}
              />
            }
          </Box>
        </div>
      }
    </>
  );
};

export default BasicTemplateHeaderTitle;