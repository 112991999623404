import { Draft } from 'immer';
import {
  NS_ADD_FIELD_MAPPING_ROW,
  NS_ADD_SOLUTION_INSTANCE,
  NS_ADD_SOURCE_TARGET,
  NS_CHANGE_EMAIL_NOTIFICATIONS_ENABLED,
  NS_CHANGE_EMAIL_NOTIFICATIONS_SUCCESS,
  NS_CHANGE_EMAIL_NOTIFICATIONS_WARNINGS,
  NS_DELETE_FIELD_MAPPING_ROW,
  NS_DELETE_SOLUTION_INSTANCE,
  NS_EMAIL_NOTIFICATIONS_ADD_ADDRESS, NS_EMAIL_NOTIFICATIONS_CANCEL_BUTTON_CLICK,
  NS_EMAIL_NOTIFICATIONS_DELETE_ADDRESS, NS_EMAIL_NOTIFICATIONS_SAVE_BUTTON_CLICK,
  NS_EMAIL_NOTIFICATIONS_UPDATE_ADDRESS,
  NS_EXECUTE_SOLUTION_INSTANCE,
  NS_LOAD_EXECUTIONS,
  NS_LOAD_FIELD_MAPPING_OPTIONS,
  NS_LOAD_SOLUTION_INSTANCES,
  NS_PROVISION_SOLUTION_INSTANCE_ERROR,
  NS_SAVE_SOLUTION_INSTANCE_OBJECTS,
  NS_UPDATE_SOLUTION_INSTANCE,
  NS_UPDATE_SOLUTION_INSTANCE_SCHEDULE,
  NS_FILTER_FIELD_MAPPING_ROWS
} from './action_types';
import {IADState} from '../../../IntegrationApps/IntegrationAppDetails/state';

export const actionMap = {
  [NS_ADD_FIELD_MAPPING_ROW]: (draft: Draft<IADState>, payload: any) => {
    const name = payload.instanceName;
    if (!draft.settingsHash['fieldMapping']) {
      draft.settingsHash['fieldMapping'] = payload.inbound ? { 
        [name]: {[payload.objectName]: {}}
      } : {
        [name]: {}
      };
      draft.settingsHash['filteredFieldMapping'] = payload.inbound ? { 
        [name]: {[payload.objectName]: {}}
      } : {
        [name]: {}
      };
    }

    if (!draft.settingsHash['fieldMapping'][name]) {
      draft.settingsHash['fieldMapping'][name] = payload.inbound ? {[payload.objectName]: {}} : {};
    }
    
    if (!draft.settingsHash['filteredFieldMapping'][name]) {
      draft.settingsHash['filteredFieldMapping'][name] = payload.inbound ? {[payload.objectName]: {}} : {};
    }

    if (payload.inbound) {
      if (!draft.settingsHash['fieldMapping'][name][payload.objectName]) {
        draft.settingsHash['fieldMapping'][name][payload.objectName] = {};
      }

      if (!draft.settingsHash['filteredFieldMapping'][name][payload.objectName]) {
        draft.settingsHash['filteredFieldMapping'][name][payload.objectName] = {};
      }

      if (!draft.settingsHash['fieldMapping'][name][payload.objectName].mappings) {
        draft.settingsHash['fieldMapping'][name][payload.objectName].mappings = {};
      }

      if (!draft.settingsHash['fieldMapping'][name][payload.objectName].mappingRows) {
        draft.settingsHash['fieldMapping'][name][payload.objectName].mappingRows = {};
        draft.settingsHash['filteredFieldMapping'][name][payload.objectName].mappingRows = {};
      }

      draft.settingsHash['fieldMapping'][name][payload.objectName].mappingRows[payload.rowId] = payload[name];
      draft.settingsHash['filteredFieldMapping'][name][payload.objectName].mappingRows[payload.rowId] = payload[name];
      draft.settingsHash['fieldMapping'][name][payload.objectName].instanceIdx = payload.instanceIdx;
    } else {
      if (!draft.settingsHash['fieldMapping'][name].mappings) {
        draft.settingsHash['fieldMapping'][name].mappings = {};
      }

      if (!draft.settingsHash['fieldMapping'][name].mappingRows) {
        draft.settingsHash['fieldMapping'][name].mappingRows = {};
        draft.settingsHash['filteredFieldMapping'][name].mappingRows = {};
      }

      draft.settingsHash['fieldMapping'][name].mappingRows[payload.rowId] = payload[name];
      draft.settingsHash['filteredFieldMapping'][name].mappingRows[payload.rowId] = payload[name];
    }
   
    draft.modified = true;
    draft.solutionInstanceUpdated = false;
  },
  [NS_DELETE_FIELD_MAPPING_ROW]: (draft: Draft<IADState>, payload: any) => {
    if (payload.objectName) {
      delete draft.settingsHash['fieldMapping'][payload.instanceName][payload.objectName].mappingRows[payload.rowId];
      delete draft.settingsHash['fieldMapping'][payload.instanceName][payload.objectName].mappings[payload.rowId];
      delete draft.settingsHash['filteredFieldMapping'][payload.instanceName][payload.objectName].mappingRows[payload.rowId];
      delete draft.settingsHash['filteredFieldMapping'][payload.instanceName][payload.objectName].mappings[payload.rowId];
    } else {
      delete draft.settingsHash['fieldMapping'][payload.instanceName].mappingRows[payload.rowId];
      delete draft.settingsHash['fieldMapping'][payload.instanceName].mappings[payload.rowId];
      delete draft.settingsHash['filteredFieldMapping'][payload.instanceName].mappingRows[payload.rowId];
      delete draft.settingsHash['filteredFieldMapping'][payload.instanceName].mappings[payload.rowId];
    }
    
    draft.modified = true;
    draft.solutionInstanceUpdated = false;
  },
  [NS_ADD_SOURCE_TARGET]: (draft: Draft<IADState>, payload: any) => {
    if (payload.objectName) {
      const source = draft.settingsHash['fieldMapping'][payload.instanceName][payload.objectName].mappings[payload.rowId]?.['source'];
      const target = draft.settingsHash['fieldMapping'][payload.instanceName][payload.objectName].mappings[payload.rowId]?.['target'];
      draft.settingsHash['fieldMapping'][payload.instanceName][payload.objectName].mappings[payload.rowId] = {
        source: payload.source || source,
        target: payload.target || target
      };
    } else {
      const source = draft.settingsHash['fieldMapping'][payload.instanceName].mappings[payload.rowId]?.['source'];
      const target = draft.settingsHash['fieldMapping'][payload.instanceName].mappings[payload.rowId]?.['target'];
      draft.settingsHash['fieldMapping'][payload.instanceName].mappings[payload.rowId] = {
        source: payload.source || source,
        target: payload.target || target
      };
    }
    
    draft.modified = true;
    draft.solutionInstanceUpdated = false;
  },
  [NS_LOAD_SOLUTION_INSTANCES]: (draft: Draft<IADState>, payload: any) => {
    if (!draft.settingsHash['solutionInstances']) {
      draft.settingsHash['solutionInstances'] = [];
    }
    const instanceIds = payload.map(e => e.id);
    draft.settingsHash['solutionInstances'] = draft.settingsHash['solutionInstances'].filter(e => instanceIds.includes(e.id));
    payload.forEach(si => {
      const idx = draft.settingsHash['solutionInstances'].findIndex(e => e.id === si.id);
      if (idx === -1) {
        draft.settingsHash['solutionInstances'].push(si);
      } else {
        draft.settingsHash['solutionInstances'][idx] = {
          ...draft.settingsHash['solutionInstances'][idx],
          ...si
        };
      }
    });
  },
  [NS_DELETE_SOLUTION_INSTANCE]: (draft: Draft<IADState>, payload: any) => {
    draft.settingsHash['solutionInstances'] = draft.settingsHash['solutionInstances'].filter(instance => instance.id != payload.id);
  },
  [NS_ADD_SOLUTION_INSTANCE]: (draft: Draft<IADState>, payload: any) => {
    if (!draft.settingsHash['solutionInstances']) {
      draft.settingsHash['solutionInstances'] = [];
    }
    draft.settingsHash['solutionInstances']?.push(payload);
    draft.provisionSolutionInstanceError = false;
  },
  [NS_UPDATE_SOLUTION_INSTANCE_SCHEDULE]: (draft: Draft<IADState>, payload: any) => {
    if (!draft.settingsHash['solutionInstances']) {
      draft.settingsHash['solutionInstances'] = [];
    }
    const instance = draft.settingsHash['solutionInstances'].find(instance => instance.id === payload.id);
    if (instance) {
      instance.scheduled_trigger = payload.scheduled_trigger;
      instance.interval = payload.interval;
      instance.timezone = payload.timezone;
      draft.solutionInstanceUpdated = true;
    }
  },
  [NS_LOAD_EXECUTIONS]: (draft: Draft<IADState>, payload: any) => {
    draft.settingsHash['executions'] = [...payload];
  },
  [NS_LOAD_FIELD_MAPPING_OPTIONS]: (draft: Draft<IADState>, payload: any) => {
    if (!draft.settingsHash['fieldMapping']) {
      draft.settingsHash['fieldMapping'] = {
        mappingOptions: {
          netsuiteFields: [],
          zuoraFields: []
        }
      };
    }
    draft.settingsHash['fieldMapping'].mappingOptions = {
      netsuiteFields: payload.netsuiteFields,
      zuoraFields: payload.zuoraFields
    };

    // regenerate mapping rows as mappingOptions change
    const keys = Object.keys(draft.settingsHash['fieldMapping']).filter(key => !['inbound', 'mappingOptions'].includes(key));
    keys.forEach(key => {
      const instance = draft.settingsHash['fieldMapping'][key];
      const rows = {};
      Object.keys(instance['mappings'] || {}).forEach(key => {
        rows[key] = {
          key,
          rowId: key,
          [`netsuite_${payload.inbound ? 'source' : 'target'}_fields`]: JSON.stringify(payload.netsuiteFields),
          '': '→',
          [`zuora_${payload.inbound ? 'target' : 'source'}_fields`]: JSON.stringify(payload.zuoraFields)
        }
      })
      instance.mappingRows = rows;
    });
    /**
     * sync with the fieldMapping state that is used in the table component
     * fieldMapping holds the filtered rows based on the search value
     */ 
    
    draft.settingsHash['filteredFieldMapping'] = draft.settingsHash['fieldMapping'];
  },
  [NS_EXECUTE_SOLUTION_INSTANCE]: (draft: Draft<IADState>, payload: any) => {
    if (!draft.settingsHash['executions']) {
      draft.settingsHash['executions'] = [];
    }

    draft.settingsHash['executions'] = [...draft.settingsHash['executions'], payload];
  },
  [NS_CHANGE_EMAIL_NOTIFICATIONS_ENABLED]: (draft: Draft<IADState>, enabled: boolean) => {
    if (!draft.settingsHash['emailNotifications']) {
      draft.settingsHash['emailNotifications'] = {};
      draft.settingsHash['emailNotifications']['enabled'] = false;
      draft.settingsHash['emailNotifications']['warnings'] = false;
      draft.settingsHash['emailNotifications']['success'] = false;
      draft.settingsHash['emailNotifications']['emails'] = [];
    }
    draft.settingsHash['emailNotifications']['enabled'] = enabled;
    draft.emailNotificationsModified = true;
  },
  [NS_CHANGE_EMAIL_NOTIFICATIONS_WARNINGS]: (draft: Draft<IADState>, warnings: boolean) => {
    draft.settingsHash['emailNotifications']['warnings'] = warnings;
    draft.emailNotificationsModified = true;
  },
  [NS_CHANGE_EMAIL_NOTIFICATIONS_SUCCESS]: (draft: Draft<IADState>, success: boolean) => {
    draft.settingsHash['emailNotifications']['success'] = success;
    draft.emailNotificationsModified = true;
  },
  [NS_EMAIL_NOTIFICATIONS_ADD_ADDRESS]: (draft: Draft<IADState>) => {
    if (!draft.settingsHash['emailNotifications']['emails']) {
      draft.settingsHash['emailNotifications']['emails'] = [];
    }
    draft.settingsHash['emailNotifications']['emails'].push('');
    draft.emailNotificationsModified = true;
  },
  [NS_EMAIL_NOTIFICATIONS_UPDATE_ADDRESS]: (draft: Draft<IADState>, payload: any) => {
    draft.settingsHash['emailNotifications']['emails'][payload.index] = payload.email;
    draft.emailNotificationsModified = true;
  },
  [NS_EMAIL_NOTIFICATIONS_DELETE_ADDRESS]: (draft: Draft<IADState>, index: number) => {
    draft.settingsHash['emailNotifications']['emails'].splice(index, 1);
    draft.emailNotificationsModified = true;
  },
  [NS_EMAIL_NOTIFICATIONS_CANCEL_BUTTON_CLICK]: (draft: Draft<IADState>) => {
    draft.settingsHash['emailNotifications'] = draft.originalEmailNotifications;
    draft.emailNotificationsModified = false;
  },
  [NS_EMAIL_NOTIFICATIONS_SAVE_BUTTON_CLICK]: (draft: Draft<IADState>) => {
    draft.originalEmailNotifications = draft.settingsHash['emailNotifications'];
    draft.emailNotificationsModified = false;
  },
  [NS_SAVE_SOLUTION_INSTANCE_OBJECTS]: (draft: Draft<IADState>, payload: any) => {
    const idx = draft.settingsHash['solutionInstances'].findIndex(si => si.id === payload.id);
    draft.settingsHash['solutionInstances'][idx] = {...payload};
  },
  [NS_UPDATE_SOLUTION_INSTANCE]: (draft: Draft<IADState>) => {
    draft.solutionInstanceUpdated = true;
  },
  [NS_PROVISION_SOLUTION_INSTANCE_ERROR]: (draft: Draft<IADState>) => {
    draft.provisionSolutionInstanceError = true;
  },
  [NS_FILTER_FIELD_MAPPING_ROWS]: (draft: Draft<IADState>, payload: any) => {
    const inbound = payload.inbound;
    const name = payload.instanceName;
    const objectName = payload.objectName;
    const allMappings = payload.inbound
      ? draft.settingsHash['fieldMapping'][name]?.[objectName]?.mappings
      : draft.settingsHash['fieldMapping'][name]?.mappings;

    const allMappingRows = payload.inbound
      ? draft.settingsHash['fieldMapping'][name]?.[objectName]?.mappingRows
      : draft.settingsHash['fieldMapping'][name]?.mappingRows;
    const filteredRows = {};

    Object.keys(allMappings).forEach((key) => {
      const row = allMappings[key];

      if (row['source']['text']?.toLowerCase().indexOf(payload.filterValue.toLowerCase()) !== -1 || row['target']['text']?.toLowerCase().indexOf(payload.filterValue.toLowerCase()) !== -1) {
        filteredRows[key] = allMappingRows[key];
      }
    });
    if (inbound) {
      draft.settingsHash['filteredFieldMapping'][name][objectName].mappingRows = filteredRows;
    } else {
      draft.settingsHash['filteredFieldMapping'][name].mappingRows = filteredRows;
    }
  }
};