import { Button, Icon, Modal, ModalActions, ModalTitle, Typography } from '@platform-ui/design-system';
import React, { FC } from 'react';
import Connect from '../../../../../Connect/Connect';
import { useStoreDispatch } from '../../../../../Store';
import { useToast } from '../../../../../ToastProvider';
import { DeleteModalProps } from './types';

export const DeleteModal: FC<DeleteModalProps> = ({
  openDeleteModal,
  setOpenDeleteModal,
  rows,
  setRows,
  deletedDest
}: DeleteModalProps) => {
  const dispatch = useStoreDispatch();
  const { setToast } = useToast();

  return (
    <Modal
      id='delete-destination-modal'
      open={!!deletedDest && openDeleteModal}
      disableBackdropClick={true}
      dsOnClose={() => setOpenDeleteModal(false)}
      header={
        <ModalTitle dsOnClose={() => setOpenDeleteModal(false)} icon={<Icon color='warning' body='warning' />}>Delete a Destination</ModalTitle>
      }
      body={<Typography>Are you sure you want to delete this destination?</Typography>}
      footer={
        <ModalActions>
          <Button variant='outlined' dsOnClick={() => setOpenDeleteModal(false)} body='No' />
          <Button
            body='Yes'
            dsOnClick={() => {
              try {
                const clone = [...rows].filter(
                  row => (
                    row.orgId !== deletedDest?.orgId ||
                    row.orgName !== deletedDest?.orgName ||
                    row.customerId !== deletedDest?.customerId ||
                    row.destSystem !== deletedDest?.destSystem ||
                    row.destName !== deletedDest?.destName
                  )
                );
                setRows(clone);
                dispatch({
                  type: 'destination',
                  payload: clone
                });
              } catch (error) {
                Connect.log(error);
                setToast({
                  message: 'Failed to delete a destination. Please try again later.',
                  severity: 'error',
                  keyRender: Date.now()
                });
              } finally {
                setOpenDeleteModal(false);
              }
            }}
          />
        </ModalActions>
      }
    />
  );
};