import React, { Dispatch, FC, useEffect, useState } from 'react';
import { IADState } from '../../../../IntegrationApps/IntegrationAppDetails/state';
import { Action, useStoreContext } from '../../../../Store';
import { SolutionInstancesDetails } from './SolutionInstancesDetails';
import { SolutionInstancesTable } from './SolutionInstancesTable';
import { Alert } from '@platform-ui/design-system';

interface SolutionInstancesProps {
  inbound: boolean;
  tabName?: string;
}

export const SolutionInstances: FC<SolutionInstancesProps> = ({
  inbound,
  tabName
}: SolutionInstancesProps) => {
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const [instanceDetails, setInstanceDetails] = useState({
    id: 0,
    show: false,
    name: '',
    withMappings: false
  });

  if (!state.active) {
    return (
      <Alert center variant='outlined' severity='warning' body='You must enable integration in Authentication tab first' open={true} />
    );
  }

  return (
    <>
      {
        instanceDetails.show ? 
        <SolutionInstancesDetails id={instanceDetails.id} name={instanceDetails.name} withMappings={instanceDetails.withMappings} setShowInstanceDetails={(show: boolean) => setInstanceDetails({...instanceDetails, show})} /> :
        <SolutionInstancesTable
          inbound={inbound}
          rows={state.settingsHash[tabName] || []}
          setInstanceDetails={(id: number, name: string, withMappings: boolean, show: boolean) => setInstanceDetails({...instanceDetails, id, name, withMappings, show})}
        />
      }
    </>
  );
};