import { Alert, Card, ColumnType, Table } from '@platform-ui/design-system';
import React, { FC, useEffect, useState } from 'react';
import Connect, { windmillRun } from '../../../../../Connect/Connect';
import { useIdentity } from '../../../../../IdentityProvider';
import { useStoreState } from '../../../../../Store';
import { useToast } from '../../../../../ToastProvider';
import { IADState } from '../../../../../IntegrationApps/IntegrationAppDetails/state';
import { AddModal } from './AddModal';
import { DeleteModal } from './DeleteModal';
import { DestinationTableProps, OrgOption } from './types';

const COLUMN_DEFINITIONS: Array<ColumnType> = [
  {
    field: 'orgName',
    label: 'Organization Name'
  },
  {
    field: 'customerId',
    label: 'Customer ID'
  },
  {
    field: 'destSystem',
    label: 'Destination System'
  },
  {
    field: 'destName',
    label: 'Destination Name'
  }
];

export const DestinationTable: FC<DestinationTableProps> = ({
  system,
  permissionCheckDisabled,
  permissionName
}: DestinationTableProps) => {
  const state = useStoreState() as IADState;
  const [rows, setRows] = useState(state.settingsHash['destination'] || []);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orgOptions, setOrgOptions] = useState<Array<OrgOption>>([]);
  const [deletedDest, setDeletedDest] = useState(null);
  const { setToast } = useToast();
  const { hasPermission } = useIdentity();
  const isConnectorEnabled = permissionCheckDisabled ? true : hasPermission(permissionName);
  const searchParams = new URLSearchParams(window.location.search);
  const activeTabName = searchParams.get('tabname');

  const fetchOrgs = async () => {
    setLoading(true);
    try {
      const action = system === 'Billing' ? 'get_billing_orgs' : 'get_revenue_orgs';
      const auth = system === 'Billing'
        ? { ...state.settingsHash['authentication'], SAP_BILLING_REST_ENDPOINT: state.envRestEndpoint }
        : state.settingsHash['authentication'];

      const response = await windmillRun({
        script_path: 'f/sap_connector/flows',
        params: { action, auth },
        async: false
      });

      if (response.statusCode === 200) {
        const { organizations }: { organizations: Array<OrgOption> } = response;
        setOrgOptions(organizations.map(org => ({ id: org.id, name: org.name })));
      } else {
        throw Error(`${response.statusCode} - ${response.statusText}`);
      }
    } catch (error) {
      Connect.log(`Destination loading orgs error: ${error}`);
      setToast({
        message: (
          <>
            Failed to load organizations.<br/>
            Reason: {error.message}.<br/>
            Please contact admin or try again later. 
          </>
        ),
        severity: 'error',
        keyRender: Date.now()
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    // Fetch orgs when connector is enabled on page load
    if (isConnectorEnabled && !state.credsModified && activeTabName === 'destination') {
      fetchOrgs();
    }
  }, [isConnectorEnabled, state.credsModified, activeTabName]);

  useEffect(() => {
    // Reload orgs when credentials are modified and saved
    if (state.saved && activeTabName === 'destination') {
      fetchOrgs();
    }
  }, [activeTabName]);

  if (!isConnectorEnabled) {
    return (
      <Alert open center variant='outlined' severity='warning' body={`Missing required permission '${permissionName}'`} />
    );
  }

  return (
    <>
      <AddModal
        orgOptions={orgOptions}
        openAddModal={openAddModal}
        setOpenAddModal={setOpenAddModal}
        rows={rows}
        setRows={setRows}
      />
      {
        openDeleteModal &&
        <DeleteModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          rows={rows}
          setRows={setRows}
          deletedDest={deletedDest}
        />
      }
      <Card
        id='sap-destination-card'
        body={
          <Table
            uniqueKey='sap-destination-table'
            loading={loading}
            columns={orgOptions.length > 0 ? COLUMN_DEFINITIONS : COLUMN_DEFINITIONS.filter(def => def.field !== 'orgName')}
            rows={rows}
            rowActions={[
              {
                icon: 'delete',
                tooltip: 'Delete a Destination',
                onClick: ((args: { row: any }) => {
                  setDeletedDest(args.row);
                  setOpenDeleteModal(true);
                })
              }
            ]}
            rowsPerPage={10}
            rowsPerPageOptions={[5, 10, 15, 20, 30, 45]}
            tableActions={[
              {
                  icon: 'add',
                  tooltip: 'Add a Destination',
                  disabled: orgOptions.length > 0 ? false : rows?.length > 0,
                  onClick: () => setOpenAddModal(true)
              }
            ]}
          />
        }
      />
    </>
  );
};