export const NS_ADD_FIELD_MAPPING_ROW = 'NetSuite/addFieldMappingRow';
export const NS_DELETE_FIELD_MAPPING_ROW = 'NetSuite/deleteFieldMappingRow';
export const NS_ADD_SOURCE_TARGET = 'NetSuite/addSourceTarget';
export const NS_LOAD_SOLUTION_INSTANCES = 'NetSuite/loadSolutionInstances';
export const NS_DELETE_SOLUTION_INSTANCE = 'NetSuite/deleteSolutionInstance';
export const NS_ADD_SOLUTION_INSTANCE = 'NetSuite/addSolutionInstance';
export const NS_LOAD_EXECUTIONS = 'NetSuite/loadExecutions';
export const NS_LOAD_FIELD_MAPPING_OPTIONS = 'NetSuite/loadFieldMappingOptions';
export const NS_EXECUTE_SOLUTION_INSTANCE = 'NetSuite/executeSolutionInstance';
export const NS_CHANGE_EMAIL_NOTIFICATIONS_ENABLED = 'NetSuite/changeEmailNotificationsEnabled';
export const NS_CHANGE_EMAIL_NOTIFICATIONS_WARNINGS = 'NetSuite/changeEmailSettingWarnings';
export const NS_CHANGE_EMAIL_NOTIFICATIONS_SUCCESS = 'NetSuite/changeEmailSettingSuccess';
export const NS_EMAIL_NOTIFICATIONS_ADD_ADDRESS = 'NetSuite/emailNotificationsAddAddress';
export const NS_EMAIL_NOTIFICATIONS_UPDATE_ADDRESS = 'NetSuite/emailNotificationsUpdateAddress';
export const NS_EMAIL_NOTIFICATIONS_DELETE_ADDRESS = 'NetSuite/emailNotificationsDeleteAddress';
export const NS_EMAIL_NOTIFICATIONS_CANCEL_BUTTON_CLICK = 'NetSuite/emailNotificationsCancelButtonClick';
export const NS_EMAIL_NOTIFICATIONS_SAVE_BUTTON_CLICK = 'NetSuite/emailNotificationsSaveButtonClick';
export const NS_SAVE_SOLUTION_INSTANCE_OBJECTS = 'NetSuite/saveSolutionInstanceObjects';
export const NS_UPDATE_SOLUTION_INSTANCE = 'NetSuite/updateSolutionInstance';
export const NS_PROVISION_SOLUTION_INSTANCE_ERROR = 'NetSuite/provisionSolutionInstanceError';
export const NS_UPDATE_SOLUTION_INSTANCE_SCHEDULE = 'NetSuite/updateSolutionInstanceSchedule';
export const NS_FILTER_FIELD_MAPPING_ROWS = 'NetSuite/filterFieldMappingRows';