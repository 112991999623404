import { Checkbox, FormControl, FormControlLabel, FormLabel, InputAdornment, List, ListItemButton, ListItemIcon, ListItemText, MenuItem, Switch, TextField } from '@mui/material';
import { Button, DatePicker, Grid, Icon, Modal, ModalActions, Spinner, Table, Typography } from '@platform-ui/design-system';
import React, { Dispatch, FC, Fragment, useEffect, useState } from 'react';
import { FieldMappingTable } from "../IntegrationApps/IntegrationAppDetails/components/FieldMapping/FieldMappingTable";
import { IADState } from '../IntegrationApps/IntegrationAppDetails/state';
import { Action, useStoreContext } from '../Store';
import Connect from '../Connect/Connect';
import { useToast } from '../ToastProvider';

interface BasicTemplateContentProps {
  fieldGroups?: Array<any>;
  locale?: string;
  tabName?: string;
}

const BasicTemplateContent: FC<BasicTemplateContentProps> = ({
  fieldGroups = [],
  locale = 'en',
  tabName
}: BasicTemplateContentProps) => {
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const [showPasswords, setShowPasswords] = useState([]);
  const contentDisabled = !state.active && ['authentication', 'settings'].includes(tabName);
  const [open, setOpen] = useState(false);
  const [instanceFields, setInstanceFields] = useState({
    name: '',
    error: false,
    helperText: '',
    showSpinner: false
  });
  const { setToast } = useToast();
  const handleDispatch = (type: string, payload: any) => {
    dispatch({ type, payload });
  };

  return (
    <Grid container>
      {
        fieldGroups.map((fieldGroup: { titleTranslations: any, fields: Array<any>, collapsible: boolean }, index: number) => {
          const title = fieldGroup.titleTranslations?.[locale];
          const collapsible = fieldGroup.collapsible;
          return (
            <Fragment key={`basic-template-content-${index}`}>
              {!collapsible && title && <Grid key={`${tabName}-${title}`} item xs={12} sm={12} md={12} lg={12} xl={12}><Typography children={title} titleBar={true} variant='titleL' /></Grid>}
              {
                fieldGroup.fields?.map((field: any, idx: number) => {
                  const title = field.titleTranslations?.[locale] || 'Title';
                  const required = field.required ? <span style={{ color: 'red' }}>*</span> : '';
                  const placeholder = field.placeholderTranslations?.[locale];
                  const info = field.descriptionTranslations?.[locale] ? <Icon fontSize='small' body='info' tooltip={<Typography body={field.descriptionTranslations?.[locale]} />} /> : '';
                  const label = <div><b>{title}</b>{required}{info}</div>;
                  const type = field.fieldType;
                  const name = field.name;
                  const size = field.direction !== 'column' && type !== 'textarea' && type !== 'field_mapping' ? 6 : 12;
                  const defaultValue = field.defaultValue;

                  const populateDefaults = () => {
                    if (state.settingsHash[tabName]?.[name] == null && defaultValue) {
                      handleDispatch(tabName, {
                        [name]: defaultValue
                      });
                    }
                  }
                  useEffect(() => {
                    populateDefaults();
                  }, []);

                  return (
                    <Grid key={`${tabName}-${name}`} item xs={size} sm={size} md={size} lg={size} xl={size}>
                      {
                        type === 'text' &&
                        <TextField
                          fullWidth
                          placeholder={placeholder}
                          label={label}
                          onChange={(event) => {
                            handleDispatch(tabName, {
                              [name]: event.target.value
                            });
                          }}
                          value={state.settingsHash[tabName]?.[name] || state[name] || ''}
                          type={type}
                          disabled={contentDisabled || !!field.readonly}
                        />
                      }
                      {
                        type === 'password' &&
                        <TextField
                          fullWidth
                          placeholder={placeholder}
                          label={label}
                          InputProps={{
                            endAdornment:
                              <InputAdornment position='end'>
                                <Button
                                  icon={showPasswords[idx] ? 'visibility_off' : 'visibility'}
                                  dsOnClick={() => {
                                    const newShowPasswords = [...showPasswords];
                                    newShowPasswords[idx] = !newShowPasswords[idx];
                                    setShowPasswords(newShowPasswords);
                                  }}
                                  disabled={contentDisabled}
                                />
                              </InputAdornment>
                          }}
                          onChange={(event) => {
                            handleDispatch(tabName, {
                              [name]: event.target.value
                            });
                          }}
                          value={state.settingsHash[tabName]?.[name] || ''}
                          type={showPasswords[idx] ? 'text' : 'password'}
                          disabled={contentDisabled}
                        />
                      }
                      {
                        type === 'select' &&
                        <TextField
                          fullWidth
                          select
                          label={label}
                          value={state.settingsHash[tabName]?.[name]?.['value'] || ''}
                          disabled={contentDisabled}
                        >
                          {field.optionTranslations?.[locale]?.map((option: { key: string; value: string }) => (
                            <MenuItem
                              key={option.key}
                              value={option.value}
                              onClick={(_) => {
                                handleDispatch(tabName, {
                                  [name]: option
                                });
                              }}
                            >
                              {option.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      }
                      {
                        type === 'checkbox' &&
                        <FormControlLabel
                          style={{ pointerEvents: 'none' }}
                          label={<div>{title}{required}</div>}
                          control={
                            <Checkbox
                              size='medium'
                              checked={
                                state.settingsHash[tabName]?.[name] !== undefined
                                  ? state.settingsHash[tabName][name]
                                  : false
                              }
                              onChange={(_, checked) => {
                                handleDispatch(tabName, {
                                  [name]: checked
                                });
                              }}
                              style={{ pointerEvents: 'auto' }}
                            />
                          }
                          disabled={contentDisabled}
                        />
                      }
                      {
                        type === 'textarea' &&
                        <TextField
                          fullWidth
                          placeholder={placeholder}
                          label={label}
                          onChange={(event) => {
                            handleDispatch(tabName, {
                              [name]: event.target.value
                            });
                          }}
                          value={state.settingsHash[tabName]?.[name] || ''}
                          type={type}
                          InputProps={{
                            sx: {
                              '& textarea': {
                                resize: 'both'
                              },
                              '&&': {
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                height: 'auto',
                              }
                            }
                          }}
                          multiline={true}
                          disabled={contentDisabled}
                        />
                      }
                      {
                        type == 'checkboxlist' &&
                        <List
                          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                        >
                          {field.optionTranslations?.[locale]?.map((option: {key: string; value: string}) => (
                            <ListItemButton>
                              <ListItemIcon>
                                      <Checkbox
                                        size='medium'
                                        checked={
                                          state.settingsHash[tabName][option.key]
                                        }
                                        onChange={(_, checked) => {
                                          const updatedSettings = {
                                            ...(state.settingsHash[tabName] || {}),
                                            [option.key]: checked
                                          };
                                          handleDispatch(tabName, updatedSettings);
                                        }}
                                      />
                              </ListItemIcon>
                              <ListItemText primary={option.value} />
                            </ListItemButton>
                          ))}
                        </List>
                      }
                      {
                        type == 'modal' && 
                        <Fragment>
                          <Button dsOnClick={() =>{
                            setOpen(true)
                          }}>Manual Sync</Button>
                          <Modal
                            id={field.name}
                            open={open}
                            dsOnClose={() =>{
                              setOpen(false)
                            }}
                            body={<BasicTemplateContent fieldGroups={field.modal_fields} locale={locale} tabName={tabName} />}
                            footer={(
                              <>
                                <ModalActions>
                                  <Button variant='outlined' dsOnClick={() => setOpen(false)}>Cancel</Button>
                                  <Button
                                    dsOnClick={() => {
                                      
                                    }}
                                  >
                                    Submit
                                  </Button>
                                </ModalActions>
                                {instanceFields.showSpinner && <Spinner variant='linear' />}
                              </>
                            )}
                          />
                        </Fragment>
                      }
                      {
                        type === 'switch' &&
                        <FormControl component="fieldset" variant="standard" disabled={contentDisabled}>
                          <FormLabel>{label}</FormLabel>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={!!state.settingsHash[tabName]?.[name]}
                                onChange={(_, checked) => {
                                  handleDispatch(tabName, {
                                    [name]: checked
                                  });
                                }}
                                disabled={contentDisabled} />
                            }
                            label=""
                          />
                        </FormControl>
                      }
                      {
                        type === 'datepicker' &&
                        <DatePicker
                          label={<Typography variant='surtitle'>{label}</Typography>}
                          dsOnChange={(val) => {
                            handleDispatch(tabName, {
                              [name]: val
                            });
                          }}
                          value={state.settingsHash[tabName]?.[name] || ''}
                          disabled={contentDisabled}
                        />
                      }
                      {
                        type === 'dateIntervalPicker' &&
                        <Grid container spacing={2}>
                          <Grid item xs={4}>
                            <DatePicker
                              label={<Typography variant='surtitle'>{field.startDateName}</Typography>}
                              dsOnChange={(val) => {
                                handleDispatch(tabName, {
                                  [field["startDateName"]]: val
                                });
                              }}
                              value={state.settingsHash[tabName]?.[field["startDateName"]] || ''}
                              disabled={contentDisabled}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <DatePicker
                              label={<Typography variant='surtitle'>{field.endDateName}</Typography>}
                              dsOnChange={(val) => {
                                handleDispatch(tabName, {
                                  [field["endDateName"]]: val
                                });
                              }}
                              value={state.settingsHash[tabName]?.[field["endDateName"]] || ''}
                              disabled={contentDisabled}
                            />
                          </Grid>
                        </Grid>
                      }
                      {
                        type === 'button' &&
                        <div style={{ paddingTop: '25px' }}>
                          <Button
                            disabled={contentDisabled}
                            body={title}
                            dsOnClick={() => {
                              if (field.onClick) {
                                try {
                                  // Note(Duc): We could expose as many objects/functions/libaries as we want here to the function parameter list
                                  const onClick = new Function('setToast', 'Connect', 'handleDispatch', field.onClick);
                                  onClick(setToast, Connect, handleDispatch);
                                } catch (error) {
                                  Connect.log('Failed to execute onClick: ', error);
                                  alert('Failed to handle button click.');
                                }
                              }
                            }}
                          />
                        </div>
                      }
                      {
                        type === 'readonly_field_mapping' &&
                        <Table
                          uniqueKey='readonly-field-mapping-table'
                          columns={[
                            {
                              field: 'sourceField',
                              label: field['sourceHeader']
                            },
                            {
                              field: 'targetField',
                              label: field['targetHeader']
                            }
                          ]}
                          rows={field['rows'] || []}
                          hidePagination
                        />
                      }
                      {
                        type === 'field_mapping' &&
                        <FieldMappingTable
                          tabName={tabName}
                          name={name}
                          windmillPathSourcePath={field["sourceHeader"]}
                          windmillPathTargetPath={field["targetHeader"]}
                          defaults={field["rows"]}
                          sourceTranslation={field.sourceFieldHeaderTranslations?.[locale] || 'Source System'}
                          targetTranslation={field.targetFieldHeaderTranslations?.[locale] || 'Target System'}
                        />
                      }
                    </Grid>
                  );
                })
              }
            </Fragment>
          );
        })
      }
    </Grid>
  );
};

export default BasicTemplateContent;