export const WD_ADD_FIELD_MAPPING_ROW = 'Workday/addFieldMappingRow';
export const WD_DELETE_FIELD_MAPPING_ROW = 'Workday/deleteFieldMappingRow';
export const WD_ADD_SOURCE_TARGET = 'Workday/addSourceTarget';
export const WD_LOAD_SOLUTION_INSTANCES = 'Workday/loadSolutionInstances';
export const WD_DELETE_SOLUTION_INSTANCE = 'Workday/deleteSolutionInstance';
export const WD_ADD_SOLUTION_INSTANCE = 'Workday/addSolutionInstance';
export const WD_LOAD_EXECUTIONS = 'Workday/loadExecutions';
export const WD_LOAD_FIELD_MAPPING_OPTIONS = 'Workday/loadFieldMappingOptions';
export const WD_EXECUTE_SOLUTION_INSTANCE = 'Workday/executeSolutionInstance';
export const WD_CHANGE_EMAIL_NOTIFICATIONS_ENABLED = 'Workday/changeEmailNotificationsEnabled';
export const WD_CHANGE_EMAIL_NOTIFICATIONS_WARNINGS = 'Workday/changeEmailSettingWarnings';
export const WD_CHANGE_EMAIL_NOTIFICATIONS_SUCCESS = 'Workday/changeEmailSettingSuccess';
export const WD_EMAIL_NOTIFICATIONS_ADD_ADDRESS = 'Workday/emailNotificationsAddAddress';
export const WD_EMAIL_NOTIFICATIONS_UPDATE_ADDRESS = 'Workday/emailNotificationsUpdateAddress';
export const WD_EMAIL_NOTIFICATIONS_DELETE_ADDRESS = 'Workday/emailNotificationsDeleteAddress';
export const WD_EMAIL_NOTIFICATIONS_CANCEL_BUTTON_CLICK = 'Workday/emailNotificationsCancelButtonClick';
export const WD_EMAIL_NOTIFICATIONS_SAVE_BUTTON_CLICK = 'Workday/emailNotificationsSaveButtonClick';
export const WD_SAVE_SOLUTION_INSTANCE_OBJECTS = 'Workday/saveSolutionInstanceObjects';
export const WD_UPDATE_SOLUTION_INSTANCE = 'Workday/updateSolutionInstance';
export const WD_PROVISION_SOLUTION_INSTANCE_ERROR = 'Workday/provisionSolutionInstanceError';
export const WD_UPDATE_SOLUTION_INSTANCE_SCHEDULE = 'Workday/updateSolutionInstanceSchedule';
export const WD_FILTER_FIELD_MAPPING_ROWS = 'Workday/filterFieldMappingRows';