/**
 * Build Workflow global constants for Field Mappings
 *
 * @param {any} fieldMappings - Field mappings between source and target
 */
export const buildGlobalConstantsForFieldMappings = (fieldMappings: any) => {
  let mappings = {};
  const mappingKeys = Object.keys(fieldMappings);
  if (mappingKeys.length > 0) {
    for (let key of mappingKeys) {
      if (key.startsWith('z-')) {
        const rawMappings = fieldMappings[key]?.['mappings'] || {};
        const rawMappingKeys = Object.keys(rawMappings);
        if (rawMappingKeys.length > 0) {
          for (let rmKey of rawMappingKeys) {
            const mapping = rawMappings[rmKey];
            const key = mapping['source']['value'];
            const value = mapping['target']['text'];
            mappings[key] = value;
          }
        }
      }
    }
  }
  return mappings;
}

/**
 * Build Workflow global constants for Email Configurations
 *
 * @param {{enabled: boolean, failures: boolean, success: boolean, emails: string[]}} emailNotifications - Email notification configurations
 */
export const buildGlobalConstantsForEmailConfigs = (emailNotifications: {
  enabled: boolean;
  failures: boolean;
  warnings: boolean;
  success: boolean;
  emails: string[];
}) => {
  let emailConfigs = {};
  if (emailNotifications.enabled) {
    emailConfigs = {
      'Failures': emailNotifications.failures || false,
      'Warnings': emailNotifications.warnings || false,
      'Success': emailNotifications.success || false,
      'Emails': emailNotifications.emails || []
    };
  }
  return emailConfigs;
}