import { useContext } from 'react';
import IdentityContext from '../IdentityContext';

const useIdentity = () => {
  const ctx = useContext(IdentityContext);

  if (ctx === null) {
    throw new Error('IdentityContext is not initialized.');
  }

  return ctx;
};

export default useIdentity;
