import { Spinner } from '@platform-ui/design-system';
import React from 'react';
import Connect from '../../../../Connect/Connect';
import { WorkflowExecution } from '../../../../IntegrationApps/IntegrationAppDetails/components/WorkflowExecution';
import { useFlipperHook } from '../../../../Tables/helpers';
import { useToast } from '../../../../ToastProvider';

export const Execution = () => {
  const [sapFeatureEnabled, sapFeatureLoading] = useFlipperHook('enable_sap_connector');
  const { setToast } = useToast();
  const connect: Connect = (window as any).connect;
  const isLocal = window.location.host.includes('localhost');
  const workflowProxyPath = `${isLocal ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2`;

  const onViewSummaryClickHandler = async (
    args: { row: any },
    utils?: {
      setSummaryModalData: React.Dispatch<React.SetStateAction<any>>,
      setOpenSummaryModal: React.Dispatch<React.SetStateAction<boolean>> 
    }
  ) => {
    const { row } = args;
    try {
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/tasks?workflow_id=${row.id}&name=Get Sync Job Summary`,
        'GET',
        undefined,
        {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Scope': 'Internal'
        }
      );

      if (!response.ok) throw Error(response.statusText);

      const { data } = await response.json();
      
      if (!Array.isArray(data) || data.length === 0) throw Error('No summary was found. Please try again later.');

      const syncJobResult = data[0]?.['data']?.['syncJobResult'];
      let summary = null;

      if (Array.isArray(syncJobResult)) {
        if (syncJobResult.length === 0) throw Error('No summary was found. Please try again later.');
        summary = syncJobResult[syncJobResult.length - 1]?.result;
      } else {
        summary = syncJobResult?.result;
      }

      if (!summary) throw Error('No summary was found. Please try again later.');

      utils.setSummaryModalData(summary);
      utils.setOpenSummaryModal(true);
    } catch (err) {
      Connect.log(`Failed to retrieve SAP Billing summary: ${err}`);
      setToast({
        message: `Failed to retrieve summary: ${err.message}`,
        severity: 'error',
        keyRender: Date.now()
      });
    }
  };

  if (sapFeatureLoading) {
    return <Spinner />;
  }

  return (
    <WorkflowExecution
      tableName='execution'
      workflowTemplateName='z-IntegrationHub-SAP-Billing'
      permissionCheckDisabled={sapFeatureEnabled}
      permissionName='permission.ZuoraSAPConnector'
      taskRerunEnabled={false}
      onViewSummaryClickHandler={onViewSummaryClickHandler}
    />
  );
};
