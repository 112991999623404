import { AlertColor, Autocomplete, FormControlLabel, Switch, TextField, styled } from '@mui/material';
import { Alert, Card, Table, ToastController } from '@platform-ui/design-system';
import React, { Dispatch, FC, useEffect, useState } from 'react';
import { IADState } from '../../../IntegrationApps/IntegrationAppDetails/state';
import { Action, useStoreContext } from '../../../Store';
import { WDB_ADD_FIELD_MAPPING_ROW, WDB_ADD_SOURCE_TARGET, WDB_DELETE_FIELD_MAPPING_ROW, WDB_FILTER_FIELD_MAPPING_ROWS, WDB_LOAD_FIELD_MAPPING_OPTIONS, WDB_TOGGLE_EDIT_FIELD_MAPPINGS, WDB_TOGGLE_REPORT_TYPE } from './action_types';
import Connect from '../../../Connect/Connect';
import { getZuoraWorkdayMappings } from './utils';
import { useIdentity } from '../../../IdentityProvider';
import { useFlipperHook } from '../../../Tables/helpers';

interface FieldMappingProps {
  tabName: string;
}

const transform = (instanceName: string) => instanceName.trim().replace(/\s+/g, '_');

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-endAdornment": {
    top: "auto !important"
  },
  "& .MuiAutocomplete-input": {
    paddingTop: "0px !important",
    paddingBottom: "0px !important"
  }
});

export const FieldMapping: FC<FieldMappingProps> = ({
  tabName
}: FieldMappingProps) => {
  let searchTimer = null;
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const [loading, setLoading] = useState(false);
  const [instanceName, setInstanceName] = useState('');
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success' as AlertColor
  });
  const workflowName = state.workflow_template_name || 'z-IntegrationHub-WorkdayBilling';
  const [anchorEl, setAnchorEl] = useState(null);
  const { hasPermission} = useIdentity();
  const isARTransactionEnabled = hasPermission('permission.EnableARTransaction');
  const isConnectorEnabled = hasPermission('permission.ZuoraWorkdayConnector') || state.enableWorkdayConnector;
  const reportType = state.settingsHash[tabName]?.reportType || 'summary';
  const isFieldMappingsEditable = !!state.settingsHash['fieldMapping']?.editable;

  const handleSearch = (searchValue) => {
    /**
     * Simple debounce to avoid re renders
     * Increase the timeout if there is performance impact for large data sets
     */
    if(searchTimer) {
      clearTimeout(searchTimer);
    }
    searchTimer = setTimeout(() => {
      dispatch({
        type: WDB_FILTER_FIELD_MAPPING_ROWS,
        payload: {
          reportType,
          instanceName: transform(instanceName),
          filterValue: searchValue
        }
      });
    }, 500);
  };

  const handleToggleEditMappings = (checked: boolean) => {
    dispatch({
      type: WDB_TOGGLE_EDIT_FIELD_MAPPINGS,
      payload: checked
    });
  }

  const handleToggleReportType = (checked: boolean) => {
    dispatch({
      type: WDB_TOGGLE_REPORT_TYPE,
      payload: checked
    });
  }

  const addFieldMapping = () => {
    const name = transform(instanceName);
    const rows = Object.values(state.settingsHash[tabName]?.[name]?.[reportType]?.mappingRows || {});
    const lastRow = rows.at(-1);
    const rowId = lastRow ? lastRow['rowId'] - -1 : 0; // this is the same as lastRow['rowId'] + 1 whether or not lastRow['rowId'] is a numeric string or an integer

    dispatch({
      type: WDB_ADD_FIELD_MAPPING_ROW,
      payload: {
        instanceName: name,
        reportType,
        rowId,
        [name]: {
          rowId,
          key: rowId,
          [`workday_target_fields`]: JSON.stringify(state.settingsHash['fieldMapping']?.['mappingOptions']?.workdayFields || []),
          '': '→',
          [`zuora_source_fields`]: JSON.stringify(state.settingsHash['fieldMapping']?.['mappingOptions']?.zuoraFields || [])
        }
      }
    });
  };

  const deleteFieldMapping = (event) => {
    dispatch({
      type: WDB_DELETE_FIELD_MAPPING_ROW,
      payload: {
        reportType,
        instanceName: transform(instanceName),
        rowId: (event as any).row.rowId
      }
    });
  };

  const downloadFieldMappings = () => {
    const name = transform(instanceName);
    const fileName = `${name}_field_mappings.json`;
    
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([JSON.stringify(state.settingsHash[tabName][name]?.[reportType]?.mappings || {}, null, 2)], { type: 'application/json' }));
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(a.href);
    a.remove();
  };

  const getFieldMappings = async (refresh?: boolean) => {
    setLoading(true);
    setToast({...toast, show: false});
    
    try {
      // load workday and zuora journal mappings
      const { workdayFields, zuoraFields } = await getZuoraWorkdayMappings({...state.settingsHash?.['authentication'], WDB_ZR_REST_ENDPOINT: state.envRestEndpoint}, reportType);
      const DEFAULT_MAPPINGS = state.default_mappings[reportType] || state.default_mappings['summary'];

      if (instanceName) {
        dispatch({
          type: WDB_LOAD_FIELD_MAPPING_OPTIONS,
          payload: { workdayFields, zuoraFields, DEFAULT_MAPPINGS, name: transform(instanceName), reportType }
        });
      }

      if (refresh) {
        setToast({
          show: true,
          msg: `Successfully loaded Workday and Zuora mappings.`,
          severity: 'success'
        });
      }
    } catch (err) {
      Connect.log(err);
      setToast({
        show: true,
        msg: `Failed to load Workday/Zuora mappings. Please check Workday/Zuora credentials.`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const zuoraFields: any = {
    field: `zuora_source_fields`,
    label: `Zuora Source Fields`,
    dsRenderCell: ({ row }) => {
      const name = transform(instanceName);
      const rowId = row.rowId;
      const items = JSON.parse(row[`zuora_source_fields`]) || [];
      const inputPlaceHolder = 'Select source field';
      const selectedValueText = state.settingsHash[tabName]?.[name]?.[reportType]?.mappings[rowId]?.['source']?.['text'] || '';

      const selectedValueObj = items.find(item => item.text === selectedValueText);
      const [selectedValue, setSelectedValue] = useState(selectedValueObj || null);
      const [inputValue, setInputValue] = useState('');

      useEffect(() => {
        if (selectedValueObj && JSON.stringify(selectedValueObj) !== JSON.stringify(selectedValue)) {
          setSelectedValue(() => selectedValueObj);
        }
      }, [selectedValueObj]);

      return (
        <StyledAutocomplete
          disableClearable
          getOptionLabel={(option: any) => option.text || ''}
          isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
          value={selectedValue}
          onChange={(event: any, newValue: any) => {
            dispatch({
              type: WDB_ADD_SOURCE_TARGET,
              payload: {
                reportType,
                instanceName: transform(instanceName),
                rowId,
                source: newValue
              }
            });
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id={`zuora-fields-selection-${rowId}`}
          options={items}
          fullWidth
          renderInput={(params) => <TextField {...params} placeholder={inputPlaceHolder} />}
        />
      );
    }
  };


  const workdayFields: any = {
    field: `workday_target_fields`,
    label: `Workday Target Fields`,
    dsRenderCell: ({ row }) => {
      const rowId = row.rowId;
      const items = JSON.parse(row[`workday_target_fields`]) || [];
      const inputPlaceHolder = 'Select target field';
      const name = transform(instanceName);
      const selectedValueText = state.settingsHash[tabName]?.[name]?.[reportType]?.mappings[rowId]?.['target']?.['text'] || ''
      const selectedValueObj = items.find(item => item.text === selectedValueText);
      const [selectedValue, setSelectedValue] = useState(selectedValueObj || null);
      const [inputValue, setInputValue] = useState('');

      useEffect(() => {
        if (selectedValueObj && JSON.stringify(selectedValueObj) !== JSON.stringify(selectedValue)) {
          setSelectedValue(() => selectedValueObj);
        }
      }, [selectedValueObj]);
      return (

        <StyledAutocomplete
          disableClearable
          getOptionLabel={(option: any) => option.text || ''}
          isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
          value={selectedValue}
          onChange={(event: any, newValue: any) => {
            dispatch({
              type: WDB_ADD_SOURCE_TARGET,
              payload: {
                reportType,
                instanceName: transform(instanceName),
                rowId,
                target: newValue
              }
            });
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id={`workday-field-selection-${rowId}`}
          options={items}
          fullWidth
          renderInput={(params) => <TextField {...params} placeholder={inputPlaceHolder} />}
        />
      );
    }
  };

  useEffect(() => {
    if (isConnectorEnabled) {
      if (instanceName && state.saved && state.reloadFieldMappings) {
        getFieldMappings();
      } else if (
        instanceName &&
        !state.saved &&
        !state.reloadFieldMappings &&
        !state.settingsHash['fieldMapping']?.[transform(instanceName)] &&
        state.settingsHash['authentication']?.['WDB_ZR_CLIENT_ID'] &&
        state.settingsHash['authentication']?.['WDB_ZR_CLIENT_SECRET']
      ) {
        getFieldMappings();
      } else if (instanceName && (state.settingsHash[tabName]?.mappingOptions == null || Object.keys(state.settingsHash[tabName]?.mappingOptions || {}).length === 0)) {
        getFieldMappings();
      }
    }
  }, [instanceName, state.saved, state.settingsHash[tabName]?.reportType]);

  useEffect(() => {
    if (isConnectorEnabled) {
      const defaultInstance = state.settingsHash['solutionInstances']?.find(s => s.solution_instance_name === workflowName);
      if (defaultInstance) {
        setInstanceName(defaultInstance.solution_instance_name || '');
      }
    }
  }, [state.settingsHash['solutionInstances']]);

  try {
    if (!isConnectorEnabled) {
      return (
        <Alert open center variant='outlined' severity='warning' body='Missing required permission ZuoraWorkdayConnector' />
      );
    }

    if (!state.active) {
      return (
        <Alert center variant='outlined' severity='warning' body='You must enable integration in Authentication tab first' open={true} />
      );
    }

    if (!state.saved && !state.settingsHash['authentication']) {
      return (
        <Alert center variant='outlined' severity='warning' body='You must configure Authentication and save first' open={true} />
      );
    }

    if (state.provisionSolutionInstanceError) {
      return (
        <Alert center variant='outlined' severity='error' body='Failed to provision your instance. Please contact admin.' open={true} />
      );
    }

    if (!state.settingsHash['solutionInstances'] || !state.settingsHash['solutionInstances']?.find(s => s.solution_instance_name === workflowName)) {
      return (
        <Alert center variant='outlined' severity='warning' body='Please wait while we are provisioning your instance' open={true} />
      );
    }
  } catch (err) {
    Connect.log(err);
    return (
      <Alert center variant='outlined' severity='error' body='An unexpected error has occurred. Please contact admin for technical support.' open={true} />
    );
  }

  return (
    <>
      {toast.show && <ToastController severity={toast.severity as any} message={toast.msg} />}
      <Card
        id='workday-field-mapping-bottom-ui'
        titleBar
        header='Map Source Field with the Target Field'
        headerAction={
          <>
            <FormControlLabel
              style={{pointerEvents: 'none'}}
              label='Edit'
              control={
                <Switch
                  size='small'
                  checked={isFieldMappingsEditable}
                  onChange={(_, checked) => handleToggleEditMappings(checked)}
                  style={{pointerEvents: 'auto'}}
                />
              }
            />
            <FormControlLabel
              style={{pointerEvents: 'none'}}
              label={'Detailed Journals'}
              disabled={!isARTransactionEnabled}
              control={
                <Switch
                  size='small'
                  checked={reportType === 'detail'}
                  onChange={(_, checked) => handleToggleReportType(checked)}
                  style={{pointerEvents: 'auto'}}
                />
              }
            />
          </>
        }
        body={
          <div style={{ marginTop: '0px', pointerEvents: isFieldMappingsEditable ? 'auto' : 'none', opacity: isFieldMappingsEditable ? '1' : '0.4' }}>
            <Table
              loading={loading}
              columns={
                [
                  zuoraFields,
                  {
                    field: '',
                    label: '',
                    alignment: 'center' as any,
                  },
                  workdayFields
                ]
              }
              rows={Object.values(state.settingsHash['filteredFieldMapping']?.[transform(instanceName)]?.[reportType]?.mappingRows || {})}
              uniqueKey='workday-map-field-table'
              sortable={false}
              tableActions={
                [
                  {
                    icon: 'add',
                    tooltip: 'Add a Field Mapping',
                    disabled: (
                      (instanceName === '' || state.settingsHash[tabName]?.mappingOptions == null || Object.keys(state.settingsHash[tabName]?.mappingOptions || {}).length === 0)
                    ),
                    onClick: () => addFieldMapping()
                  },
                  {
                    icon: 'refresh',
                    tooltip: 'Refresh Field Mapping Sources',
                    onClick: () => getFieldMappings(true)
                  },
                  {
                    icon: 'download',
                    tooltip: 'Download Field Mappings',
                    disabled: Object.keys(state.settingsHash[tabName]?.[transform(instanceName)]?.[reportType]?.mappings || {}).length < 1,
                    onClick: () => downloadFieldMappings()
                  }
                ]
              }
              rowActions={
                [
                  {
                    icon: 'delete',
                    tooltip: 'Delete a Field Map',
                    onClick: (event) => deleteFieldMapping(event)
                  }
                ]
              }
              hidePagination={true}
              rowDisplayOptions={{
                hoverEffect: false
              }}
              searchable
              searchPlaceholder='Type field name to filter results...'
              dsOnSearchChange={(value) => handleSearch(value)}
            />
          </div>
        }
      />
    </>
  );
};