import { Draft } from 'immer';
import { ZDQ_DESTINATION_DATA, ZDQ_RECIPIENT } from './action_types';
import { IADState } from '../../../IntegrationApps/IntegrationAppDetails/state';

export const actionMap = {
    [ZDQ_DESTINATION_DATA]: (draft: Draft<IADState>, payload: any) => {
        draft.settingsHash[ZDQ_DESTINATION_DATA] = { ...draft.settingsHash[ZDQ_DESTINATION_DATA], ...payload };
    },
    [ZDQ_RECIPIENT]: (draft: Draft<IADState>, payload: any) => {
        draft.settingsHash[ZDQ_RECIPIENT] = { ...draft.settingsHash[ZDQ_RECIPIENT], ...payload };
    }
};