export const getUserIdentifier = (props: {
  userId?: string;
  currEntityId?: string;
}) => {
  return Object.entries(props)
    .reduce((tagArray: string[], [key, value]) => {
      if (value) {
        return tagArray.concat([`${key}=${value}`]);
      }
      return tagArray;
    }, [])
    .join('&');
};
