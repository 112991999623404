import React, { FunctionComponent } from 'react';
import useLocalStorageIdentity from './hooks/useLocalStorageIdentity';
import useZuoraCookies from './hooks/useZuoraCookies';
import IdentityContext from './IdentityContext';
import Identity from './types/Identity';
import useUserPreference from './hooks/useUserPreference';
import IdentityProviderProps from './types/IdentityProviderProps';
import { getUserIdentifier } from './utils/getUserIdentifier';
import { IdentityPermissions } from './constants/permissions';

const IdentityProvider: FunctionComponent<
  {
    children?: React.ReactNode;
  } & IdentityProviderProps
> = ({ children, overrides }) => {
  const cookies = useZuoraCookies();
  const [identity] = useLocalStorageIdentity(
    getUserIdentifier({
      userId: cookies.userId,
      currEntityId: cookies.entityId,
    }),
  );
  // don't use user's density setting for now, always use condensed
  const { darkMode } = useUserPreference({ userId: cookies.userId });
  const locale =
    overrides?.locale ?? identity?.locale ?? cookies.locale ?? 'en_US';
  const zdsLocale = locale.replace('_', '-') as Identity['zdsLocale'];

  const identityPermissions = new Set(identity?.permissions);

  return (
    <IdentityContext.Provider
      value={{
        identity: identity ?? undefined,
        language:
          overrides?.language ?? identity?.language ?? cookies.language ?? 'en',
        locale,
        zdsLocale,
        timezone:
          overrides?.timezone ??
          identity?.timeZone ??
          cookies.timezone ??
          identity?.tenantTimeZone ??
          'UTC',
        getUserIdentifier: () =>
          getUserIdentifier({
            userId: cookies.userId,
            currEntityId: cookies.entityId,
          }),
        theme: overrides?.theme ?? darkMode ? 'dark' : 'light',
        density: overrides?.density ?? 'condensed',
        workEmail: identity?.workEmail ?? '',
        firstName: identity?.firstName ?? '',
        lastName: identity?.lastName ?? '',
        hasPermission: (...permissions: IdentityPermissions[]) =>
          permissions.every((permission) =>
            identityPermissions.has(permission),
          ),
      }}
    >
      {children}
    </IdentityContext.Provider>
  );
};

export default IdentityProvider;
