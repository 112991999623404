import { Button, Chip, Grid, Typography } from '@platform-ui/design-system';
import React, { FC } from 'react';
import { ButtonGroup } from './ButtonGroup';
import { useStoreState } from '../../../Store';
import { IADState } from '../state';
import Connect from '../../../Connect/Connect';

interface TitleProps {
  icon: any;
  title: string;
  description?: string;
  showBackButton?: boolean;
  showButtonGroup: boolean;
}

export const Title: FC<TitleProps> = ({
  icon,
  title,
  description,
  showBackButton = false,
  showButtonGroup
}: TitleProps) => {
  const state = useStoreState() as IADState;
  const connect: Connect = (window as any).connect;
  const connectId = (connect.tenant as any).connect_id;
  const origin = Connect.path();
  const path = window.location.pathname.includes('admin') ? 
  `/admin/integration_apps/${connectId}/integration_apps` :
  `/integration_apps/${connectId}/integration_apps`;
  const redirectUrl = origin + path;
  
  const handleBackButtonClick = () => {
    window.location.href = redirectUrl;
  };

  const imgStyle: React.CSSProperties = {
    objectFit: 'contain'
  }

  return (
    <Grid item container direction='row' alignItems='center'>
      {
        showBackButton && <Grid item><Button icon='arrow_back' dsOnClick={handleBackButtonClick} /></Grid>
      }
      <Grid item><img height='56px' width='56px' src={icon} style={imgStyle}></img></Grid>
      <Grid item>
        <Grid item container direction='column' spacing={0}>
          <Grid item>
            <Typography variant='h5'>{title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant='surtitle'>{description}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {
        showButtonGroup && <ButtonGroup showUnsavedChangesChip={state.modified} />
      }
    </Grid>
  );
};