import React, { Dispatch, useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Action, useStoreContext } from '../../../../Store';
import { Field } from './types';
import { IADState } from '../../state';


interface FieldMappingValue {
  fieldOptions: Array<Field>;
  row: any;
  mappingTableTab: string;
  mappingTableName: string;
  system: string;
  placeholder?: string;
}

export const MapOptions: React.FC<FieldMappingValue> = ({
  fieldOptions,
  row,
  mappingTableTab,
  mappingTableName,
  system,
  placeholder
}) => {

  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };

  const updateMapping = (rowId :number, value: Field) => {
    const currentMapping = state.settingsHash[mappingTableTab][mappingTableName];
    const rowIndex = currentMapping.findIndex(row => row.rowId === rowId);
    
    if (rowIndex === -1) return; // Row not found
    
    // Create new array with updated item
    const updatedMapping = [...currentMapping];
    updatedMapping[rowIndex] = { 
        ...updatedMapping[rowIndex], 
        [system]: value 
    };

    dispatch({
      type: mappingTableTab,
      payload: {
        [mappingTableName]: updatedMapping
      }
    });
  }

  return (
      <Autocomplete
        disableClearable
        freeSolo  // This allows manual input
        isOptionEqualToValue={(option: Field, value: Field) => option?.value === value?.value}
        onChange={(event: any, newValue: any) => {
            // Handle selection from dropdown
            updateMapping(row.rowId, newValue);
        }}
        onInputChange={(event, newValue) => {
            // Handle manual input
            if (event?.type === 'change') {
                updateMapping(row.rowId, { value: newValue, label: newValue, type: 'string' });
            }
        }}
        value={row[system]}
        options={fieldOptions}
        fullWidth
        renderInput={(params) => (
            <TextField 
                {...params} 
                placeholder={placeholder || `Select a field`} 
            />
        )}
    />
  );
}