import { Modal, ModalActions, ModalTitle } from '@platform-ui/design-system';
import React, { Dispatch, FC, ReactNode, SetStateAction } from 'react';

interface ExecutionModalProps {
  children: ReactNode;
  submitButton: ReactNode;
  open: boolean;
  setOpenExecutionModal: Dispatch<SetStateAction<boolean>>;
  newLayout?: boolean;
}

export const ExecutionModal: FC<ExecutionModalProps> = ({
  children,
  submitButton,
  open,
  setOpenExecutionModal,
  newLayout = false
}: ExecutionModalProps) => {
  if (newLayout) {
    return (
      <Modal
        id='execution-modal'
        open={open}
        dsOnClose={() => setOpenExecutionModal(false)}
        body={children}
        header={
          <ModalTitle dsOnClose={() => setOpenExecutionModal(false)}>Execution/Schedule Configuration</ModalTitle>
        }
        footer={
          <ModalActions>{submitButton}</ModalActions>
        }
        disableBackdropClick
        fullWidth
      />
    );
  } else {
    return <>{children}</>
  }
};