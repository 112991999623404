import { Draft } from 'immer';
import { ZDP_AUTH_SETUP, ZDP_SYNC_SETTINGS } from './action_types';
import { IADState } from '../../../IntegrationApps/IntegrationAppDetails/state';

export const actionMap = {
  [ZDP_SYNC_SETTINGS]: (draft: Draft<IADState>, payload: any) => {
    const merge = payload['merge'];
    if (merge) {
      delete payload['merge'];
    }
    draft.settingsHash[ZDP_SYNC_SETTINGS] = {...draft.settingsHash[ZDP_SYNC_SETTINGS], ...payload};
    draft.modified = merge ? false : true;
  },
  [ZDP_AUTH_SETUP]: (draft: Draft<IADState>, payload: any) => {
    draft.credsModified = payload.authSet;
  }
};