import { Accordion, Card, Grid, Typography } from '@platform-ui/design-system';
import React from 'react';
import BasicTemplate from '../../../../BasicTemplate/BasicTemplate';
import BasicTemplateContent from '../../../../BasicTemplate/BasicTemplateContent';
import Divider from '@mui/material/Divider';
import { HeaderTitle } from './HeaderTitle';
import { HeaderAction } from './HeaderAction';
import './Authentication.css';

export const Authentication = ({
  tabName = 'authentication',
  locale,
  fieldGroups,
  lastUpdatedBy,
  lastUpdatedDate
}) => {
  const firstFieldGroup = fieldGroups?.slice(0, 1) || [];
  const otherFieldGroups = fieldGroups?.slice(1) || [];

  const title = firstFieldGroup[0]?.titleTranslations?.[locale];
  const collapsible = firstFieldGroup[0]?.collapsible;
  const hidden = firstFieldGroup[0]?.hidden;
  const cardContent = <BasicTemplateContent fieldGroups={firstFieldGroup} locale={locale} tabName={tabName} />;
  const subheader = 'Last Updated By: ' + `${lastUpdatedBy ? lastUpdatedBy : 'N/A'}` + ' | Last Updated Date: ' + `${lastUpdatedDate ? lastUpdatedDate : 'N/A'}`;
  const cardBody = hidden ? null : collapsible
    ? <Accordion titleBar header={<Typography children={title} variant='h6' />} body={cardContent} elevation={0} variant='fullWidth' />
    : cardContent;
  
  return (
    <>
      <Card
        id={`${tabName}-id`}
        e2e={`${tabName}-template`}
        header={
          <>
            <Grid container alignItems='center'>
              <HeaderTitle
                header='Status'
                subheader={subheader}
                showTestConnection={true}
                tabName={tabName}
              />
              <Divider style={{marginTop: '15px'}} orientation='vertical' flexItem />
              <HeaderAction />
            </Grid>
            <Divider style={{marginTop: '20px'}} />
          </>
        }
        body={cardBody}
      />
      {
        otherFieldGroups.map((fieldGroup, idx) => (
          <div style={{ marginTop: '10px' }}>
            <BasicTemplate
              id={`basic-template-field-group-${idx}`}
              showHeader={false}
              fieldGroups={[fieldGroup]}
              locale={locale}
              tabName={tabName}
            /> 
          </div>
        ))
      }
    </>
  );
};