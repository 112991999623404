import { FormControlLabel, Switch } from '@mui/material';
import { Button, Grid } from '@platform-ui/design-system';
import React, { Dispatch, FC, useState } from 'react';
import { IADState } from '../IntegrationApps/IntegrationAppDetails/state';
import { Action, useStoreContext } from '../Store';
import { IADActionTypes } from '../IntegrationApps/IntegrationAppDetails';
import { useFlipperHook } from '../Tables/helpers';

interface BasicTemplateHeaderActionProps {
  tabName?: string;
  showRefreshButton?: boolean;
}

const BasicTemplateHeaderAction: FC<BasicTemplateHeaderActionProps> = ({
  tabName,
  showRefreshButton
}: BasicTemplateHeaderActionProps) => {
  const {state, dispatch} = useStoreContext() as {state: IADState, dispatch: Dispatch<Action>};
  const [integrationButtonEnabled, _] = useFlipperHook('ih_enable_integration_button');
  const handleRefreshClick = () => {
    dispatch({
      type: IADActionTypes.REFRESH_CREDENTIALS,
      payload: {
        tabName
      }
    });
  };
  const handleEnableIntegration = (checked: boolean) => {
    dispatch({
      type: IADActionTypes.ENABLE_INTEGRATION,
      payload: checked
    });
  };

  return (
    <Grid container item xs='auto' alignContent='flex-start' alignItems='center' justify='flex-end'>
      <Grid item>
        <FormControlLabel
          style={{pointerEvents: 'none'}}
          label='Enable Integration'
          control={
            <Switch
              size='small'
              checked={!!state.active}
              onChange={(_, checked) => handleEnableIntegration(checked)}
              style={{pointerEvents: 'auto'}}
            />
          }
          disabled={state.active && !integrationButtonEnabled}
        />
      </Grid>
      {
        showRefreshButton && <Grid item><Button disabled={!state.modified} dsOnClick={handleRefreshClick} icon='refresh' tooltip={state.modified ? 'Refresh' : 'Refresh Disabled'} /></Grid>
      }
    </Grid>
  );
};

export default BasicTemplateHeaderAction;