export const SAP_CHANGE_EMAIL_NOTIFICATIONS_ENABLED = 'SAP/changeEmailNotificationsEnabled';
export const SAP_CHANGE_EMAIL_NOTIFICATIONS_FAILURES = 'SAP/changeEmailSettingFailures';
export const SAP_CHANGE_EMAIL_NOTIFICATIONS_WARNINGS = 'SAP/changeEmailSettingWarnings';
export const SAP_CHANGE_EMAIL_NOTIFICATIONS_SUCCESS = 'SAP/changeEmailSettingSuccess';
export const SAP_EMAIL_NOTIFICATIONS_ADD_ADDRESS = 'SAP/emailNotificationsAddAddress';
export const SAP_EMAIL_NOTIFICATIONS_UPDATE_ADDRESS = 'SAP/emailNotificationsUpdateAddress';
export const SAP_EMAIL_NOTIFICATIONS_DELETE_ADDRESS = 'SAP/emailNotificationsDeleteAddress';
export const SAP_EMAIL_NOTIFICATIONS_CANCEL_BUTTON_CLICK = 'SAP/emailNotificationsCancelButtonClick';
export const SAP_EMAIL_NOTIFICATIONS_SAVE_BUTTON_CLICK = 'SAP/emailNotificationsSaveButtonClick';
export const SAP_ADD_SOLUTION_INSTANCE = 'SAP/addSolutionInstance';
export const SAP_LOAD_SOLUTION_INSTANCES = 'SAP/loadSolutionInstances';
export const SAP_PROVISION_SOLUTION_INSTANCE_ERROR = 'SAP/provisionSolutionInstanceError';