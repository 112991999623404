import { ToastControllerProps } from '@platform-ui/design-system';
import { createContext } from 'react';

const ToastContext = createContext<{
  setToast: (props: ToastControllerProps) => void;
  closeToast: () => void;
}>({
  setToast: () => {
    throw new Error('ToastProvider not initialized');
  },
  closeToast: () => {
    throw new Error('ToastProvider not initialized');
  },
});

export default ToastContext;