import Connect from '../../../Connect/Connect';
import { windmillRun } from '../../../Connect/Connect';


export const getRevenueMappings = async (
  auth: {
    OB_ZR_URL: string;
    OB_ZR_USERNAME: string;
    OB_ZR_PASSWORD: string;
    OB_ZR_USERROLE: string;
    OB_ZR_CLIENTNAME: string;
  }
) => {
  let result = [];
  try {
    result = await windmillRun({
      'script_path': 'f/revenue_workday/validation_and_mappings_flow',
      'params': {
        'action': 'get_zr_mappings',
        'auth': auth
      },
      'async': false
    });
  } catch (error) {
    Connect.log(error);
  }
  return result;
};

export const validateRevenueWorkday = async (
  auth: {
    OB_WD_SERVICE_URL: string;
    OB_WD_USERNAME: string;
    OB_WD_PASSWORD: string;
    OB_ZR_URL: string;
    OB_ZR_USERNAME: string;
    OB_ZR_PASSWORD: string;
    OB_ZR_USERROLE: string;
    OB_ZR_CLIENTNAME: string;
  }
) => {
  const result = { isRevenueValid: false, isWorkdayValid: false };
  try {
    const response = await windmillRun({
      'script_path': 'f/revenue_workday/validation_and_mappings_flow',
      'params': {
        'action': 'validate_zr_wd',
        'auth': auth
      },
      'async': false
    });
    result.isRevenueValid = response['isRevenueValid'];
    result.isWorkdayValid = response['isWorkdayValid'];
  } catch (error) {
    Connect.log(error);
  }
  return result;
}

export const getRevenueWorkdayMappings = async (
  auth: {
    OB_ZR_URL: string;
    OB_ZR_USERNAME: string;
    OB_ZR_PASSWORD: string;
    OB_ZR_USERROLE: string;
    OB_ZR_CLIENTNAME: string;
  }
) => {
  let revenueMappings = [];
  let workdayMappings = [];
  try {
    const response = await windmillRun({
      'script_path': 'f/revenue_workday/validation_and_mappings_flow',
      'params': {
        'action': 'get_zr_wd_mappings',
        'auth': auth
      },
      'async': false
    });
    revenueMappings = response['revenueMappings'];
    workdayMappings = response['workdayMappings']
  } catch (error) {
    Connect.log(error);
  }
  return { revenueMappings, workdayMappings };
};

export const getWorkdayMappings = () => {
  return [
    {
      text: 'Header_Currency_Rate_Type_Reference',
      value: 'Header_Currency_Rate_Type_Reference'
    },
    {
      text: 'Header_Control_Total_Amount',
      value: 'Header_Control_Total_Amount'
    },
    {
      text: 'Header_Reversal_Date',
      value: 'Header_Reversal_Date'
    },
    {
      text: 'Header_Create_Reversal',
      value: 'Header_Create_Reversal'
    },
    {
      text: 'Header_Include_Tax_Lines',
      value: 'Header_Include_Tax_Lines'
    },
    {
      text: 'Header_Adjustment_Journal',
      value: 'Header_Adjustment_Journal'
    },
    {
      text: 'Header_Display_Account_Set_Reference',
      value: 'Header_Display_Account_Set_Reference'
    },
    {
      text: 'Header_Document_Link',
      value: 'Header_Document_Link'
    },
    {
      text: 'Header_External_Reference_ID',
      value: 'Header_External_Reference_ID'
    },
    {
      text: 'Header_Journal_Entry_Memo',
      value: 'Header_Journal_Entry_Memo'
    },
    {
      text: 'Header_Record_Quantity',
      value: 'Header_Record_Quantity'
    },
    {
      text: 'Header_Optional_Balancing_Worktags_Reference',
      value: 'Header_Optional_Balancing_Worktags_Reference'
    },
    {
      text: 'Header_Balancing_Worktag_Reference',
      value: 'Header_Balancing_Worktag_Reference'
    },
    {
      text: 'Header_Journal_Source_Reference',
      value: 'Header_Journal_Source_Reference'
    },
    {
      text: 'Header_Accounting_Date',
      value: 'Header_Accounting_Date'
    },
    {
      text: 'Header_Book_Code_Reference',
      value: 'Header_Book_Code_Reference'
    },
    {
      text: 'Header_Ledger_Type_Reference',
      value: 'Header_Ledger_Type_Reference'
    },
    {
      text: 'Header_Currency_Reference',
      value: 'Header_Currency_Reference'
    },
    {
      text: 'Header_Company_Reference',
      value: 'Header_Company_Reference'
    },
    {
      text: 'Header_Journal_Number',
      value: 'Header_Journal_Number'
    },
    {
      text: 'Header_Round_Ledger_Amounts',
      value: 'Header_Round_Ledger_Amounts'
    },
    {
      text: 'Header_Disable_Optional_Worktag_Balancing',
      value: 'Header_Disable_Optional_Worktag_Balancing'
    },
    {
      text: 'Header_Submit',
      value: 'Header_Submit'
    },
    {
      text: 'Header_Accounting_Journal_ID',
      value: 'Header_Accounting_Journal_ID'
    },
    {
      text: 'Header_Locked_in_Workday',
      value: 'Header_Locked_in_Workday'
    },
    {
      text: 'Header_Journal_Sequence_Number',
      value: 'Header_Journal_Sequence_Number'
    },
    {
      text: 'Header_Alternate_Ledger_Currency_Rate_Type_Reference',
      value: 'Header_Alternate_Ledger_Currency_Rate_Type_Reference'
    },
    {
      text: 'Header_Journal_for_All_Ledgers',
      value: 'Header_Journal_for_All_Ledgers'
    },
    {
      text: 'Header_Auto_Complete',
      value: 'Header_Auto_Complete'
    },
    {
      text: 'Header_Comment',
      value: 'Header_Comment'
    },
    {
      text: 'Header_Worker_Reference',
      value: 'Header_Worker_Reference'
    },
    {
      text: 'Line_Exclude_from_Spend_Report',
      value: 'Line_Exclude_from_Spend_Report'
    },
    {
      text: 'Line_Balancing_Worktag_Affiliate_Reference',
      value: 'Line_Balancing_Worktag_Affiliate_Reference'
    },
    {
      text: 'Line_Worktags_Reference',
      value: 'Line_Worktags_Reference'
    },
    {
      text: 'Line_Budget_Date',
      value: 'Line_Budget_Date'
    },
    {
      text: 'Line_External_Reference_ID',
      value: 'Line_External_Reference_ID'
    },
    {
      text: 'Line_Memo',
      value: 'Line_Memo'
    },
    {
      text: 'Line_Unit_of_Measure_2_Reference',
      value: 'Line_Unit_of_Measure_2_Reference'
    },
    {
      text: 'Line_Quantity_2',
      value: 'Line_Quantity_2'
    },
    {
      text: 'Line_Unit_of_Measure_Reference',
      value: 'Line_Unit_of_Measure_Reference'
    },
    {
      text: 'Line_Quantity',
      value: 'Line_Quantity'
    },
    {
      text: 'Line_Ledger_Credit_Amount',
      value: 'Line_Ledger_Credit_Amount'
    },
    {
      text: 'Line_Ledger_Debit_Amount',
      value: 'Line_Ledger_Debit_Amount'
    },
    {
      text: 'Line_Currency_Rate',
      value: 'Line_Currency_Rate'
    },
    {
      text: 'Line_Currency_Reference',
      value: 'Line_Currency_Reference'
    },
    {
      text: 'Line_Credit_Amount',
      value: 'Line_Credit_Amount'
    },
    {
      text: 'Line_Debit_Amount',
      value: 'Line_Debit_Amount'
    },
    {
      text: 'Line_Alternate_Ledger_Account_Reference',
      value: 'Line_Alternate_Ledger_Account_Reference'
    },
    {
      text: 'Line_Ledger_Account_Reference',
      value: 'Line_Ledger_Account_Reference'
    },
    {
      text: 'Line_Line_Company_Reference',
      value: 'Line_Line_Company_Reference'
    },
    {
      text: 'Line_Line_Order',
      value: 'Line_Line_Order'
    },
    {
      text: 'Line_Journal_Line_Number',
      value: 'Line_Journal_Line_Number'
    },
    {
      text: 'Line_Cost_Center',
      value: 'Line_Cost_Center'
    }
  ];
};

export const buildWorkdayGlobalConstants = (auth: {
  OB_WD_SERVICE_URL: string;
  OB_WD_USERNAME: string;
  OB_WD_PASSWORD: string;
  OB_ZR_URL: string;
  OB_ZR_USERNAME: string;
  OB_ZR_PASSWORD: string;
  OB_ZR_USERROLE: string;
  OB_ZR_CLIENTNAME: string;
}, emailNotifications: {
  enabled: boolean;
  warnings: boolean;
  success: boolean;
  emails: string[];
}, fieldMappings: any) => {
  // build field mappings
  let mappings = {};
  const mappingKeys = Object.keys(fieldMappings);
  if (mappingKeys.length > 0) {
    for (let key of mappingKeys) {
      if (['mappingOptions', 'choose_solution_instance'].includes(key)) {
        continue;
      }
      const rawMappings = fieldMappings[key]?.['mappings'] || {};
      const rawMappingKeys = Object.keys(rawMappings);
      if (rawMappingKeys.length > 0) {
        for (let rmKey of rawMappingKeys) {
          const mapping = rawMappings[rmKey];
          const key = mapping['source']['value'];
          const value = mapping['target']['text'];
          mappings[key] = value;
        }
      }
    }
  }

  // build email configs
  let emailConfig = {};
  if (emailNotifications.enabled) {
    emailConfig = {
      'Failures': true,
      'Warnings': emailNotifications.warnings || false,
      'Success': emailNotifications.success || false,
      'Emails': emailNotifications.emails || []
    };
  }

  return {
    "OB_WD_SERVICE_URL": auth.OB_WD_SERVICE_URL,
    "OB_WD_USERNAME": auth.OB_WD_USERNAME,
    "OB_WD_PASSWORD": auth.OB_WD_PASSWORD,
    "OB_ZR_URL": auth.OB_ZR_URL,
    "OB_ZR_USERNAME": auth.OB_ZR_USERNAME,
    "OB_ZR_PASSWORD": auth.OB_ZR_PASSWORD,
    "OB_ZR_USERROLE": auth.OB_ZR_USERROLE,
    "OB_ZR_CLIENTNAME": auth.OB_ZR_CLIENTNAME,
    "OB_WD_MAPPINGS": JSON.stringify(mappings),
    "OB_WD_EMAIL_CONFIG": JSON.stringify(emailConfig)
  };
};