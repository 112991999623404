import { AlertColor, Autocomplete, TextField, styled } from '@mui/material';
import { Alert, Card, Table, ToastController } from '@platform-ui/design-system';
import React, { Dispatch, FC, useEffect, useState } from 'react';
import { IADState } from '../../../IntegrationApps/IntegrationAppDetails/state';
import { Action, useStoreContext } from '../../../Store';
import { WD_ADD_FIELD_MAPPING_ROW, WD_ADD_SOURCE_TARGET, WD_DELETE_FIELD_MAPPING_ROW, WD_FILTER_FIELD_MAPPING_ROWS, WD_LOAD_FIELD_MAPPING_OPTIONS } from './action_types';
import Connect from '../../../Connect/Connect';
import { getRevenueMappings, getRevenueWorkdayMappings, getWorkdayMappings } from './utils';

interface FieldMappingProps {
  tabName: string;
}

const transform = (instanceName: string) => instanceName.trim().replace(/\s+/g, '_');

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-endAdornment": {
    top: "auto !important"
  },
  "& .MuiAutocomplete-input": {
    paddingTop: "0px !important",
    paddingBottom: "0px !important"
  }
});

export const FieldMapping: FC<FieldMappingProps> = ({
  tabName
}: FieldMappingProps) => {
  let searchTimer = null;
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const [loading, setLoading] = useState(false);
  const [instanceName, setInstanceName] = useState('');
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success' as AlertColor
  });

  const handleSearch = (searchValue) => {
    /**
     * Simple debounce to avoid re renders
     * Increase the timeout if there is performance impact for large data sets
     */
    if(searchTimer) {
      clearTimeout(searchTimer);
    }
    searchTimer = setTimeout(() => {
      dispatch({
        type: WD_FILTER_FIELD_MAPPING_ROWS,
        payload: {
          instanceName: transform(instanceName),
          filterValue: searchValue
        }
      });
    }, 500);
  };


  const addFieldMapping = () => {
    const name = transform(instanceName);
    const rows = Object.values(state.settingsHash[tabName][name]?.mappingRows || {});
    const lastRow = rows.at(-1);
    const rowId = lastRow ? lastRow['rowId'] - -1 : 0; // this is the same as lastRow['rowId'] + 1 whether or not lastRow['rowId'] is a numeric string or an integer

    dispatch({
      type: WD_ADD_FIELD_MAPPING_ROW,
      payload: {
        instanceName: name,
        rowId,
        [name]: {
          rowId,
          key: rowId,
          [`workday_target_fields`]: JSON.stringify(state.settingsHash['fieldMapping']?.['mappingOptions']?.workdayFields || []),
          '': '→',
          [`zuora_source_fields`]: JSON.stringify(state.settingsHash['fieldMapping']?.['mappingOptions']?.zuoraFields || [])
        }
      }
    });
  };

  const deleteFieldMapping = (event) => {
    dispatch({
      type: WD_DELETE_FIELD_MAPPING_ROW,
      payload: {
        instanceName: transform(instanceName),
        rowId: (event as any).row.rowId
      }
    });
  };

  const downloadFieldMappings = () => {
    const name = transform(instanceName);
    const fileName = `${name}_field_mappings.json`;
    
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([JSON.stringify(state.settingsHash[tabName][name]?.mappings || {}, null, 2)], { type: 'application/json' }));
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(a.href);
    a.remove();
  };

  const getFieldMappings = async () => {
    setLoading(true);
    setToast({...toast, show: false});
    
    try {
      // load workday and zuora journal mappings
      let workdayFields = [];
      let zuoraFields = [];

      if (state.workday_mappings) {
        workdayFields = state.workday_mappings;
        zuoraFields = await getRevenueMappings(state.settingsHash?.['authentication'] || {});
      } else {
        const response = await getRevenueWorkdayMappings(state.settingsHash?.['authentication'] || {});
        workdayFields = response?.['workdayMappings'] || [];
        zuoraFields = response?.['revenueMappings'] || [];
      }

      dispatch({
        type: WD_LOAD_FIELD_MAPPING_OPTIONS,
        payload: { workdayFields, zuoraFields }
      });
    } catch (err) {
      Connect.log(err);
      setToast({
        show: true,
        msg: `Failed to load Workday/Revenue mappings. Please check Workday/Revenue credentials!`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const zuoraFields: any = {
    field: `zuora_source_fields`,
    label: `Zuora Source Fields`,
    dsRenderCell: ({ row }) => {
      const name = transform(instanceName);
      const rowId = row.rowId;
      const items = JSON.parse(row[`zuora_source_fields`]) || [];
      const inputPlaceHolder = 'Select source field';
      const selectedValueText = state.settingsHash[tabName][name]?.mappings[rowId]?.['source']?.['text'] || '';

      const selectedValueObj = items.find(item => item.text === selectedValueText);
      const [selectedValue, setSelectedValue] = useState(selectedValueObj || null);
      const [inputValue, setInputValue] = useState('');

      useEffect(() => {
        if (selectedValueObj && JSON.stringify(selectedValueObj) !== JSON.stringify(selectedValue)) {
          setSelectedValue(() => selectedValueObj);
        }
      }, [selectedValueObj]);

      return (
        <StyledAutocomplete
          disableClearable
          getOptionLabel={(option: any) => option.text || ''}
          isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
          value={selectedValue}
          onChange={(event: any, newValue: any) => {
            dispatch({
              type: WD_ADD_SOURCE_TARGET,
              payload: {
                instanceName: transform(instanceName),
                rowId,
                source: newValue
              }
            });
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id={`zuora-fields-selection-${rowId}`}
          options={items}
          fullWidth
          renderInput={(params) => <TextField {...params} placeholder={inputPlaceHolder} />}
        />
      );
    }
  };


  const workdayFields: any = {
    field: `workday_target_fields`,
    label: `Workday Journal Target Fields`,
    dsRenderCell: ({ row }) => {
      const rowId = row.rowId;
      const items = JSON.parse(row[`workday_target_fields`]) || [];
      const inputPlaceHolder = 'Select target field';
      const name = transform(instanceName);
      const selectedValueText = state.settingsHash[tabName][name]?.mappings[rowId]?.['target']?.['text'] || ''
      const selectedValueObj = items.find(item => item.text === selectedValueText);
      const [selectedValue, setSelectedValue] = useState(selectedValueObj || null);
      const [inputValue, setInputValue] = useState('');

      useEffect(() => {
        if (selectedValueObj && JSON.stringify(selectedValueObj) !== JSON.stringify(selectedValue)) {
          setSelectedValue(() => selectedValueObj);
        }
      }, [selectedValueObj]);
      return (

        <StyledAutocomplete
          disableClearable
          getOptionLabel={(option: any) => option.text || ''}
          isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
          value={selectedValue}
          onChange={(event: any, newValue: any) => {
            dispatch({
              type: WD_ADD_SOURCE_TARGET,
              payload: {
                instanceName: transform(instanceName),
                rowId,
                target: newValue
              }
            });
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id={`workday-field-selection-${rowId}`}
          options={items}
          fullWidth
          renderInput={(params) => <TextField {...params} placeholder={inputPlaceHolder} />}
        />
      );
    }
  };

  useEffect(() => {
    if (state.saved && state.reloadFieldMappings) {
      getFieldMappings();
    }
  }, [state.saved, state.reloadFieldMappings]);

  // TODO: improve the loading possibly by useMemo???
  useEffect(() => {
    // Add a little bit of delay when loading field mappings to avoid race condition in the backend
    // when trying to load Workday connector
    let timer = null;
    if (state.settingsHash['authentication']) {
      timer = setTimeout(() => getFieldMappings(), 1000);
    }
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (instanceName !== '' && !state.settingsHash['fieldMapping']?.[transform(instanceName)]) {
      getFieldMappings();
    }
  }, [instanceName]);

  useEffect(() => {
    const defaultInstance = state.settingsHash['solutionInstances']?.find(s => s.solution_instance_name === 'IH_Internal_Workday_Outbound');
    if (defaultInstance) {
      setInstanceName(defaultInstance.solution_instance_name || '');
    }
  }, [state.settingsHash['solutionInstances']]);

  try {
    if (!state.active) {
      return (
        <Alert center variant='outlined' severity='warning' body='You must enable integration in Authentication tab first' open={true} />
      );
    }

    if (!state.settingsHash['authentication']) {
      return (
        <Alert center variant='outlined' severity='warning' body='You must configure Authentication and save first' open={true} />
      );
    }

    if (state.provisionSolutionInstanceError) {
      return (
        <Alert center variant='outlined' severity='error' body='Failed to provision your instance. Please contact admin!' open={true} />
      );
    }

    if (!state.settingsHash['solutionInstances'] || !state.settingsHash['solutionInstances']?.find(s => s.solution_instance_name === 'IH_Internal_Workday_Outbound')) {
      return (
        <Alert center variant='outlined' severity='warning' body='Please wait while we are provisioning your instance' open={true} />
      );
    }
  } catch (err) {
    Connect.log(err);
    return (
      <Alert center variant='outlined' severity='error' body='An unexpected error has occurred. Please contact admin for technical support!' open={true} />
    );
  }

  return (
    <>
      {toast.show && <ToastController severity={toast.severity as any} message={toast.msg} />}
      <div style={{ marginTop: '0px' }}>
        <Card
          id='workday-field-mapping-bottom-ui'
          titleBar
          header='Map Source Field with the Target Field'
          body={
            <Table
              loading={loading}
              columns={
                [
                  zuoraFields,
                  {
                    field: '',
                    label: '',
                    alignment: 'center' as any,
                  },
                  workdayFields
                ]
              }
              rows={Object.values(state.settingsHash['filteredFieldMapping']?.[transform(instanceName)]?.mappingRows || {})}
              uniqueKey='workday-map-field-table'
              sortable={false}
              tableActions={
                [
                  {
                    icon: 'add',
                    tooltip: 'Add a Field Mapping',
                    disabled: (
                      (instanceName === '' || state.settingsHash[tabName]?.mappingOptions == null || Object.keys(state.settingsHash[tabName]?.mappingOptions || {}).length === 0)
                    ),
                    onClick: () => addFieldMapping()
                  },
                  {
                    icon: 'refresh',
                    tooltip: 'Refresh Field Mapping Sources',
                    onClick: () => getFieldMappings()
                  },
                  {
                    icon: 'download',
                    tooltip: 'Download Field Mappings',
                    disabled: Object.keys(state.settingsHash[tabName]?.[transform(instanceName)]?.mappings || {}).length < 1,
                    onClick: () => downloadFieldMappings()
                  }
                ]
              }
              rowActions={
                [
                  {
                    icon: 'delete',
                    tooltip: 'Delete a Field Map',
                    onClick: (event) => deleteFieldMapping(event)
                  }
                ]
              }
              hidePagination={true}
              rowDisplayOptions={{
                hoverEffect: false
              }}
              searchable
              searchPlaceholder='Type field name to filter results...'
              dsOnSearchChange={(value) => handleSearch(value)}
            />
          }
        />
      </div>
    </>
  );
};