import Connect, { windmillRun } from '../../../../Connect/Connect';
import { buildGlobalConstantsForEmailConfigs, buildGlobalConstantsForFieldMappings } from '../../../../IntegrationApps/IntegrationAppDetails/utils';

/**
 * Validate the authentication configurations for connection to Zuora
 *
 * @param {{ SAP_BILLING_CLIENT_ID: string, SAP_BILLING_CLIENT_SECRET: string, SAP_BILLING_REST_ENDPOINT: string}} auth - Authentication configurations
 * @returns {isValid: boolean, status: number, statusText: string} - Validation result
 */
export const validateZuora = async (
  auth: {
    SAP_BILLING_CLIENT_ID: string;
    SAP_BILLING_CLIENT_SECRET: string;
    SAP_BILLING_REST_ENDPOINT: string;
  }
) => {
  let result = {
    isValid: false,
    status: 200,
    statusText: ''
  };
  try {
    const response = await windmillRun({
      'script_path': 'f/sap_connector/flows',
      'params': {
        'action': 'validate_zuora_billing',
        'auth': auth
      },
      'async': false
    });
    result = response;
  } catch (error) {
    Connect.log(error);
  }
  return result;
}

/**
 * Build Workflow global constants for SAP billing
 *
 * @param {{ SAP_BILLING_CLIENT_ID: string, SAP_BILLING_CLIENT_SECRET: string, SAP_BILLING_REST_ENDPOINT: string}} auth - Authentication configurations
 * @param {{enabled: boolean, failures: boolean, success: boolean, emails: string[]}} emailNotifications - Email notification configurations
 * @param {any} fieldMappings - Field mappings between Zuora and SAP
 * @param {string} envRestEndpoint - The REST endpoint for a specific Zuora environment (prod, test, apisandbox, etc.)
 * @returns {Object} - Global constants for SAP Billing
 */
export const buildSAPBillingGlobalConstants = (auth: {
  SAP_BILLING_CLIENT_ID: string;
  SAP_BILLING_CLIENT_SECRET: string;
  SAP_BILLING_REST_ENDPOINT: string;
}, emailNotifications: {
  enabled: boolean;
  failures: boolean;
  warnings: boolean;
  success: boolean;
  emails: string[];
}, fieldMappings: any, envRestEndpoint: string, destinations: any) => {

  // build field mappings
  const mappings = buildGlobalConstantsForFieldMappings(fieldMappings);

  // build email configs
  const emailConfigs = buildGlobalConstantsForEmailConfigs(emailNotifications);

  // store tenant information to Workflow Global Constants to be used in email notification
  const connect: Connect = (window as any).connect;
  const lastHyphenIndex = connect.tenant?.slug ? connect.tenant.slug.lastIndexOf('-') : -1;
  const host = lastHyphenIndex !== -1 ? connect.tenant.slug.substring(0, lastHyphenIndex) : '';
  const entity = lastHyphenIndex !== -1 ? connect.tenant.slug.substring(lastHyphenIndex + 1) : '';
  let environment = '';

  if (host.includes('staging')) {
    environment = 'Staging';
  } else if (host.includes('sandbox')) {
    environment = 'Sandbox';
  } else if (host.includes('test')) {
    environment = 'Test';
  } else if (host.includes('preprod')) {
    environment = 'Preprod';
  } else if (host.includes('na')) {
    environment = 'NA Production';
  } else if (host.includes('eu')) {
    environment = 'EU Production';
  } else if (host.includes('www.zuora.com') || host.includes('zuora.com')) {
    environment = 'Production';
  } else {
    environment = 'Development';
  }

  return {
    "SAP_BILLING_CLIENT_ID": auth.SAP_BILLING_CLIENT_ID,
    "SAP_BILLING_CLIENT_SECRET": auth.SAP_BILLING_CLIENT_SECRET,
    "SAP_BILLING_REST_ENDPOINT": envRestEndpoint,
    "SAP_BILLING_MAPPINGS": JSON.stringify(mappings),
    "SAP_BILLING_EMAIL_CONFIG": JSON.stringify(emailConfigs),
    "SAP_BILLING_ENVIRONMENT": environment,
    "SAP_BILLING_TENANT_ID": connect.tenant.tenant_id || entity || 'Unknown',
    "SAP_BILLING_TENANT_NAME": connect.tenant.name || 'Unknown',
    "SAP_BILLING_ENTITY_ID": entity || 'Unknown',
    "SAP_BILLING_DESTINATIONS": JSON.stringify(destinations)
  };
};