import { useLocalStorage } from '@rehooks/local-storage';
import UserPreference from '../types/UserPreference';

const useUserPreference = ({ userId }: { userId?: string }) => {
  const [userPreference] = useLocalStorage<UserPreference>(
    `preferences.userId=${userId}`,
    {
      darkMode: false,
      density: 'condensed',
    },
  );

  return userPreference;
};

export default useUserPreference;
