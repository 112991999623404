import { Draft } from 'immer';
import { ImmerReducer } from 'use-immer';
import { IADState } from './state';
import { Action } from '../../Store';
import { actionMap as iadActionMap } from './action_map';
import { actionMap as nsActionMap } from '../../Connectors/CustomTabs/NetSuite';
import { actionMap as zdpActionMap } from '../../Connectors/CustomTabs/ZDP/action_map';
import { actionMap as wdActionMap } from '../../Connectors/CustomTabs/Workday';
import { actionMap as wdbActionMap } from '../../Connectors/CustomTabs/WorkdayBilling';
import { actionMap as zdqActionMap } from '../../Connectors/CustomTabs/ZDQ/action_map';
import { actionMap as SAPActionMap } from '../../Connectors/CustomTabs/SAP';
import { actionMap as wfExecutionActionMap } from './components/WorkflowExecution/reducer/action_map';

type ActionMap = {
  [key: string]: (draft: Draft<IADState>, payload: any) => void;
};

const actionMaps: ActionMap = {
  ...iadActionMap,
  ...nsActionMap,
  ...zdpActionMap,
  ...wdActionMap,
  ...wdbActionMap,
  ...zdqActionMap,
  ...SAPActionMap,
  ...wfExecutionActionMap,
  
  // Add a special action for lazy state expansion
  EXPAND_STATE: (draft: Draft<IADState>, payload: { path: string; initialValue: any }) => {
    const { path, initialValue } = payload;
    if (!draft.settingsHash[path]) {
      draft.settingsHash[path] = initialValue;
      draft.modified = true;
      draft.saved = false;
    }
  }
};

const ensureSettingsHashPath = (draft: Draft<IADState>, type: string) => {
  if (!draft.settingsHash) {
    draft.settingsHash = {};
  }
  if (!draft.settingsHash[type]) {
    draft.settingsHash[type] = {};
  }
};

const handleGenericUpdate = (draft: Draft<IADState>, type: string, payload: any) => {
  ensureSettingsHashPath(draft, type);
  draft.settingsHash[type] = { ...draft.settingsHash[type], ...payload };
  draft.modified = true;
  draft.credsModified = true;
  draft.saved = false;
  draft.solutionInstanceUpdated = false;
};

export const reducer: ImmerReducer<IADState, Action> = (draft: Draft<IADState>, action: Action) => {
  const { type, payload } = action;

  // Handle iframe and custom tab cases first
  if (type.match(/^(iframe|custom).*/i)?.input) {
    // Dynamically register the action if it doesn't exist
    if (!actionMaps[type]) {
      actionMaps[type] = (draftState: Draft<IADState>, actionPayload: any) => {
        handleGenericUpdate(draftState, type, actionPayload);
      };
    }
    // Execute the action immediately
    return actionMaps[type](draft, payload);
  }

  // Handle mapped actions
  const mappedAction = actionMaps[type];
  if (mappedAction) {
    return mappedAction(draft, payload);
  }

  // Fallback for unmapped actions (with type guard to ensure they don't get dropped)
  handleGenericUpdate(draft, type, payload);
};

// Helper function for components to use when they need to lazy expand state
export const createExpandStateAction = (path: string, initialValue: any): Action => ({
  type: 'EXPAND_STATE',
  payload: { path, initialValue }
});