import { Draft } from 'immer';
import { UPDATE_SOLUTION_INSTANCE_SCHEDULE } from './action_types';
import { IADState } from '../../../state';

export const actionMap = {
  [UPDATE_SOLUTION_INSTANCE_SCHEDULE]: (draft: Draft<IADState>, payload: any) => {
    if (!draft.settingsHash['solutionInstances']) {
      draft.settingsHash['solutionInstances'] = [];
    }
    const instance = draft.settingsHash['solutionInstances'].find(instance => instance.id === payload.id);
    if (instance) {
      instance.scheduled_trigger = payload.scheduled_trigger;
      instance.interval = payload.interval;
      instance.timezone = payload.timezone;
    }
  },
}
