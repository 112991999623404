import { Spinner } from '@platform-ui/design-system';
import React from 'react';
import Connect from '../../../../Connect/Connect';
import { WorkflowExecution } from '../../../../IntegrationApps/IntegrationAppDetails/components/WorkflowExecution';
import { useFlipperHook } from '../../../../Tables/helpers';
import { useToast } from '../../../../ToastProvider';

export const Execution = () => {
  const [sapFeatureEnabled, sapFeatureLoading] = useFlipperHook('enable_sap_connector');
  const { setToast } = useToast();
  const connect: Connect = (window as any).connect;
  const isLocal = window.location.host.includes('localhost');
  const workflowProxyPath = `${isLocal ? 'http://localhost:8080' : ''}/platform/gateway-proxy-v2`;

  const download = (fileName: string, content: any) => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([JSON.stringify(content, null, 2)], { type: 'application/json' }));
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(a.href);
    a.remove();
  };

  const onDownloadClickHandler = async (args: { row: any }) => {
    const { row } = args;
    try {
      const response = await Connect.proxyCall(
        `${workflowProxyPath}/workflows/tasks?workflow_id=${row.id}&name=FileLink`,
        'GET',
        undefined,
        {
          'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
          'Scope': 'Internal'
        }
      );

      if (!response.ok) throw Error(response.statusText);

      const { data } = await response.json();
      
      if (!Array.isArray(data) || data.length === 0) throw Error('No output task was found');

      const fileName = `${row.solution_instance_name}_${row.workflow_run_number}_execution.json`;

      download(fileName, data[0]?.['data']?.['Revenue::GenerateSummary']?.['Summary'] || {});
    } catch (err) {
      Connect.log(`Failed to download SAP Revenue summary: ${err}`);
      setToast({
        message: `Failed to download: ${err.message}`,
        severity: 'error',
        keyRender: Date.now()
      });
    }
  };

  if (sapFeatureLoading) {
    return <Spinner />;
  }

  return (
    <WorkflowExecution
      tableName='execution'
      workflowTemplateName='z-IntegrationHub-SAP-Revenue'
      permissionCheckDisabled={sapFeatureEnabled}
      permissionName='permission.ZuoraSAPConnector'
      taskRerunEnabled={false}
      onViewSummaryClickHandler={onDownloadClickHandler}
    />
  );
};
