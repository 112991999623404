import Connect, { windmillRun } from '../../../../Connect/Connect';
import { buildGlobalConstantsForEmailConfigs, buildGlobalConstantsForFieldMappings } from '../../../../IntegrationApps/IntegrationAppDetails/utils';

/**
 * Validate the authentication configurations for connection to Revenue
 *
 * @param {{ SAP_REVENUE_URL: string, SAP_REVENUE_USERNAME: string, SAP_REVENUE_PASSWORD: string,
 * SAP_REVENUE_USERROLE: string, SAP_REVENUE_CLIENTNAME: string }} auth - Authentication configurations
 * @returns {isValid: boolean, status: number, statusText: string} - Validation result
 */
export const validateRevenue = async (
  auth: {
    SAP_REVENUE_URL: string;
    SAP_REVENUE_USERNAME: string;
    SAP_REVENUE_PASSWORD: string;
    SAP_REVENUE_USERROLE: string;
    SAP_REVENUE_CLIENTNAME: string;
  }
) => {
  let result = {
    isValid: false,
    status: 200,
    statusText: ''
  };
  try {
    const response = await windmillRun({
      'script_path': 'f/sap_connector/flows',
      'params': {
        'action': 'validate_zuora_revenue',
        'auth': auth
      },
      'async': false
    });
    result = response;
  } catch (error) {
    Connect.log(error);
  }
  return result;
}

/**
 * Build Workflow global constants for SAP Revenue
 *
 * @param {{ SAP_REVENUE_URL: string, SAP_REVENUE_USERNAME: string, SAP_REVENUE_PASSWORD: string,
 * SAP_REVENUE_USERROLE: string, SAP_REVENUE_CLIENTNAME: string }} auth - Authentication configurations
 * @param {{enabled: boolean, failures: boolean, warnings: boolean, success: boolean, emails: string[]}} emailNotifications - Email notification configurations
 * @param {any} fieldMappings - Field mappings between Zuora and SAP
 * @returns {Object} - Global constants for SAP Revenue
 */
export const buildSAPRevenueGlobalConstants = (auth: {
  SAP_REVENUE_URL: string;
  SAP_REVENUE_USERNAME: string;
  SAP_REVENUE_PASSWORD: string;
  SAP_REVENUE_USERROLE: string;
  SAP_REVENUE_CLIENTNAME: string;
}, emailNotifications: {
  enabled: boolean;
  failures: boolean;
  warnings: boolean;
  success: boolean;
  emails: string[];
}, fieldMappings: any, destinations: any) => {
  // build field mappings
  const mappings = buildGlobalConstantsForFieldMappings(fieldMappings);

  // build email configs
  const emailConfigs = buildGlobalConstantsForEmailConfigs(emailNotifications);

  return {
    "SAP_REVENUE_URL": auth.SAP_REVENUE_URL,
    "SAP_REVENUE_USERNAME": auth.SAP_REVENUE_USERNAME,
    "SAP_REVENUE_PASSWORD": auth.SAP_REVENUE_PASSWORD,
    "SAP_REVENUE_USERROLE": auth.SAP_REVENUE_USERROLE,
    "SAP_REVENUE_CLIENTNAME": auth.SAP_REVENUE_CLIENTNAME,
    "SAP_REVENUE_MAPPINGS": JSON.stringify(mappings),
    "SAP_REVENUE_EMAIL_CONFIG": JSON.stringify(emailConfigs),
    "SAP_REVENUE_DESTINATIONS": JSON.stringify(destinations)
  };
};