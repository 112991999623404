import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Button, Grid, Modal, ModalActions, ModalTitle } from '@platform-ui/design-system';
import React, { FC, useState } from 'react';
import Connect from '../../../../../Connect/Connect';
import { useStoreDispatch } from '../../../../../Store';
import { useToast } from '../../../../../ToastProvider';
import { AddModalProps } from './types';

export const AddModal: FC<AddModalProps> = ({
  orgOptions,
  openAddModal,
  setOpenAddModal,
  rows,
  setRows
}: AddModalProps) => {
  const dispatch = useStoreDispatch();
  const [isAdding, setIsAdding] = useState(false);
  const [org, setOrg] = useState({id: '', name: ''});
  const [customerId, setCustomerId] = useState('');
  const [destSystem, setDestSystem] = useState('');
  const [destName, setDestName] = useState('');
  const { setToast } = useToast();

  return (
    <Modal
      id='add-destination-modal'
      open={openAddModal}
      disableBackdropClick={true}
      header={<ModalTitle dsOnClose={() => setOpenAddModal(false)}>Add a Destination</ModalTitle>}
      dsOnClose={() => setOpenAddModal(false)}
      body={
        <Grid container direction='column'>
          {
            orgOptions.length > 0 &&
            <Grid item>
              <TextField
                fullWidth
                select
                label='Organization'
                value={org.name}
              >
                {
                  orgOptions.map((orgOption: { id: string; name: string }) => (
                    <MenuItem
                      key={orgOption.id}
                      value={orgOption.name}
                      onClick={() => setOrg({ id: orgOption.id, name: orgOption.name })}
                    >
                      {orgOption.name}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Grid>
          }
          <Grid item>
            <TextField
              fullWidth
              placeholder='Enter Customer ID'
              onChange={(e) => setCustomerId(e.target.value)}
              value={customerId}
              label='Customer ID'
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              placeholder='Enter Destination System'
              onChange={(e) => setDestSystem(e.target.value)}
              value={destSystem}
              label='Destination System'
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              placeholder='Enter Destination Name'
              onChange={(e) => setDestName(e.target.value)}
              value={destName}
              label='Destination Name'
            />
          </Grid>
        </Grid>
      }
      footer={
        <ModalActions>
          <Button variant='outlined' dsOnClick={() => setOpenAddModal(false)} body='Cancel' />
          <Button
            disabled={isAdding || !customerId || !destName || !destSystem || (orgOptions.length > 0 && !org.id && !org.name)}
            body='Add'
            dsOnClick={async () => {
              setIsAdding(true);

              try {
                const addedRow = { orgId: org.id, orgName: org.name, customerId, destSystem, destName }
                const found = rows.findIndex(
                  (row: { orgId: string; orgName: string; customerId: string; destSystem: string; destName: string}) => (
                    row.orgId === addedRow.orgId &&
                    row.orgName === addedRow.orgName &&
                    row.customerId === addedRow.customerId &&
                    row.destSystem === addedRow.destSystem &&
                    row.destName === addedRow.destName
                  )
                ) !== -1;
                if (found) {
                  setToast({
                    message: 'Duplicate destination. Please try a different destination.',
                    severity: 'error',
                    keyRender: Date.now()
                  });
                  setIsAdding(false);
                  return;
                }
                const clone = [...rows, addedRow];
                setRows(clone);
                dispatch({
                  type: 'destination',
                  payload: clone
                });
              } catch(error) {
                Connect.log(error);
              }

              setIsAdding(false);
              setCustomerId('');
              setDestName('');
              setDestSystem('');
              setOrg({id: '', name: ''});
              setOpenAddModal(false);
            }}
          />
        </ModalActions>
      }
    />
  );
};