import { AlertColor, Autocomplete, MenuItem, TextField, styled } from '@mui/material';
import { Alert, Card, Grid, Table, ToastController } from '@platform-ui/design-system';
import React, { Dispatch, FC, useEffect, useState } from 'react';
import { IADState } from '../../../IntegrationApps/IntegrationAppDetails/state';
import { Action, useStoreContext } from '../../../Store';
import { NS_ADD_FIELD_MAPPING_ROW, NS_ADD_SOURCE_TARGET, NS_DELETE_FIELD_MAPPING_ROW, NS_FILTER_FIELD_MAPPING_ROWS, NS_LOAD_FIELD_MAPPING_OPTIONS } from './action_types';
import Connect from '../../../Connect/Connect';

interface FieldMappingProps {
  tabName: string;
  inbound: boolean;
}

const transform = (instanceName: string) => instanceName.trim().replace(/\s+/g, '_');

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-endAdornment": {
    top: "auto !important"
  },
  "& .MuiAutocomplete-input": {
    paddingTop: "0px !important",
    paddingBottom: "0px !important"
  }
});

export const FieldMapping: FC<FieldMappingProps> = ({
  tabName,
  inbound = true
}: FieldMappingProps) => {
  let searchTimer = null;
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const [loading, setLoading] = useState(false);
  const [instanceName, setInstanceName] = useState('');
  const [instanceIdx, setInstanceIdx] = useState(-1);
  const [objectName, setObjectName] = useState('');
  const includesObjects = (state.settingsHash['solutionInstances'] || [])[instanceIdx]?.includes || {};
  const mappings = (state.settingsHash['solutionInstances'] || [])[instanceIdx]?.mappings || {};
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success' as AlertColor
  });

  const handleSearch = (searchValue) => {
    /**
     * Simple debounce to avoid re renders
     * Increase the timeout if there is performance impact for large data sets
     */
    if(searchTimer) {
      clearTimeout(searchTimer);
    }
    searchTimer = setTimeout(() => {
      dispatch({
        type: NS_FILTER_FIELD_MAPPING_ROWS,
        payload: {
          instanceName: transform(instanceName),
          objectName: inbound ? objectName : null,
          inbound,
          filterValue: searchValue
        }
      });
    }, 500);
  };


  const addFieldMapping = () => {
    const name = transform(instanceName);
    const rows = inbound ? Object.values(state.settingsHash[tabName][name]?.[objectName]?.mappingRows || {}) : Object.values(state.settingsHash[tabName][name]?.mappingRows || {});
    const lastRow = rows.at(-1);
    const rowId = lastRow ? lastRow['rowId'] - -1 : 0; // this is the same as lastRow['rowId'] + 1 whether or not lastRow['rowId'] is a numeric string or an integer

    dispatch({
      type: NS_ADD_FIELD_MAPPING_ROW,
      payload: {
        inbound,
        instanceIdx,
        instanceName: name,
        objectName: inbound ? objectName : null,
        rowId,
        [name]: {
          rowId,
          key: rowId,
          [`netsuite_${inbound ? 'source' : 'target'}_fields`]: JSON.stringify(state.settingsHash['fieldMapping']?.['mappingOptions']?.netsuiteFields || []),
          '': '→',
          [`zuora_${inbound ? 'target' : 'source'}_fields`]: JSON.stringify(state.settingsHash['fieldMapping']?.['mappingOptions']?.zuoraFields || [])
        }
      }
    });
  };

  const deleteFieldMapping = (event) => {
    dispatch({
      type: NS_DELETE_FIELD_MAPPING_ROW,
      payload: {
        instanceName: transform(instanceName),
        objectName: inbound ? objectName : null,
        rowId: (event as any).row.rowId
      }
    });
  };

  const downloadFieldMappings = () => {
    const name = transform(instanceName);
    const fileName = `${name}_field_mappings.json`;
    
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([JSON.stringify(state.settingsHash[tabName][name]?.mappings || {}, null, 2)], { type: 'application/json' }));
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(a.href);
    a.remove();
  };

  const parseInboundMappings = (netsuiteMappings, zuoraMappings) => {
    // parse netsuite
    const records = netsuiteMappings.items;
    const fields = new Set();
  	records.forEach(record => {
    	Object.keys(record).forEach(field => {
        fields.add(field);
    	});
		});
  	fields.delete('links');
    const netsuiteFields = Array.from(fields).map(data => {
        return {text: data, value: data};
  	});

    // parse zuora
    const unSortedFields = zuoraMappings.Mapping.sort((a,b) => (a.seq > b.seq ? 1 : -1));
    const zuoraFields = unSortedFields.map(data => {
        return {text: data.label, value: data.label};
  	});

    return [netsuiteFields, zuoraFields];
  };

  const parseOutboundMappings = (netsuiteMappings, zuoraMappings) => {
    // parse netsuite
    const IGNORE_FIELDS = ["createdDate", "lastModified", "links", "clearedDate", "id", "cleared", "void", "customForm"];
    const entries = Object.entries(netsuiteMappings);
    const netsuiteFields = [];
    entries.forEach(entry => {
      const [key, value] = entry
      if (IGNORE_FIELDS.indexOf(key) !== -1) {
        return;
      }

      if (key === "line") {
        const keys = Object.keys((value as any).properties.items.items.properties);
        keys.forEach(val => {
          if (val === "memo") {
            netsuiteFields.push({
              text: `Line_${val}`,
              value: `Line_${val}`
            });
          } else {
            if (IGNORE_FIELDS.indexOf(val) !== -1 || val === "line") {
              return;
            }
            netsuiteFields.push({
              text: `Line_${val}`,
              value: val
            });
          }
        });
      } else {
        netsuiteFields.push({
          text: `Header_${key}`,
          value: key
        });
      }
    });

    // parse zuora
    const DEFAULT_LIST = [];
    const zuoraFields = zuoraMappings.map(data => {
      if (!DEFAULT_LIST.includes(data.column_name)) {
        return {
          text: data.disp_label?.concat(" (",data.column_name,")") || data.column_name,
          value: data.disp_label || data.column_name
        };
      }
    });

    return [netsuiteFields, zuoraFields];
  };

  const getFieldMappings = async () => {
    if (inbound && (instanceName === '' || objectName === '')) {
      return;
    }

    setLoading(true);

    if (inbound) {
      setToast({...toast, show: true, msg: 'Loading field mappings might take a while!!!', severity: 'warning'});
    } else {
      setToast({...toast, show: false});
    }
    
    try {
      // load netsuite and zuora journal mapping
      const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
      const options = {
        method: 'GET',
        headers: new Headers({
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'X-CSRF-Token': csrf
        })
      };

      const netsuiteObj = inbound && mappings[objectName].netsuite;
      const revenueObj = inbound && mappings[objectName].revenue;

      const response = await window.fetch(`${state.settingsUrl.replace('.json', `/netsuite/mappings?inbound=${inbound}&netsuite_src_obj=${netsuiteObj}&zuora_target_obj=${revenueObj}`)}`, options);
      if (!response.ok) throw Error(response.statusText);

      const { netsuiteMappings, zuoraMappings } = await response.json();

      // TODO(Duc): Move these parsing sections to their own functions out of the functional component
      const [netsuiteFields, zuoraFields] = inbound ? parseInboundMappings(netsuiteMappings, zuoraMappings) : parseOutboundMappings(netsuiteMappings, zuoraMappings);

      dispatch({
        type: NS_LOAD_FIELD_MAPPING_OPTIONS,
        payload: { netsuiteFields, zuoraFields, inbound }
      });
    } catch (err) {
      Connect.log(err);
      setToast({
        show: true,
        msg: `Failed to load NetSuite/Revenue mappings. Please check NetSuite/Revenue credentials${inbound ? ' and custom objects' : ''}!`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const zuoraFields: any = {
    field: `zuora_${inbound ? 'target' : 'source'}_fields`,
    label: `Zuora ${inbound ? 'Target' : 'Source'} Fields`,
    dsRenderCell: ({ row }) => {
      const name = transform(instanceName);
      const rowId = row.rowId;
      const items = JSON.parse(row[`zuora_${inbound ? 'target' : 'source'}_fields`]) || [];
      const inputPlaceHolder = inbound ? 'Select target field' : 'Select source field';
      const selectedValueText = inbound
        ? state.settingsHash[tabName][name]?.[objectName]?.mappings[rowId]?.[inbound ? 'target' : 'source']?.['text'] || ''
        : state.settingsHash[tabName][name]?.mappings[rowId]?.[inbound ? 'target' : 'source']?.['text'] || '';

      const selectedValueObj = items.find(item => item.text === selectedValueText);
      const [selectedValue, setSelectedValue] = useState(selectedValueObj || null);
      const [inputValue, setInputValue] = useState('');

      useEffect(() => {
        if (selectedValueObj && JSON.stringify(selectedValueObj) !== JSON.stringify(selectedValue)) {
          setSelectedValue(() => selectedValueObj);
        }
      }, [selectedValueObj]);

      return (
        <StyledAutocomplete
          disableClearable
          getOptionLabel={(option: any) => option.text || ''}
          isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
          value={selectedValue}
          onChange={(event: any, newValue: any) => {
            dispatch({
              type: NS_ADD_SOURCE_TARGET,
              payload: {
                instanceName: transform(instanceName),
                objectName: inbound ? objectName : null,
                rowId,
                [inbound ? 'target' : 'source']: newValue
              }
            });
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id={`zuora-fields-selection-${rowId}`}
          options={items}
          fullWidth
          renderInput={(params) => <TextField {...params} placeholder={inputPlaceHolder} />}
        />
      );
    }
  };


  const netsuiteFields: any = {
    field: `netsuite_${inbound ? 'source' : 'target'}_fields`,
    label: `NetSuite ${inbound ? 'Source' : 'Target'} Fields`,
    dsRenderCell: ({ row }) => {
      const rowId = row.rowId;
      const items = JSON.parse(row[`netsuite_${inbound ? 'source' : 'target'}_fields`]) || [];
      const inputPlaceHolder = inbound ? 'Select source field' : 'Select target field';
      const name = transform(instanceName);
      const selectedValueText = inbound
        ? state.settingsHash[tabName][name]?.[objectName]?.mappings[rowId]?.[inbound ? 'source' : 'target']?.['text'] || ''
        : state.settingsHash[tabName][name]?.mappings[rowId]?.[inbound ? 'source' : 'target']?.['text'] || ''
      const selectedValueObj = items.find(item => item.text === selectedValueText);
      const [selectedValue, setSelectedValue] = useState(selectedValueObj || null);
      const [inputValue, setInputValue] = useState('');

      useEffect(() => {
        if (selectedValueObj && JSON.stringify(selectedValueObj) !== JSON.stringify(selectedValue)) {
          setSelectedValue(() => selectedValueObj);
        }
      }, [selectedValueObj]);
      return (

        <StyledAutocomplete
          disableClearable
          getOptionLabel={(option: any) => option.text || ''}
          isOptionEqualToValue={(option: any, value: any) => option?.value === value?.value}
          value={selectedValue}
          onChange={(event: any, newValue: any) => {
            dispatch({
              type: NS_ADD_SOURCE_TARGET,
              payload: {
                instanceName: transform(instanceName),
                objectName: inbound ? objectName : null,
                rowId,
                [inbound ? 'source' : 'target']: newValue
              }
            });
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id={`netsuite-field-selection-${rowId}`}
          options={items}
          fullWidth
          renderInput={(params) => <TextField {...params} placeholder={inputPlaceHolder} />}
        />
      );
    }
  };

  useEffect(() => {
    if (state.saved && state.reloadFieldMappings) {
      getFieldMappings();
    }
  }, [state.saved, state.reloadFieldMappings]);

  // TODO: improve the loading possibly by useMemo???
  useEffect(() => {
    // Add a little bit of delay when loading field mappings to avoid race condition in the backend
    // when trying to load NetSuite connector
    let timer = null;
    if (state.settingsHash['authentication']) {
      timer = setTimeout(() => getFieldMappings(), 1000);
    }
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (instanceName !== '' && objectName !== '' && !state.settingsHash['fieldMapping']?.[transform(instanceName)]) {
      getFieldMappings();
    }
  }, [instanceName, objectName]);

  useEffect(() => {
    if (!inbound) {
      const defaultInstance = state.settingsHash['solutionInstances']?.find(s => s.solution_instance_name === 'IH_Internal_NetSuite_GL');
      if (defaultInstance) {
        setInstanceName(defaultInstance.solution_instance_name || '');
      }
    }
  }, [state.settingsHash['solutionInstances']]);

  try {
    if (!state.active) {
      return (
        <Alert center variant='outlined' severity='warning' body='You must enable integration in Authentication tab first' open={true} />
      );
    }

    if (!state.settingsHash['authentication']) {
      return (
        <Alert center variant='outlined' severity='warning' body='You must configure Authentication and save first' open={true} />
      );
    }

    if (inbound && (!state.settingsHash['solutionInstances'] || state.settingsHash['solutionInstances'].length === 0)) {
      return (
        <Alert center variant='outlined' severity='warning' body='You must configure Solution Instances first' open={true} />
      );
    }

    if (!inbound && !state.netsuiteAdmin && state.provisionSolutionInstanceError) {
      return (
        <Alert center variant='outlined' severity='error' body='Failed to provision your instance. Please contact admin!' open={true} />
      );
    }

    if (!inbound && !state.netsuiteAdmin && (!state.settingsHash['solutionInstances'] || !state.settingsHash['solutionInstances']?.find(s => s.solution_instance_name === 'IH_Internal_NetSuite_GL'))) {
      return (
        <Alert center variant='outlined' severity='warning' body='Please wait while we are provisioning your instance' open={true} />
      );
    }
  } catch (err) {
    Connect.log(err);
    return (
      <Alert center variant='outlined' severity='error' body='An unexpected error has occurred. Please contact admin for technical support!' open={true} />
    );
  }

  return (
    <>
      {toast.show && <ToastController severity={toast.severity as any} message={toast.msg} />}
      {
        inbound &&
        <Card
          id='netsuite-field-mapping-top-ui'
          body={
            <Grid container>
              <Grid key='choose-solution-instance-name' item xs={6}>
                <TextField
                  fullWidth
                  select
                  label={<b>Choose Solution Instance</b>}
                  value={instanceName}
                >
                  {
                    (state.settingsHash['solutionInstances'] || []).map((instance, idx) => (
                      <MenuItem
                        key={`${instance.solution_instance_name}-${idx}`}
                        value={`${instance.solution_instance_name}`}
                        onClick={() => {
                          setInstanceName(instance.solution_instance_name);
                          setInstanceIdx(idx);
                        }}
                      >
                        {instance.solution_instance_name}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Grid>
              <Grid key='choose-solution-instance-objects' item xs={6}>
                <TextField
                  fullWidth
                  select
                  label={<b>Choose Object for which you want to map the source and target fields</b>}
                  value={objectName}
                >
                  {
                    Object.keys(includesObjects).filter(obj => includesObjects[obj]).map((obj, idx) => (
                      <MenuItem
                        key={`${obj}-${idx}`}
                        value={obj}
                        onClick={() => {
                          setObjectName(obj);
                        }}
                      >
                        {obj}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </Grid>
            </Grid>
          }
        />
      }
      <div style={{ marginTop: inbound ? '10px' : '0px' }}>
        <Card
          id='netsuite-field-mapping-bottom-ui'
          titleBar
          header='Map Source Field with the Target Field'
          body={
            <Table
              loading={loading}
              columns={
                [
                  inbound ? netsuiteFields : zuoraFields,
                  {
                    field: '',
                    label: '',
                    alignment: 'center' as any,
                  },
                  inbound ? zuoraFields : netsuiteFields
                ]
              }
              rows={
                inbound
                  ? Object.values(state.settingsHash['filteredFieldMapping']?.[transform(instanceName)]?.[objectName]?.mappingRows || {})
                  : Object.values(state.settingsHash['filteredFieldMapping']?.[transform(instanceName)]?.mappingRows || {})
              }
              uniqueKey='netsuite-map-field-table'
              sortable={false}
              tableActions={
                [
                  {
                    icon: 'add',
                    tooltip: 'Add a Field Mapping',
                    disabled: (
                      inbound
                        ? (instanceName === '' || objectName === '' || state.settingsHash[tabName]?.mappingOptions == null || Object.keys(state.settingsHash[tabName]?.mappingOptions || {}).length === 0)
                        : (instanceName === '' || state.settingsHash[tabName]?.mappingOptions == null || Object.keys(state.settingsHash[tabName]?.mappingOptions || {}).length === 0)
                    ),
                    onClick: () => addFieldMapping()
                  },
                  {
                    icon: 'refresh',
                    tooltip: 'Refresh Field Mapping Sources',
                    disabled: inbound ? instanceName === '' || objectName === '' : false,
                    onClick: () => getFieldMappings()
                  },
                  {
                    icon: 'download',
                    tooltip: 'Download Field Mappings',
                    disabled: Object.keys(state.settingsHash[tabName]?.[transform(instanceName)]?.mappings || {}).length < 1,
                    onClick: () => downloadFieldMappings()
                  }
                ]
              }
              rowActions={
                [
                  {
                    icon: 'delete',
                    tooltip: 'Delete a Field Map',
                    onClick: (event) => deleteFieldMapping(event)
                  }
                ]
              }
              hidePagination={true}
              rowDisplayOptions={{
                hoverEffect: false
              }}
              searchable
              searchPlaceholder='Type field name to filter results...'
              dsOnSearchChange={(value) => handleSearch(value)}
            />
          }
        />
      </div>
    </>
  );
};