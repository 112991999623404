import { useCookies } from 'react-cookie';
import Identity from '../types/Identity';

enum ZuoraCookies {
  UserId = 'Zuora-User-Id',
  TenantId = 'Zuora-Tenant-Id',
  UserLanguage = 'Zuora-User-Language',
  UserLocale = 'Zuora-User-Locale',
  UserTimezone = 'Zuora-User-Timezone',
  CurrentEntity = 'ZuoraCurrentEntity',
}

type ZCookies = {
  userId?: string;
  tenantId?: string;
  language?: Identity['language'];
  /** Standard locale format, eg: xx_XX */
  locale?: string;
  timezone?: string;
  entityId?: string;
};

/**
 * @deprecated Don't use this outside of IdentityProvider!
 * */
const useZuoraCookies = (): ZCookies => {
  // setCookies currently isn't used anywhere, but we should lean on using Billing APIs to get cookies
  const [cookies] = useCookies([
    ZuoraCookies.UserId,
    ZuoraCookies.TenantId,
    ZuoraCookies.UserLanguage,
    ZuoraCookies.UserLocale,
    ZuoraCookies.UserTimezone,
    ZuoraCookies.CurrentEntity,
  ]);

  return {
    userId: cookies[ZuoraCookies.UserId],
    tenantId: cookies[ZuoraCookies.TenantId],
    language: cookies[ZuoraCookies.UserLanguage],
    locale: cookies[ZuoraCookies.UserLocale],
    timezone: cookies[ZuoraCookies.UserTimezone],
    entityId: cookies[ZuoraCookies.CurrentEntity],
  };
};

export default useZuoraCookies;
