import { Divider } from '@mui/material';
import { Grid } from '@platform-ui/design-system';
import React, { FC } from 'react';
import BasicTemplateHeaderAction from './BasicTemplateHeaderAction';
import BasicTemplateHeaderTitle from './BasicTemplateHeaderTitle';

interface BasicTemplateHeaderProps {
  header?: string;
  lastUpdatedBy?: string;
  lastUpdatedDate?: string;
  showTestConnection?: boolean;
  tabName?: string;
  showRefreshButton?: boolean;
}

const BasicTemplateHeader: FC<BasicTemplateHeaderProps> = ({
  header,
  lastUpdatedBy,
  lastUpdatedDate,
  showTestConnection,
  tabName,
  showRefreshButton
}: BasicTemplateHeaderProps) => {
  const subheader = 'Last Updated By: ' + `${lastUpdatedBy ? lastUpdatedBy : 'N/A'}` + ' | Last Updated Date: ' + `${lastUpdatedDate ? lastUpdatedDate : 'N/A'}`;

  return (
    <>
      <Grid container alignItems='center'>
        <BasicTemplateHeaderTitle
          header={header}
          subheader={subheader}
          showTestConnection={showTestConnection}
          tabName={tabName}
        />
        <Divider style={{marginTop: '15px'}} orientation='vertical' flexItem />
        <BasicTemplateHeaderAction tabName={tabName} showRefreshButton={showRefreshButton} />
      </Grid>
      <Divider style={{marginTop: '20px'}} />
    </>
  );
};

export default BasicTemplateHeader;