import { AlertColor, Autocomplete, TextField, styled } from '@mui/material';
import { Alert, Button, Card, Icon, Table, ToastController } from '@platform-ui/design-system';
import { IADState } from '../../state';
import React, { Dispatch, FC, useEffect, useState } from 'react';
import { Action, useStoreContext } from '../../../../Store';
import Connect from '../../../../Connect/Connect';
import { windmillRun } from '../../../../Connect/Connect';
import { MapOptions } from './MapOptions';
import { Field, MappedFieldValue } from './types';

interface FieldMappingTableProps {
  tabName: string;
  name: string;
  windmillPathSourcePath: string;
  windmillPathTargetPath: string;
  defaults?: Array<MappedFieldValue>;
  sourceTranslation: string;
  targetTranslation: string;
}

export const FieldMappingTable: FC<FieldMappingTableProps> = ({
  tabName,
  name,
  windmillPathSourcePath,
  windmillPathTargetPath,
  defaults,
  sourceTranslation,
  targetTranslation
}: FieldMappingTableProps) => {
  let searchTimer = null;
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const [loading, setLoading] = useState(false);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [targetOptions, setTargetOptions] = useState([]);
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success' as AlertColor
  });
  
  // [{ sourceField: 'Workday Field', targetField: 'Zuora Field', type: 'string', rowId: 0 }]
  

  const addToMapping = (rowId: number, source: string, target: string, type: string) => {
    const currentMapping = state.settingsHash[tabName][name];
    // Find the highest existing rowId and add 1, or start at 0 if array is empty
    const nextRowId = currentMapping.length > 0 
      ? Math.max(...currentMapping.map(row => row.rowId)) + 1 
      : 0;

    dispatch({
      type: tabName,
      payload: {
        [name]: [
          ...currentMapping,
          { sourceField: source, targetField: target, type: type, rowId: nextRowId }
        ]
      }
    });
  }

  const deleteFromMapping = (rowId: number) => {
    const currentMapping = [...state.settingsHash[tabName][name]];
    const updatedMapping = currentMapping.filter(row => row.rowId !== rowId);
    
    dispatch({
      type: tabName,
      payload: {
        rowId,
        [name]: updatedMapping
      }
    });
  }
  
  const handleSearch = (searchValue) => {
  };

  const addFieldMapping = () => {
    const rows = state.settingsHash[tabName][name] || {};
    const lastRowKey = Object.keys(rows).at(-1);
    const rowId = lastRowKey ? parseInt(lastRowKey) + 1 : 0;
    addToMapping (rowId, "", "", "string" )
  };

  const addDefaultMappings = (defaults: Array<MappedFieldValue>) => {
    if (!defaults?.length) return;
  
    const defaultsWithIds = defaults.map((def, index) => {
      // Ensure source field has proper structure
      const sourceField = {
        type: def.sourceField.type || 'string',
        label: def.sourceField.label || String(def.sourceField.value),
        value: def.sourceField.value
      };
  
      // Ensure target field has proper structure
      const targetField = {
        type: def.targetField.type || 'string',
        label: def.targetField.label || String(def.targetField.value),
        value: def.targetField.value
      };
  
      return {
        ...def,
        rowId: index,
        type: def.type ?? sourceField.type, // Default to source field type if not specified
        sourceField,
        targetField
      };
    });
  
    dispatch({
      type: tabName,
      payload: {
        [name]: defaultsWithIds
      }
    });
  };
  


  const getFieldMappings = async () => {
    setLoading(true);
    setToast({...toast, show: false});
    try {
      // load workday and zuora journal mappings
      const fetchedSourceOptions = await windmillRun({
        'script_path': windmillPathSourcePath,
        'params': state.settingsHash,
        'async': false
      });
      
      setSourceOptions(fetchedSourceOptions || []);

      const fetchedTargetOptions = await windmillRun({
        'script_path': windmillPathTargetPath,
        'params': state.settingsHash,
        'async': false
      });
      setTargetOptions(fetchedTargetOptions  || []);

    } catch (err) {
      Connect.log(err);
      setToast({
        show: true,
        msg: `Failed to load Mappings Options. Please double check credentials!`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // maybe need to update this to check for other values to get file mappings
    if (state.saved && state.reloadFieldMappings) {
      getFieldMappings();
    }
  }, [state.saved, state.reloadFieldMappings]);

  useEffect(() => {
    if (!state.settingsHash[tabName]) {
      dispatch({ type: tabName, payload: {[name]: []}});
      if (defaults){
        addDefaultMappings(defaults);
      }
    }
    getFieldMappings();
  }, []);

   
  const rowRenderer: any = (system: string, title: string,fieldOptions: Array<Field>) => {
    return {
      field: `${system}_fields`,
      label: `${title}`,
      dsRenderCell: ({ row }) => {
        return (
            <MapOptions fieldOptions={fieldOptions} row={row} system={system} mappingTableTab={tabName} mappingTableName={name} />
        )
      }
    }
  };

  return (
    <>
      {toast.show && <ToastController severity={toast.severity as any} message={toast.msg} />}
      <Table
        loading={loading}
        columns={[
          rowRenderer("sourceField", sourceTranslation, sourceOptions),
          {
            field: '',
            label: '',
            alignment: 'center' as any,
            dsRenderCell: ({ row }) => {
              return (
                  <Icon
                    body="arrow_forward"
                  />
              )
            }
          },
          rowRenderer("targetField", targetTranslation, targetOptions),
        ]}
        uniqueKey={`${tabName}fieldmaping-field-table`}
        sortable={false}
        tableActions={[
          {
            icon: 'add',
            tooltip: 'Add a Field Mapping',
            onClick: () => addFieldMapping()
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh Field Mapping Sources',
            onClick: () => getFieldMappings()
          }
        ]}
        rowActions={[
          {
            icon: 'delete',
            tooltip: 'Delete a Field Map',
            onClick: (event) => deleteFromMapping((event as any).row.rowId)
          }
        ]}
        hidePagination={true}
        rowDisplayOptions={{
          hoverEffect: false
        }}
        searchable
        searchPlaceholder='Type field name to filter results...'
        dsOnSearchChange={(value) => handleSearch(value)} 
        rows={state?.settingsHash?.[tabName]?.[name] ?? []}
      /> 
    </>
  );
};
