import Connect from '../../../Connect/Connect';
import { windmillRun } from '../../../Connect/Connect';

export const getZuoraWorkdayMappings = async (
  auth: {
    WDB_SERVICE_URL: string;
    WDB_USERNAME_GET: string;
    WDB_PASSWORD_GET: string;
    WDB_USERNAME_PUT: string;
    WDB_PASSWORD_PUT: string;
    WDB_ZR_CLIENT_ID: string;
    WDB_ZR_CLIENT_SECRET: string;
    WDB_ZR_REST_ENDPOINT: string;
  },
  type: string = 'summary'
) => {
  const result = { workdayFields: [], zuoraFields: [] };
  try {
    const response = await windmillRun({
      'script_path': 'f/workday_for_billing/flows',
      'params': {
        'action': 'get_zr_wd_mappings',
        'auth': auth,
        'type': type
      },
      'async': false
    });
    result.workdayFields = response['workdayFields'];
    result.zuoraFields = response['zuoraFields'];
  } catch (error) {
    Connect.log(error);
  }
  return result;
};

export const validateZuoraWorkday = async (
  auth: {
    WDB_SERVICE_URL: string;
    WDB_USERNAME_GET: string;
    WDB_PASSWORD_GET: string;
    WDB_USERNAME_PUT: string;
    WDB_PASSWORD_PUT: string;
    WDB_ZR_CLIENT_ID: string;
    WDB_ZR_CLIENT_SECRET: string;
    WDB_ZR_REST_ENDPOINT: string;
  }
) => {
  const result = {
    workday: {
      isValid: false,
      status: 200,
      statusText: ''
    },
    zuora: {
      isValid: false,
      status: 200,
      statusText: ''
    }
  };
  try {
    const response = await windmillRun({
      'script_path': 'f/workday_for_billing/flows',
      'params': {
        'action': 'validate_zr_wd',
        'auth': auth
      },
      'async': false
    });
    result.workday = response['workday'];
    result.zuora = response['zuora'];
  } catch (error) {
    Connect.log(error);
  }
  return result;
}

export const buildWorkdayBillingGlobalConstants = (auth: {
  WDB_SERVICE_URL: string;
  WDB_USERNAME_GET: string;
  WDB_PASSWORD_GET: string;
  WDB_USERNAME_PUT: string;
  WDB_PASSWORD_PUT: string;
  WDB_ZR_CLIENT_ID: string;
  WDB_ZR_CLIENT_SECRET: string;
}, emailNotifications: {
  enabled: boolean;
  failures: boolean;
  success: boolean;
  emails: string[];
}, fieldMappings: any, envRestEndpoint: string) => {
  // build field mappings
  let mappings = {};
  const reportType = fieldMappings['reportType'] || 'summary';
  const mappingKeys = Object.keys(fieldMappings);
  if (mappingKeys.length > 0) {
    for (let key of mappingKeys) {
      if (key.startsWith('z-')) {
        const rawMappings = fieldMappings[key]?.[reportType]?.['mappings'] || {};
        const rawMappingKeys = Object.keys(rawMappings);
        if (rawMappingKeys.length > 0) {
          for (let rmKey of rawMappingKeys) {
            const mapping = rawMappings[rmKey];
            const key = mapping['source']['text'];
            const value = mapping['target']['text'];
            mappings[key] = value;
          }
        }
      }
    }
  }

  // build email configs
  let emailConfig = {};
  if (emailNotifications.enabled) {
    emailConfig = {
      'Failures': emailNotifications.failures || false,
      'Success': emailNotifications.success || false,
      'Emails': emailNotifications.emails || []
    };
  }

  // store tenant information to Workflow Global Constants to be used in email notification
  const connect: Connect = (window as any).connect;
  const lastHyphenIndex = connect.tenant?.slug ? connect.tenant.slug.lastIndexOf('-') : -1;
  const host = lastHyphenIndex !== -1 ? connect.tenant.slug.substring(0, lastHyphenIndex) : '';
  const entity = lastHyphenIndex !== -1 ? connect.tenant.slug.substring(lastHyphenIndex + 1) : '';
  let environment = '';

  if (host.includes('staging')) {
    environment = 'Staging';
  } else if (host.includes('sandbox')) {
    environment = 'Sandbox';
  } else if (host.includes('test')) {
    environment = 'Test';
  } else if (host.includes('preprod')) {
    environment = 'Preprod';
  } else if (host.includes('na')) {
    environment = 'NA Production';
  } else if (host.includes('eu')) {
    environment = 'EU Production';
  } else if (host.includes('www.zuora.com') || host.includes('zuora.com')) {
    environment = 'Production';
  } else {
    environment = 'Development';
  }

  return {
    "WDB_SERVICE_URL": auth.WDB_SERVICE_URL,
    "WDB_USERNAME_GET": auth.WDB_USERNAME_GET,
    "WDB_PASSWORD_GET": auth.WDB_PASSWORD_GET,
    "WDB_USERNAME_PUT": auth.WDB_USERNAME_PUT,
    "WDB_PASSWORD_PUT": auth.WDB_PASSWORD_PUT,
    "WDB_ZR_CLIENT_ID": auth.WDB_ZR_CLIENT_ID,
    "WDB_ZR_CLIENT_SECRET": auth.WDB_ZR_CLIENT_SECRET,
    "WDB_ZR_REST_ENDPOINT": envRestEndpoint,
    "WDB_MAPPINGS": JSON.stringify(mappings),
    "WDB_EMAIL_CONFIG": JSON.stringify(emailConfig),
    "WDB_ACTION_TYPE": reportType,
    "WDB_ENVIRONMENT": environment,
    "WDB_TENANT_ID": connect.tenant.tenant_id || entity || 'Unknown',
    "WDB_TENANT_NAME": connect.tenant.name || 'Unknown',
    "WDB_ENTITY_ID": entity || 'Unknown'
  };
};