import { Box, ChipProps, CircularProgress, MenuItem, TextField } from '@mui/material';
import { Alert, Button, Card, Chip, Grid, Table, ToastController, Typography, designTokens } from '@platform-ui/design-system';
import React, { Dispatch, FC, useEffect, useMemo, useState } from 'react';
import Connect from '../../../../Connect/Connect';
import { IADState } from '../../../../IntegrationApps/IntegrationAppDetails/state';
import { Action, useStoreContext } from '../../../../Store';
import { TasksModal } from './TasksModal';
import { NS_UPDATE_SOLUTION_INSTANCE_SCHEDULE } from '../action_types';
import CronBuilder from '../../../../CronBuilder/CronBuilder';
import { ExecutionModal } from './ExecutionModal';
import { NextRunModal } from './NextRunModal';

interface ExecutionProps {
  inbound: boolean;
  tabName: string;
  newLayout: boolean;
}

const EXECUTION_DETAILS_TABLE_NAME = 'ns_execution_details';

export const Execution: FC<ExecutionProps> = ({
  inbound,
  tabName,
  newLayout
}: ExecutionProps) => {
  const [cron, setCron] = useState({
    timezone: 'UTC',
    expression: ['*', '*', '*', '*', '*', '*']
  });
  const { state, dispatch } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
  const [submitting, setSubmitting] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    msg: '',
    severity: 'success'
  });
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    pageSize: 10,
    currentPage: 0
  });
  const [singlePageLoadingOptions, setSinglePageLoadingOptions] = useState({
    isPreviousPageDisabled: true,
    isNextPageDisabled: true,
    currentPage: 0,
  });
  const [openTasksModal, setOpenTasksModal] = useState(false);
  const [workflowRunId, setWorkflowRunId] = useState(null);
  const [workflowRunNumber, setWorkflowRunNumber] = useState(null);
  const EXECUTION_DETAILS_COLUMNS = useMemo(
    () => [
      {
        field: 'id',
        label: 'Id',
        sortable: false
      },
      {
        field: 'solution_instance_name',
        label: 'Solution Instance Name',
        sortable: false
      },
      {
        field: 'workflow_run_number',
        label: 'Execution Run Number',
        sortable: false,
        dsRenderCell: ({ row, value }) => {
          return (
            state.enableTaskSummary
              ?
                (
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setWorkflowRunId(row.id);
                      setWorkflowRunNumber(row.workflow_run_number);
                      setOpenTasksModal(true);
                    }}
                  >
                    {value}
                  </a>
                )
              :
                <p>{value}</p>
          );
        }
      },
      {
        field: 'status',
        label: 'Status',
        sortable: false,
        dsRenderCell: ({ value }) => {
          let state: ChipProps['color'] = 'success';
    
          switch (value) {
            case 'SUCCEEDED':
            case 'Finished':
              state = 'success';
              break;
            case 'FAILED':
            case 'Stopped':
            case 'Stopping':
              state = 'error';
              break;
            case 'RUNNING':
            case 'Processing':
              state = 'primary';
              break;
            case 'WARNING':
              state = 'warning';
              break;
            case 'Queued':
            case 'Pending':
              state = 'default';
              break;
            default:
              state = 'default';
          }
    
          return <Chip label={<Typography variant='overline'>{value}</Typography>} size='medium' state={state} />
        }
      },
      {
        field: 'created_at',
        label: 'Start Date',
        sortable: true
      },
      {
        field: 'finished_at',
        label: 'End Date',
        sortable: false
      },
      {
        field: 'run_time',
        label: 'Duration (sec)',
        sortable: false
      },
      {
        field: 'error_count',
        label: 'Error Count',
        sortable: false
      }
    ].filter(col => inbound || col.field !== 'solution_instance_name'), 
    []
  );
  const [columns, setColumns] = useState(() => {
    const savedColumns = JSON.parse(localStorage.getItem(`${EXECUTION_DETAILS_TABLE_NAME}_columns`));
    if (savedColumns) {
      const merged = savedColumns.map(savedColumn => {
        const matchedColumn = EXECUTION_DETAILS_COLUMNS.find(col => col.field === savedColumn.field);
        if (matchedColumn?.dsRenderCell) {
          savedColumn.dsRenderCell = matchedColumn.dsRenderCell;
        }
        savedColumn.sortable = !!matchedColumn?.sortable;
        return savedColumn;
      });
      return merged;
    } else {
      return EXECUTION_DETAILS_COLUMNS;
    }
  });
  const [searchValue, setSearchValue] = useState(null);
  const [openExecutionModal, setOpenExecutionModal] = useState(false);
  const [openNextRunModal, setOpenNextRunModal] = useState(false);
  const isNewLayoutEnabled = newLayout || state.netsuiteNewLayout; // newLayout could be enabled via either ui_layout or feature flag
  const connect: Connect = (window as any).connect;
  const workflowProxyPath = `/platform/gateway-proxy-v2`;

  const getExecutions = async ({ page, rowsPerPage }) => {
    setLoading(true);
    setPageDetails({ currentPage: page, pageSize: rowsPerPage });

    try {
      const extraQueryParams = `page=${page + 1}&page_length=${rowsPerPage}&order_by=created_at&order_dir=desc`;
      let response;

      if (state.production) {
        const path = state.enablePrivateWorkflow
          ? `${workflowProxyPath}/workflows/workflow_runs?tags[]=IntegrationHub_NetSuite_${inbound ? 'Inbound' : 'Outbound'}&${extraQueryParams}`
          : `${workflowProxyPath}/workflows/workflow_runs?internal_group=IntegrationHub_NetSuite_${inbound ? 'Inbound' : 'Outbound'}&${extraQueryParams}`;

        const headers = state.enablePrivateWorkflow
          ? { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal' }
          : { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id };
          
        response = await Connect.proxyCall(
          path,
          'GET',
          undefined,
          headers
        );
      } else {
        const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
        const options = {
          method: 'GET',
          headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf
          })
        };
        response = await window.fetch(`${state.settingsUrl.replace('.json', `/netsuite/executions?inbound=${inbound}&${extraQueryParams}`)}`, options);
      }

      if (!response.ok) throw Error(response.statusText);

      const { data: executions, pagination } = await response.json();
      const rows = executions.map(execution => ({
        id: execution.id,
        workflow_run_number: execution.name,
        solution_instance_name: execution.definitionName.split(`IntegrationHub_NetSuite_${inbound ? 'Inbound' : 'Outbound'}_`)[1],
        status: execution.status,
        run_time: execution.runTime || 'N/A',
        created_at: execution.createdAt || 'N/A',
        finished_at: execution.finishedAt || 'N/A',
        error_count: execution.tasks.error
      }));

      setLoading(false);
      setSinglePageLoadingOptions({
        currentPage: page,
        isPreviousPageDisabled: page === 0,
        isNextPageDisabled: !pagination.next_page
      });
      setRows(rows);
    } catch (err) {
      Connect.log(err);
      setLoading(false);
    }
  };

  const execute = async () => {
    if (!submitting) {
      setSubmitting(true);
      setToast({...toast, show: false});

      const instance = state.settingsHash['solutionInstances'].find(
        instance => instance.solution_instance_name === state.settingsHash[tabName]['solution_instance_name']
      );
      
      try {
        let response;
        const isScheduled = state.settingsHash[tabName]?.['execution_mode'] === 'Scheduled';

        if (isScheduled && cron.timezone === '') {
          throw new Error('Timezone cannot be empty!!! Please select a timezone and try again!');
        }

        if (state.production) {
          const headers = state.enablePrivateWorkflow
            ? { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Scope': 'Internal', 'Content-Type': 'application/json' }
            : { 'Zuora-Tenant-Id': (connect.tenant as any).tenant_id, 'Content-Type': 'application/json' };

          // update workflow with schedule
          if (isScheduled) {
            await Connect.proxyCall(
              `${workflowProxyPath}/workflows/versions/${instance.active_version_id}`,
              'PUT',
              {
                workflow: {
                  scheduled_trigger: isScheduled,
                  interval: isScheduled ? cron.expression.join(' ').trim() : '',
                  timezone: isScheduled ? cron.timezone : ''
                }
              },
              headers
            );
          } else {
            // run workflow when it's on demand
            response = await Connect.proxyCall(
              `${workflowProxyPath}/workflows/${instance.id}/run`,
              'POST',
              {},
              headers
            );
          }
        } else {
          // update workflow with schedule
          const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
          const options = {
            method: 'PUT',
            headers: new Headers({
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-CSRF-Token': csrf
            }),
            body: JSON.stringify({
              workflow: {
                scheduled_trigger: isScheduled,
                interval: isScheduled ? cron.expression.join(' ').trim() : '',
                timezone: isScheduled ? cron.timezone : ''
              }
            })
          };

          if (isScheduled) {
            await window.fetch(`${state.settingsUrl.replace('.json', `/netsuite/instances/${instance.active_version_id}`)}`, options);
          } else {
            // run workflow when it's on demand
            options.method = 'POST';
            options.body = JSON.stringify({ instanceId: instance.id });
            response = await window.fetch(`${state.settingsUrl.replace('.json', '/netsuite/execute')}`, options);
          }
        }

        if (response && !response.ok) throw Error(response.statusText);

        // update solution instance state if it's scheduled
        if (isScheduled) {
          dispatch({
            type: NS_UPDATE_SOLUTION_INSTANCE_SCHEDULE,
            payload: {
              id: instance.id,
              scheduled_trigger: isScheduled,
              interval: isScheduled ? cron.expression.join(' ').trim() : '',
              timezone: isScheduled ? cron.timezone : ''
            }
          });
        } else {
          await getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize });
        }

        setSubmitting(false);
        setToast({
          show: true,
          msg: isScheduled ? 'Successfully submitted a scheduled run' : 'Successfully submitted an adhoc run',
          severity: 'success'
        });
      } catch (err) {
        Connect.log(err);
        setSubmitting(false);
        setToast({
          show: true,
          msg: err.message || 'Failed to submit',
          severity: 'error'
        });
      }
    }
  };

  const download = (fileName: string, content: any) => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([JSON.stringify(content, null, 2)], { type: 'application/json' }));
    a.download = fileName;
    a.click();

    URL.revokeObjectURL(a.href);
    a.remove();
  };

  const onDownloadClickHandler = async (args: { row: any }) => {
    const { row } = args;
    setLoading(true);
    try {
      let response;

      if (state.production) {
        const headers = state.enablePrivateWorkflow
          ? {
            'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
            'Scope': 'Internal'
          }
          : {
            'Zuora-Tenant-Id': (connect.tenant as any).tenant_id
          };

        response = await Connect.proxyCall(
          `${workflowProxyPath}/workflows/tasks?workflow_id=${row.id}&name=FileLink`,
          'GET',
          undefined,
          headers
        );
      } else {
        const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
        const options = {
          method: 'GET',
          headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf
          })
        };
        response = await window.fetch(`${state.settingsUrl.replace('.json', `/netsuite/tasks?workflow_id=${row.id}&name=FileLink`)}`, options);
      }

      if (!response.ok) throw Error(response.statusText);

      const { data } = await response.json();
      
      if (!Array.isArray(data) || data.length === 0) throw Error('No output task was found');

      const fileName = `${row.solution_instance_name}_${row.workflow_run_number}_execution.json`;

      download(fileName, data[0]?.['data']?.['Revenue::GenerateSummary']?.['Summary'] || {});

      setLoading(false);
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        show: true,
        msg: `Failed to download: ${err.message}`,
        severity: 'error'
      });
    }
  };

  const onStopClickHandler = async (args: { row: any }) => {
    const { row } = args;

    if (!confirm(`Are you sure you want to stop ${row.workflow_run_number}`)) {
      return;
    }

    setLoading(true);
    try {
      let response;

      if (state.production) {
        const headers = state.enablePrivateWorkflow
          ? {
            'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
            'Scope': 'Internal',
            'Accept': 'application/json'
          }
          : {
            'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
            'Accept': 'application/json'
          };

        response = await Connect.proxyCall(
          `${workflowProxyPath}/workflows/${row.id}/stop`,
          'PUT',
          undefined,
          headers
        );
      } else {
        const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
        const options = {
          method: 'PUT',
          headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf
          })
        };
        response = await window.fetch(`${state.settingsUrl.replace('.json', `/netsuite/executions/${row.id}/stop`)}`, options);
      }

      if (!response.ok) throw Error(response.statusText);

      const { success } = await response.json();
      
      if (!success) throw Error('Failed to stop');

      await getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize });

      setLoading(false);
      setToast({
        show: true,
        msg: `Successfully stopped ${row.workflow_run_number}`,
        severity: 'success'
      });
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        show: true,
        msg: `Failed to stop ${row.workflow_run_number}`,
        severity: 'error'
      });
    }
  };

  const onDeleteClickHandler = async (args: { row: any }) => {
    const { row } = args;

    if (!confirm(`Are you sure you want to delete ${row.workflow_run_number}`)) {
      return;
    }
    
    setLoading(true);
    try {
      let response;

      if (state.production) {
        const headers = state.enablePrivateWorkflow
          ? {
            'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
            'Scope': 'Internal'
          }
          : {
            'Zuora-Tenant-Id': (connect.tenant as any).tenant_id
          };

        response = await Connect.proxyCall(
          `${workflowProxyPath}/workflows/${row.id}`,
          'DELETE',
          undefined,
          headers
        );
      } else {
        const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
        const options = {
          method: 'DELETE',
          headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf
          })
        };
        response = await window.fetch(`${state.settingsUrl.replace('.json', `/netsuite/executions/${row.id}`)}`, options);
      }

      if (!response.ok) throw Error(response.statusText);

      await getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize });

      setLoading(false);
      setToast({
        show: true,
        msg: `Successfully deleted ${row.workflow_run_number}`,
        severity: 'success'
      });
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        show: true,
        msg: 'Failed to delete',
        severity: 'error'
      });
    }
  };

  const getExecution = async (workflowRunId: string) => {
    setLoading(true);
    try {
      let response;

      if (state.production) {
        const headers = state.enablePrivateWorkflow
          ? {
            'Zuora-Tenant-Id': (connect.tenant as any).tenant_id,
            'Scope': 'Internal'
          }
          : {
            'Zuora-Tenant-Id': (connect.tenant as any).tenant_id
          };

        response = await Connect.proxyCall(
          `${workflowProxyPath}/workflows/workflow_runs/${workflowRunId}`,
          'GET',
          undefined,
          headers
        );
      } else {
        const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
        const options = {
          method: 'GET',
          headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrf
          })
        };
        response = await window.fetch(`${state.settingsUrl.replace('.json', `/netsuite/executions?workflow_run_id=${workflowRunId}`)}`, options);
      }

      if (!response.ok) throw Error(response.statusText);

      const execution = await response.json();

      if (execution.errors) {
        setRows([]);
      } else {
        setRows([{
          id: execution.id,
          workflow_run_number: execution.name,
          solution_instance_name: execution.definitionName.split(`IntegrationHub_NetSuite_${inbound ? 'Inbound' : 'Outbound'}_`)[1],
          status: execution.status,
          run_time: execution.runTime || 'N/A',
          created_at: execution.createdAt || 'N/A',
          finished_at: execution.finishedAt || 'N/A',
          error_count: execution.tasks.error
        }]);
      }

      setLoading(false);
      setSinglePageLoadingOptions({
        currentPage: 0,
        isPreviousPageDisabled: true,
        isNextPageDisabled: true
      });
    } catch (err) {
      Connect.log(err);
      setLoading(false);
      setToast({
        show: true,
        msg: 'Failed to search an execution run',
        severity: 'error'
      });
    }
  };

  const submitButton = (
    <Box sx={{m: 1, position: 'relative'}}>
      {
        <Button
          disabled={
            submitting || 
            state.settingsHash[tabName]?.['execution_mode'] == null ||
            state.settingsHash[tabName]?.['solution_instance_name'] == null ||
            (state.settingsHash[tabName]?.['execution_mode'] === 'Scheduled' && cron.timezone === '')
          }
          tooltip={state.settingsHash[tabName]?.['execution_mode'] === 'Scheduled' && cron.timezone === '' ? 'Timezone cannot be empty' : null}
          endIcon={state.settingsHash[tabName]?.['execution_mode'] === 'Scheduled' ? 'save' : 'play_circle_outline'}
          dsOnClick={() => execute()}
          body={state.settingsHash[tabName]?.['execution_mode'] === 'Scheduled' ? 'Save' : 'Run'}
        />
      }
      {
        submitting &&
        <CircularProgress
          size={24}
          sx={{
            color: designTokens.colors.blue500,
            position: 'absolute',
            top: '50%',
            left: '40px',
            marginTop: '-12px',
            marginLeft: '-12px'
          }}
        />
      }
    </Box>
  );

  useEffect(() => {
    if (searchValue === null) {
      return;
    }

    if (searchValue.length === 0) {
      getExecutions({ page: 0, rowsPerPage: 10 });
      return;
    }

    const timer = setTimeout(() => getExecution(searchValue), 1000);
    
    return () => clearTimeout(timer);
  }, [searchValue]);

  useEffect(() => {
    // Add a little bit of delay when loading executions to avoid race condition in the backend
    // when trying to load NetSuite connector
    let timer = null;
    if (state.settingsHash['authentication']) {
      timer = setTimeout(() => getExecutions({ page: 0, rowsPerPage: 10 }), 1500);
    }
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!inbound) {
      const defaultInstance = state.settingsHash['solutionInstances']?.find(s => s.solution_instance_name === 'IH_Internal_NetSuite_GL');
      if (defaultInstance) {
        dispatch({
          type: tabName,
          payload: {
            'solution_instance_name': defaultInstance.solution_instance_name || ''
          }
        });
      }
    }
  }, [state.settingsHash['solutionInstances']]);

  try {
    if (!state.active) {
      return (
        <Alert center variant='outlined' severity='warning' body='You must enable integration in Authentication tab first' open={true} />
      );
    }
  
    if (!state.settingsHash['authentication']) {
      return (
        <Alert center variant='outlined' severity='warning' body='You must configure Authentication and save first' open={true} />
      );
    }
  
    if (inbound && (!state.settingsHash['solutionInstances'] || state.settingsHash['solutionInstances'].length === 0)) {
      return (
        <Alert center variant='outlined' severity='warning' body='You must configure Solution Instances first' open={true} />
      );
    }
  
    if (!inbound && !state.netsuiteAdmin && state.provisionSolutionInstanceError) {
      return (
        <Alert center variant='outlined' severity='error' body='Failed to provision your instance. Please contact admin!' open={true} />
      );
    }
  
    if (!inbound && !state.netsuiteAdmin && (!state.settingsHash['solutionInstances'] || !state.settingsHash['solutionInstances']?.find(s => s.solution_instance_name === 'IH_Internal_NetSuite_GL'))) {
      return (
        <Alert center variant='outlined' severity='warning' body='Please wait while we are provisioning your instance' open={true} />
      );
    }
  
    if (!inbound && !state.netsuiteAdmin && state.saved && !state.solutionInstanceUpdated) {
      return (
        <Alert center variant='outlined' severity='warning' body='Please wait while we are updating your instance' open={true} />
      );
    }
  } catch (err) {
    Connect.log(err);
    return (
      <Alert center variant='outlined' severity='error' body='An unexpected error has occurred. Please contact admin for technical support!' open={true} />
    );
  }

  return (
    <Grid container spacing={2}>
      { toast.show && <ToastController severity={toast.severity as any} message={toast.msg} /> }
      { state.enableTaskSummary && <TasksModal workflowRunId={workflowRunId} workflowRunNumber={workflowRunNumber} open={openTasksModal} setOpenTasksModal={setOpenTasksModal} /> }
      { isNewLayoutEnabled && <NextRunModal inbound={inbound} open={openNextRunModal} setOpenNextRunModal={setOpenNextRunModal} /> }
      <ExecutionModal
        open={openExecutionModal}
        setOpenExecutionModal={(open) => {
          setOpenExecutionModal(open);
          dispatch({type: tabName, payload: { 'execution_mode': '' }});
        }}
        newLayout={isNewLayoutEnabled}
        submitButton={submitButton}
      >
        <Grid item xs={12}>
          <Card
            id='netsuite-execution-top-ui'
            variant={isNewLayoutEnabled ? 'outlined' : 'elevation'}
            body={
              <Grid container>
                <Grid item xs={isNewLayoutEnabled ? 12 : 6} sx={{ display: inbound ? '' : state.netsuiteAdmin ? '' : 'none' }}>
                  <TextField
                    fullWidth
                    select
                    label={<b>Solution Instance Name</b>}
                    value={state.settingsHash[tabName]?.['solution_instance_name'] || ''}
                  >
                    {
                      // TODO (Duc): replace these options with data from Workflow
                      (state.settingsHash['solutionInstances'] || []).map(instance => instance).map((instance: any, idx: number) => (
                        <MenuItem
                          key={idx}
                          value={instance.solution_instance_name}
                          onClick={() => {
                            dispatch({type: tabName, payload: { 'solution_instance_name': instance.solution_instance_name }});
                            if (inbound) {
                              setCron({
                                ...cron,
                                timezone: instance.timezone || 'UTC',
                                expression: instance.interval ? instance.interval.split(' ') : ['*', '*', '*', '*', '*', '*']
                              });
                            }
                          }}
                        >
                          {instance.solution_instance_name}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </Grid>
                <Grid item xs={isNewLayoutEnabled ? 12 : 6}>
                  <TextField
                    fullWidth
                    select
                    label={<b>Execution Mode</b>}
                    value={state.settingsHash[tabName]?.['execution_mode'] || ''}
                  >
                    {
                      ['On-Demand', 'Scheduled'].map((option: string, idx: number) => (
                        <MenuItem
                          key={idx}
                          value={option}
                          onClick={() => {
                            dispatch({type: tabName, payload: { 'execution_mode': option }});
                            const instance = state.settingsHash['solutionInstances']?.find(s => s.solution_instance_name === state.settingsHash[tabName]?.['solution_instance_name']);
                            if (instance) {
                              setCron({
                                ...cron,
                                timezone: instance.timezone || 'UTC',
                                expression: instance.interval ? instance.interval.split(' ') : ['*', '*', '*', '*', '*', '*']
                              });
                            }
                          }}
                        >
                          {option}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </Grid>
                {
                  state.settingsHash[tabName]?.['execution_mode'] === 'Scheduled' &&
                  <Grid item xs={12}>
                    <CronBuilder
                      cron={cron}
                      setCron={setCron}
                    />
                  </Grid>
                }
                {
                  !isNewLayoutEnabled &&
                  <Grid item xs={12}>
                    <Grid container direction='column' alignItems='center'>
                      <Grid item>
                        {submitButton}   
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
            }
          />
        </Grid>
      </ExecutionModal>
      <Grid item xs={12}>
        <Card
          id='netsuite-execution-bottom-ui'
          titleBar
          header='Execution Details'
          headerAction={
            isNewLayoutEnabled 
              ? (
                <>
                  <Button icon='settings' tooltip='Configure Run' dsOnClick={() => setOpenExecutionModal(true)} />
                  <Button icon='schedule' tooltip='See Next Runs' dsOnClick={() => setOpenNextRunModal(true)} />
                </>
              )
              : null
          }
          body={
            <Table
              loading={loading}
              columns={columns}
              rows={rows}
              uniqueKey='netsuite-execution-table'
              tableActions={[
                {
                  icon: 'refresh',
                  tooltip: 'Reload Table',
                  onClick: () => getExecutions({ page: pageDetails.currentPage, rowsPerPage: pageDetails.pageSize })
                }
              ]}
              rowActions={[
                {
                  dsGetActionData: (row) => ({
                    icon: 'cloud_download',
                    tooltip: 'Download',
                    disabled: row.status !== 'Finished',
                    onClick: onDownloadClickHandler
                  })
                },
                {
                  dsGetActionData: (row) => ({
                    icon: 'stop_circle',
                    tooltip: 'Stop',
                    disabled: ['Finished', 'Stopped', 'Stopping'].includes(row.status as string),
                    onClick: onStopClickHandler
                  })
                },
                {
                  dsGetActionData: (row) => ({
                    icon: 'delete_outline',
                    tooltip: 'Delete',
                    disabled: ['Queued', 'Processing', 'Pending', 'Stopping'].includes(row.status as string),
                    onClick: onDeleteClickHandler
                  })
                }
              ]}
              rowDisplayOptions={{
                hoverEffect: false
              }}
              rowsPerPage={pageDetails.pageSize}
              rowsPerPageOptions={[5, 10, 15, 20, 30, 45]}
              dsOnPage={getExecutions}
              hideTotalRows
              singlePageLoadingOptions={singlePageLoadingOptions}
              orderable
              {...{searchable: true}}
              dsOnColumnsChange={(columns) => {
                localStorage.setItem(`${EXECUTION_DETAILS_TABLE_NAME}_columns`, JSON.stringify(columns));
              }}
              dsOnSearchChange={(args) => setSearchValue(args)}
              showNoRowsMessage={rows?.length === 0}
              searchPlaceholder='Type execution run number/id to filter results...'
            />
          }
        />
      </Grid>
    </Grid>
  );
};