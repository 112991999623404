import {
  SAP_ADD_SOLUTION_INSTANCE,
  SAP_CHANGE_EMAIL_NOTIFICATIONS_ENABLED,
  SAP_CHANGE_EMAIL_NOTIFICATIONS_FAILURES,
  SAP_CHANGE_EMAIL_NOTIFICATIONS_WARNINGS,
  SAP_CHANGE_EMAIL_NOTIFICATIONS_SUCCESS,
  SAP_EMAIL_NOTIFICATIONS_ADD_ADDRESS,
  SAP_EMAIL_NOTIFICATIONS_CANCEL_BUTTON_CLICK,
  SAP_EMAIL_NOTIFICATIONS_DELETE_ADDRESS,
  SAP_EMAIL_NOTIFICATIONS_SAVE_BUTTON_CLICK,
  SAP_EMAIL_NOTIFICATIONS_UPDATE_ADDRESS,
  SAP_LOAD_SOLUTION_INSTANCES,
  SAP_PROVISION_SOLUTION_INSTANCE_ERROR
} from './action_types';
import {Draft} from "immer";
import {IADState} from "../../../IntegrationApps/IntegrationAppDetails/state";

export const actionMap = {
  [SAP_CHANGE_EMAIL_NOTIFICATIONS_ENABLED]: (draft: Draft<IADState>, enabled: boolean) => {
    if (!draft.settingsHash['emailNotifications']) {
      draft.settingsHash['emailNotifications'] = {};
      draft.settingsHash['emailNotifications']['enabled'] = false;
      draft.settingsHash['emailNotifications']['warnings'] = false;
      draft.settingsHash['emailNotifications']['success'] = false;
      draft.settingsHash['emailNotifications']['emails'] = [];
    }
    draft.settingsHash['emailNotifications']['enabled'] = enabled;
    draft.emailNotificationsModified = true;
  },
  [SAP_CHANGE_EMAIL_NOTIFICATIONS_FAILURES]: (draft: Draft<IADState>, failures: boolean) => {
    draft.settingsHash['emailNotifications']['failures'] = failures;
    draft.emailNotificationsModified = true;
  },
  [SAP_CHANGE_EMAIL_NOTIFICATIONS_WARNINGS]: (draft: Draft<IADState>, warnings: boolean) => {
    draft.settingsHash['emailNotifications']['warnings'] = warnings;
    draft.emailNotificationsModified = true;
  },
  [SAP_CHANGE_EMAIL_NOTIFICATIONS_SUCCESS]: (draft: Draft<IADState>, success: boolean) => {
    draft.settingsHash['emailNotifications']['success'] = success;
    draft.emailNotificationsModified = true;
  },
  [SAP_EMAIL_NOTIFICATIONS_ADD_ADDRESS]: (draft: Draft<IADState>) => {
    if (!draft.settingsHash['emailNotifications']['emails']) {
      draft.settingsHash['emailNotifications']['emails'] = [];
    }
    draft.settingsHash['emailNotifications']['emails'].push('');
    draft.emailNotificationsModified = true;
  },
  [SAP_EMAIL_NOTIFICATIONS_UPDATE_ADDRESS]: (draft: Draft<IADState>, payload: any) => {
    draft.settingsHash['emailNotifications']['emails'][payload.index] = payload.email;
    draft.emailNotificationsModified = true;
  },
  [SAP_EMAIL_NOTIFICATIONS_DELETE_ADDRESS]: (draft: Draft<IADState>, index: number) => {
    draft.settingsHash['emailNotifications']['emails'].splice(index, 1);
    draft.emailNotificationsModified = true;
  },
  [SAP_EMAIL_NOTIFICATIONS_CANCEL_BUTTON_CLICK]: (draft: Draft<IADState>) => {
    draft.settingsHash['emailNotifications'] = draft.originalEmailNotifications;
    draft.emailNotificationsModified = false;
  },
  [SAP_EMAIL_NOTIFICATIONS_SAVE_BUTTON_CLICK]: (draft: Draft<IADState>) => {
    draft.originalEmailNotifications = draft.settingsHash['emailNotifications'];
    draft.emailNotificationsModified = false;
  },
  [SAP_ADD_SOLUTION_INSTANCE]: (draft: Draft<IADState>, payload: any) => {
    if (!draft.settingsHash['solutionInstances']) {
      draft.settingsHash['solutionInstances'] = [];
    }
    draft.settingsHash['solutionInstances']?.push(payload);
    draft.provisionSolutionInstanceError = false;
  },
  [SAP_LOAD_SOLUTION_INSTANCES]: (draft: Draft<IADState>, payload: any) => {
    if (!draft.settingsHash['solutionInstances']) {
      draft.settingsHash['solutionInstances'] = [];
    }
    const instanceIds = payload.map(e => e.id);
    draft.settingsHash['solutionInstances'] = draft.settingsHash['solutionInstances'].filter(e => instanceIds.includes(e.id));
    payload.forEach(si => {
      const idx = draft.settingsHash['solutionInstances'].findIndex(e => e.id === si.id);
      if (idx === -1) {
        draft.settingsHash['solutionInstances'].push(si);
      } else {
        draft.settingsHash['solutionInstances'][idx] = {
          ...draft.settingsHash['solutionInstances'][idx],
          ...si
        };
      }
    });
  },
  [SAP_PROVISION_SOLUTION_INSTANCE_ERROR]: (draft: Draft<IADState>) => {
    draft.provisionSolutionInstanceError = true;
  },
};

